/**
 * 浮窗播放
 */
import React, { Component } from 'react';
import styles from './index.less';

class HoverButton extends Component {
  static defaultProps = {
    visible: false,
    onClick: f => f,
    zIndex: 10,
  }

  constructor(props) {
    super(props);
    this.state = {
      oLeft: '',
      oTop: '',
    };
    this.$vm = null; // 悬浮按钮
    this.moving = false; // 移动状态

    this.lastX = null; // 偏移量
    this.lastY = null;

    this.oW = null; // 悬钮距离
    this.oH = null;

    this.htmlWidth = null; // 页面宽度
    this.htmlHeight = null;

    this.bWidth = null; // 悬钮宽度
    this.bHeight = null;

    this.click = false; // 是否是点击
  }

  componentDidMount() {
    this.$vm.addEventListener(
      'touchmove',
      (e) => {
        if (e.cancelable) {
          e.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    this.htmlWidth = document.documentElement.clientWidth;
    this.htmlHeight = document.documentElement.clientHeight;
    const appEle = document.getElementById('app');
    this.winWidth = appEle.getBoundingClientRect().width;
    this.tempWidth = (this.htmlWidth - this.winWidth) / 2;
  }

  // 移动触发
  onTouchStart = (e) => {
    e = e.touches[0];
    this.click = true;

    this.oW = e.clientX - this.$vm.getBoundingClientRect().left;
    this.oH = e.clientY - this.$vm.getBoundingClientRect().top;

    this.bWidth = this.$vm.offsetWidth;
    this.bHeight = this.$vm.offsetHeight;

    const oLeft = e.clientX - this.oW;
    const oTop = e.clientY - this.oH;
    this.setState({
      oLeft,
      oTop,
    });

    this.moving = true;
  }

  // 移动结束
  onTouchEnd = (e) => {
    const { onClick } = this.props;
    const { oLeft: oldLeft } = this.state;
    this.moving = false;
    this.$vm.className = `${this.$vm.className} ${styles.suspendBtnAnimate}`;

    // 左侧距离
    let oLeft = oldLeft;
    if (oLeft < (this.htmlWidth - this.bWidth) / 2) {
      oLeft = this.tempWidth + 12;
    } else {
      oLeft = this.htmlWidth - this.tempWidth - this.bWidth - 12;
    }

    if (this.click) {
      onClick(e);
    }

    this.setState({
      oLeft,
    });
  }

  // 开始移动
  onTouchMove = (e) => {
    const { conClassName } = this.props;
    this.$vm.className = `${styles.suspendBtn} ${conClassName}`;
    if (this.moving) this.onMove(e);
  }

  // 移动中
  onMove = (e) => {
    e = e.touches[0];
    this.click = false;

    // 左侧距离
    let oLeft = e.clientX - this.oW;
    let oTop = e.clientY - this.oH;
    if (oLeft < this.tempWidth) {
      oLeft = this.tempWidth;
    } else if (oLeft > this.tempWidth + this.winWidth - this.bWidth) {
      oLeft = this.tempWidth + this.winWidth - this.bWidth;
    }
    if (oTop < 0) {
      oTop = 0;
    } else if (oTop > this.htmlHeight - this.bHeight) {
      oTop = this.htmlHeight - this.bHeight;
    }

    this.setState({
      oLeft,
      oTop,
    });
  }

  render() {
    const { img, zIndex, isActive, visible, children, style: customStyle = {}, bottom = '15%', conClassName } = this.props;
    const { oLeft, oTop } = this.state;
    let className = isActive ? `${styles.suspendBtn} ${styles.pactive}` : styles.suspendBtn;
    const _rootStyle = Object.assign({}, customStyle,
      {
        left: `${oLeft}px`,
        top: `${oTop}px`,
        zIndex,
        visibility: visible ? 'visible' : 'hidden',
        bottom,
      });
    if (conClassName) className = `${className} ${conClassName}`;
    return (
      <div
        ref={$vm => (this.$vm = $vm)}
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.onTouchEnd}
        className={className}
        style={_rootStyle}
      >
        {
          children || <img src={img} alt="" />
        }
      </div>
    );
  }
}

export default HoverButton;
