// 是否数科域名
export const isYB = (hostname: string):boolean => hostname.indexOf('ybaobx.com') !== -1;

// 是否首信域名
export const isShouxin = (hostname: string):boolean => hostname.indexOf('shouxinbaoxian.com') !== -1;

// 判断当前域名是否是元保经纪域名
export const isCN = (hostname: string):boolean => hostname.indexOf('yuanbaobaoxian.cn') !== -1;

// 是否科创域名
export const isKc = (hostname:string) => hostname.indexOf('yuanbaokc.com') !== -1;
