/*
 * @Author: cuilinchao
 * @Date: 2024-06-15 16:10:21
 * @LastEditors: cuilinchao
 * @LastEditTime: 2024-06-17 10:00:35
 * @Description: 通用协议弹窗控制器
 */

import React from 'react';
import { renderToBody } from 'common/utils/renderToBody';
import ProtocolShowModal from 'src/component/Modals/ProtocolShowModal';

export default class ProtocolModalManager {
  /** modal实例的引用 */
  static modalRef;

  static visible;

  /**
   * 弹窗是否正在展示
   */
  static isShow = () => ProtocolModalManager.modalRef?.current?.state?.managerVisible;

  /**
   * 关闭弹窗
   */
  static close = () => {
    ProtocolModalManager.modalRef?.current?._onClose();
    ProtocolModalManager.modalRef = undefined;
  };

  /**
   * 展示弹窗
   */
  static show = async (props) => {
    if (ProtocolModalManager.isShow()) return; // 弹窗正在展示，不再调用展示
    ProtocolModalManager.visible = true;
    ProtocolModalManager.modalRef = React.createRef();

    const { onAfterClose, onClose, ...otherProps } = props;
    const unmont = renderToBody(
      <ProtocolShowModal
        ref={ProtocolModalManager.modalRef}
        onAfterClose={() => {
          unmont();
          ProtocolModalManager.modalRef = undefined;
          onAfterClose?.();
        }}
        {...otherProps}
        fromManager
      />
    );
  };
}
