/*
 * @Author: pyj
 * @Date: 2023-08-03 11:07:57
 * @LastEditors: pyj
 * @LastEditTime: 2023-08-07 15:43:11
 * @Description: 协议弹窗loading组件
 */
import classNames from 'classnames';
import { getImgPath } from 'ybcommon/ybutils/common/common';

import styles from './index.less';

interface IProps {
  isLoading: boolean, // 是否需要展示loading动画
  loadingText: string, // loading提示文本
  reLoad: () => void, // 重新加载回调
}

function ProtocolLoading(props: IProps) {
  const { isLoading, loadingText, reLoad } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.loading} style={{ display: isLoading ? 'flex' : 'none' }}>
        <div className={classNames(styles.dot, styles.dot1)} />
        <div className={classNames(styles.dot, styles.dot2)} />
        <div className={classNames(styles.dot, styles.dot3)} />
      </div>
      <div className={styles.img} style={{ display: isLoading ? 'none' : 'flex' }}>
        <img src={getImgPath(require('./img/fail.png'))} alt="fail" />
      </div>
      <div className={styles.text}>
        {loadingText}
      </div>
      <div
        className={styles.reload}
        style={{ display: isLoading ? 'none' : 'block' }}
        onClick={reLoad}
      >
        <span>重新加载</span>
      </div>
    </div>
  );
}

export default ProtocolLoading;
