/*
 * @Author: shixiaoxia
 * @Date: 2022-10-24 14:17:51
 * @LastEditors: xiaotao xiaotao@yuanbaobaoxian.com
 * @LastEditTime: 2024-06-07 17:05:38
 * @Description: 环境UA判断
 */

import { device } from 'fe-yb-tools';

const { isWeMini, isWechat, isIOS, isAndroid, isMiuiBrowser, isDouyin, isQuark, isAlipay, isBaidu, isHuaWei, isMobile, isAlipayClient, isUC, isChrome, isJingjiApp, isShouxinApp, isTTMini } = device;

export { isWeMini, isWechat, isIOS, isAndroid, isMiuiBrowser, isDouyin, isQuark, isAlipay, isBaidu, isHuaWei, isMobile, isAlipayClient, isUC, isChrome, isJingjiApp, isShouxinApp, isTTMini };
