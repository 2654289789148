/*
 * @Author: shixiaoxia
 * @Date: 2024-08-29 17:50:44
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-10-18 13:04:18
 * @Description: 请填写简介
 */
import { AxiosResponse } from 'axios';
import md5 from 'md5';
import { getEnvConfigrationByDomain } from '../../envConfig/index';
import { CommonApiParams, CommonAbtestParams } from '../../types';
import { CommonHeader, DataResponse } from '../base';
import { composeAbtestParams, makeCdnParams, shuntDefaultFetch, shuntFetch } from './shuntFetch';
import { AbTestCodeParam } from './types';
import { getLogger } from '../../webLogger';

/**
 * 生成分流请求
 * 泛型参数必须继承 {BaseShuntAllResult}，并在具有templates属性
 * @param commonHeader {CommonHeader} 公共header
 * @returns {Promise<AxiosResponse<DataResponse<T>, unknown>}  返回结果
 */
export async function getShuntAbtestAll<T>(
  ybPosition: string | undefined,
  abTestParamsList: AbTestCodeParam[],
  commonAbTestParams: CommonAbtestParams,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
// eslint-disable-next-line max-len
):Promise<AxiosResponse<DataResponse<T>, unknown>> {
  const shuntFetchInstance = shuntFetch(commonHeader);
  const params = { ...(commonApiParams || {}), ...composeAbtestParams(ybPosition, abTestParamsList, commonAbTestParams) };
  try {
    return await shuntFetchInstance.post('/api/growth/shunt/v1/determine/batchGetPage', params);
    // throw new Error('mock error');
  } catch (err) {
    // 异常 走OSS兜底
    // TODO: 这里需要添加异常上报
    getLogger().info('shunterr1', { err: JSON.stringify(err) });
    try {
      const shuntFetchDefaultInstance = shuntDefaultFetch(commonHeader);
      const result = await shuntFetchDefaultInstance.post('api/growth/shunt/default/determine/batchGetPage', params);
      return result;
    } catch (e) {
      getLogger().info('shunterr2', { err: JSON.stringify(e) });
      // 异常 走CDN兜底
      // TODO: 这里需要添加异常上报
      const t = new Date().getTime();
      const urlParamsStr = makeCdnParams(params.paramList);
      const name = md5(urlParamsStr);
      getLogger().info('shuntcdn', { md5PreStr: urlParamsStr, md5AfterStr: name });
      const result = await shuntFetchInstance.get(`${getEnvConfigrationByDomain().SHUNT_CDN_URL}${name}.json?v=${t}`);
      return result;
    }
  }
}
