export default {
  path: '/unorder',
  routes: [
    { // 退保服务
      path: '/unorder/service',
      component: () => import('./Service'),
    },
    { // 退保服务
      path: '/unorder/servicenew',
      component: () => import('./ServiceNew'),
    },
    { // 退保服务（新页面）
      path: '/unorder/servicepage',
      title: '退保服务',
      component: () => import('./ServicePage'),
    },
    { // 退保联路版本3
      path: '/unorder/servicelink',
      title: '退保服务',
      component: () => import('./ServiceLink'),
    },
    { // 退保挽回
      path: '/unorder/retrieve',
      component: () => import('./Retrieve'),
    },
    { // 退保结果
      path: '/unorder/result',
      component: () => import('./Result'),
      title: '退保结果',
    },
  ],
};
