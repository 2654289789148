/* eslint-disable no-shadow */
// 运营商映射埋点
export enum OPERATOR_NUM {
  cm = 1, // 移动
  cu = 2, // 联通
  ct = 3, // 电信
}
// 供应商映射埋点
export enum SUPPLIER {
  JY = 1, // 极意
  ALIYUN = 2, // 阿里云
  JG = 3, // 极光
  ALIYUN_NOUI = 4, // 阿里云无UI版本
}
// 极验版本
export enum JIYAN_VERSION {
  V3 = 3, // 极意本机校验
  V4 = 4, // 极意一键登录
  V9 = 9, // 阿里云本机校验
  V10 = 10, // 阿里云一键登录
  V13 = 13, // 阿里云无UI版本
}
