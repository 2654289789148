/**
 * 费率因子表格
*/

import React, { Component } from 'react';
import classnames from 'classnames';
import PremiumTableDetail from './PremiumTableDetail';
import styles from './index.less';

class PremiumTable extends Component {
  constructor(props) {
    super(props);
    const { tabIndex } = props;
    this.state = {
      curIndex: tabIndex || 0, // 当前激活的tab索引
    };
  }

  render() {
    const { tabDateList, showType } = this.props;
    const { curIndex } = this.state;
    let content = null;
    if (showType) { // showType 1:以tabs展示多个产品的费率表
      content = (
        <div className={styles.tabBox}>
          <div className={styles.tabBars}>
            {
              tabDateList.map((item, index) => (
                <div
                  key={`${item.insuranceId}bar`}
                  onClick={() => this.setState({ curIndex: index })}
                  className={classnames(styles.tabBar, index === curIndex ? styles.activeTabBar : null)}
                >
                  {item.tabTitle || '完整费率表'}
                </div>
              ))
            }
          </div>
          <div className={styles.tabBody}>
            {
              tabDateList.map((item, index) => (
                <div
                  key={`${item.insuranceId}Body`}
                  className={classnames(styles.tabBar, index === curIndex ? styles.activeTab : null)}
                >
                  <PremiumTableDetail {...item} />
                </div>
              ))
            }
          </div>
        </div>
      );
    } else { // showType 0或者没传递：展示单个产品的费率表
      content = (
        <PremiumTableDetail {...this.props} />
      );
    }
    return content;
  }
}

export default PremiumTable;
