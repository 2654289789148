/*
 * @Author: shixiaoxia
 * @Date: 2022-07-27 13:32:10
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-04-19 15:33:51
 * @Description: 埋点fetch
 */
import axios from 'axios';
import { getFetchMsg, getFetchSign } from '../ybutils/common/common';

const trackUrl = __PUB__ ? '//track.yuanbaobaoxian.com/' : '//data-track.yuanbaobaoxian.com/';
const TrackFetch = axios.create({
  baseURL: __DEV__ ? '/' : trackUrl,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;',
  },
});

const Interceptor = {
  request: [
    (request) => {
      const { data } = request;
      const params = { ...(data || {}) };
      const msg = getFetchMsg(params);
      const sign = getFetchSign(msg);
      request.data = { msg, sign };
      return request;
    },
  ],
};
TrackFetch.interceptors.request.use(...Interceptor.request);

export default TrackFetch;
