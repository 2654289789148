/*
 * @Author: shixiaoxia
 * @Date: 2022-07-27 12:02:01
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-10-24 13:48:22
 * @Description: 通用fetch
 */
import axios, { CancelToken } from 'axios';
import qs from 'qs';
import stores from 'store';
import { getCancelRequets } from 'ybcommon/ybutils/historyUtil';
import { getMediaUuid } from 'ybcommon/ybmodel/tgUtil';
import { cookie } from 'fe-yb-tools';
import { getFetchMsg, getFetchSign, getSource } from '../ybutils/common/common';
import { getAppCode, getBusinessOrigin } from '../ybutils/common/company';
import { getSessionID, isNodeBff } from '../ybutils/common/sessionIdUtil';
import eventBus from '../eventBus';

function isFormData(val) {
  return (typeof FormData !== 'undefined') && (val instanceof FormData);
}
const shuntOssQueryUrl = ['api/growth/shunt/default/determine/batchGetPage', 'api/growth/shunt/default/determine/getPage'];
const needCancelUrl = [...shuntOssQueryUrl, 'api/mall/cq/insurance/queryDetail', 'api/mall/cq/insurance/shortProduct/queryDetail'];

const processError = ({ code, msg, data = null }) => {
  code = Number(code);
  switch (code) {
    case 201:
      msg = '未登录';
      setTimeout(() => {
        eventBus.emit('doLogin');
      }, 500);
      break;
    case 408:
      msg = '请求超时，请稍后重试';
      break;
    case 502:
    case 504:
      msg = msg || '网络不给力，请稍后重试';
      break;
    case 9999:
      msg = '';
      break;
    case 666: // 重定向特殊状态码
      if (data && data.redirectUrl) {
        window.location.replace(data.redirectUrl);
      }
      msg = '';
      break;
    default:
      break;
  }
  return Promise.reject({ code, msg, data });
};

const Interceptor = {
  request: [
    (request) => {
      const { method, url } = request;
      if (needCancelUrl.indexOf(url) !== -1) {
        request.cancelToken = new CancelToken((cancel) => {
          getCancelRequets().push(cancel);
        });
      }
      const { userId, userToken, uuid, isWeChatMiniApp, unionId, appId, channel } = stores?.userStore ?? {};
      const tuiguangId = getMediaUuid?.();
      const companyCode = getBusinessOrigin(); // 业务来源主体编码
      const appCode = getAppCode(isWeChatMiniApp);
      const source = getSource();
      const grayFlag = cookie.readCookie('ybgray');
      const grayVersion = cookie.readCookie('grayversion');

      // shunt oss 请求url不进行header添加
      if (shuntOssQueryUrl.indexOf(url) === -1) {
        request.headers.post = {
          ...request.headers.post,
          authorizationv2: uuid || '', // 切换用户中心标识
          'auth-token': userToken || '',
          'auth-userid': userId || '',
          'auth-uuid': uuid || '',
        };
      }
      if (grayFlag != null && grayFlag !== 'null') { // 灰度标识
        request.headers.post = {
          ...request.headers.post,
          'x-yb-gray-flag': grayFlag,
          'x-yb-gray-release-time': grayVersion,
          'x-yb-gray-flag-release-time': `${grayFlag}_${grayVersion}`,
        };
      }
      if (method === 'post') {
        if (isFormData(request.data)) return request; // 如果是formData数据 不对请求数据进行处理
        let params = { isNodeBff: isNodeBff(), uuid, channel, companyCode, appCode, source, url_pathname: window.__pathname, shuntId: window.ybParams?.shuntId, appVersion: __VERSION__, ...(request.data || {}) };
        if (!__DEV__ && !params.domain) { // 非本地环境 添加域名入参
          params.domain = window.location.host;
        }
        params.busiTraceId = getSessionID();
        if (!params.tuiguangId && tuiguangId) params.tuiguangId = tuiguangId;
        if (url.indexOf('api/login/getSmsCode') > -1
          || (window.__pathname === '/resign' && url.indexOf('api/h5/insurance/order/reSign/privilege/getSignUrl') > -1)
          || (window.__pathname === '/resultNew/batch' && url.indexOf('api/h5/insurance/correction/init') > -1)
          || (window.__pathname === '/order/unpaid' && url.indexOf('api/mall/bff/order/detail/version2/queryUnpaidDetail') > -1)
          || (window.__pathname === '/resultNew/detail' && url.indexOf('api/mall/bff/order/detail/version2/queryDetail') > -1)) {
          params.curPath = window.location.href.split('#')[0];
        }
        switch (+isWeChatMiniApp) {
          case 1: // 元保商城小程序
          case 5: // 首信微信小程序
          case 6: // 数科支付宝小程序
          case 7: // 数科支付宝小程序v2 - 退款助手
          case 8: // 数科支付宝小程序v3 - 退款售后
            params = { ...params, isWeChatMiniApp: +isWeChatMiniApp, appId, unionId };
            break;
          case 9: // 抖音经纪小程序
            params = { ...params, isWeChatMiniApp: 9, appId, unionId };
            break;
          case 10: // 抖音首信小程序
            params = { ...params, isWeChatMiniApp: 10, appId, unionId };
            break;
          default:
            break;
        }
        if (source === 8 && url.indexOf('api/cashier/standard/createPayOrder') > -1) params.appId = '';
        const reqParams = {};
        Object.keys(params).forEach((key) => {
          if (key === 'undefined' || params[key] === 'undefined' || params[key] === 'null' || (params[key] ?? '') === '') return;
          reqParams[key] = params[key];
        });
        const msg = getFetchMsg(reqParams);
        const sign = getFetchSign(msg);
        const newParams = { msg, sign };
        request.data = newParams;
        delete request.params;
      }
      return request;
    },
  ],
  response: [
    (response) => {
      console.log('=========fetch response', response);
      const { status, config } = response || {};
      if (config?.method === 'get') {
        if (status === 200) {
          return response.data;
        }
      }
      if (/json/.test(response.headers['content-type'])) {
        console.log('=========json response');
        // shunt oss 请求成功后会直接返回结果，没有code，因此直接返回结果
        if (shuntOssQueryUrl.indexOf(config?.url) > -1 && status === 200) {
          return response.data;
        }
        const { retcode, retCode, retdesc, retDesc, ...data } = response.data;
        // 兼容处理
        const code = Number(retcode || retCode);
        const msg = retdesc || retDesc;

        if (Number.isNaN(code) || code === 200) {
          return data.data; // 业务数据
        }
        return processError({ code, msg, data: data.data });
      }
      return response; // response对象
    },
    (err) => {
      console.log('fetch error:', err);
      if (axios.isCancel(err)) {
        // 为了终结promise链 就是实际请求 不会走到.catch(rej=>{});这样就不会触发错误提示之类了。
        return new Promise(() => { });
      }
      let msg = err?.response?.data?.msg || err?.msg || '抱歉，网络异常，请检查网络或稍后重试';
      if (msg === '抱歉，网络异常，请检查网络或稍后重试') {
        if (window.yb && window.yb.uplogCatchError && err) {
          // && !/取消上次请求|Network Error|timeout|Request aborted/.test(err.message)
          let errorReason = '';
          if (err?.response) {
            errorReason = '请求成功发出且服务器也响应了状态码，但状态代码不在指定的范围[200, 300)';
          } else if (err?.request) {
            errorReason = '请求已经成功发起，但没有收到响应';
          } else {
            errorReason = '发送请求时出了点问题';
          }
          window.yb.uplogCatchError(
            JSON.stringify({
              code: 'fetchError',
              msg: {
                ybState: document.visibilityState,
                errorReason,
                err,
              },
            }),
          );
        }
      }
      let code = err?.response?.data?.status || err?.response?.status || 99;
      if (axios.isCancel(err)) {
        msg = err?.message;
        code = 9999;
      }
      return processError({ code, msg, err });
    },
  ],
};

export { Interceptor };

export default class Fetch {
  constructor(config) {
    this.config = config;
  }

  getInstance() {
    const fetch = this.create(this.config); // 系统默认实例
    fetch.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
    const { post: oldPost } = fetch;
    const newPost = ({ url, params, postConfig }, successCallback,
      failCallback) => {
      const { showLoading = true } = params || {};
      if (showLoading) {
        eventBus.emit('showLoading');
      }
      oldPost(url, params, postConfig)
        .then((res) => {
          if (showLoading) {
            eventBus.emit('hideLoading');
          }
          if (successCallback) successCallback(res);
        })
        .catch((err) => {
          if (showLoading) {
            eventBus.emit('hideLoading');
          }
          if (failCallback) failCallback(err);
        });
    };
    fetch.loadingPost = newPost;
    return fetch;
  }

  create(config) {
    const instance = axios.create({
      baseURL: '/',
      timeout: 50 * 1000,
      transformRequest: [
        function (data) {
          if (isFormData(data)) return data; // formData数据不需要处理
          return qs.stringify(data);
        },
      ],
      ...config,
    });
    instance.interceptors.request.use(...Interceptor.request);
    instance.interceptors.response.use(...Interceptor.response);
    return instance;
  }
}
