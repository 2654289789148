/*
 * @Author: shixiaoxia
 * @Date: 2022-07-27 14:40:07
 * @LastEditors: 斌 libin@yuanbaobaoxian.com
 * @LastEditTime: 2023-06-09 14:08:46
 * @Description: 可视化平台fetch
 */
import axios from 'axios';
import { getEnv } from 'ybcommon/envUtil';
import { Interceptor } from './base';

let visualUrl;
if (__PUB__) {
  visualUrl = '//visual.yuanbaobaoxian.com/';
} else if (__TEST__) {
  visualUrl = `//visual-${getEnv()}.yuanbaobaoxian.com/`;
} else {
  visualUrl = '/visualpreview';
}

const VisualFetch = axios.create({
  baseURL: visualUrl,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;',
  },
});
VisualFetch.interceptors.response.use(...Interceptor.response);

export default /** @type {any} */ (VisualFetch);
