/*
 * @Author: 姜薇 jixiaoyu@yuanbaobaoxian.com
 * @Date: 2024-03-12 15:23:27
 * @LastEditors: xiaotao xiaotao@yuanbaobaoxian.com
 * @LastEditTime: 2024-04-01 14:51:48
 * @Description: 个人信息页
 */
export default [
  {
    path: '/personinfo',
    title: '个人信息',
    component: () => import('./Info'),
  },
  {
    path: '/privacy',
    title: '隐私管理',
    component: () => import('./Privacy'),
    routes: [
      {
        path: '/privacy/personalize',
        title: '个性化内容推荐',
        component: () => import('./Privacy/Personalize'),
      },
      {
        path: '/privacy/authority',
        title: '权限管理',
        component: () => import('./Privacy/Authority'),
      },
      {
        path: '/privacy/infocollect',
        title: '个人信息收集清单',
        component: () => import('./Privacy/InfoCollect'),
      },
      {
        path: '/privacy/infopage',
        component: () => import('./Privacy/InfoPage'),
      },
      {
        path: '/privacy/faceLogin',
        title: '人脸登录设置',
        component: () => import('./Privacy/FaceLogin'),
      },
    ],
  },
  {
    path: '/logoff',
    title: '注销账户',
    component: () => import('./Logoff/index'),
  },
];
