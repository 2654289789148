// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".adBg_6Z6vrTFu {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  background: transparent;\n  position: relative;\n  z-index: 1;\n}\n.contentImg_VL6YHzIS {\n  width: 100%;\n  height: auto;\n  object-fit: unset;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adBg": "adBg_6Z6vrTFu",
	"contentImg": "contentImg_VL6YHzIS"
};
export default ___CSS_LOADER_EXPORT___;
