/*
 * @Author: shixiaoxia
 * @Date: 2022-07-27 13:36:49
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-07-01 17:11:16
 * @Description: 优化单系统mix
 */
import { getEnv } from 'ybcommon/envUtil';
import Fetch from './base';

let _mixFetch;
if (__PUB__) {
  _mixFetch = new Fetch({ baseURL: '//mix.yuanbaokc.com/' });
} else if (__TEST__) {
  _mixFetch = new Fetch({ baseURL: `//${getEnv()}mix.yuanbaokc.com/` });
} else {
  _mixFetch = new Fetch({ baseURL: '/' });
}
const MixFetch = _mixFetch.getInstance();
export default /** @type {any} */ (MixFetch);
