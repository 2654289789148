/*
 * @Author: shixiaoxia
 * @Date: 2022-10-24 14:22:47
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-09 17:36:23
 * @Description: 埋点
 */
import stores from 'store';
import { device } from 'fe-yb-tools';
// import { getEnv, getHostSuffix } from 'ybcommon/envUtil';
import storage from './storage';
import { TrackFetch } from '../ybfetch';
import { getFetchMsg, getFetchSign, getUrlParam } from './common/common';
import { getSessionID, isNodeBff } from './common/sessionIdUtil';
import { isInIframe } from './iframeUtil';

const { isWeMini, isWechat, isJingjiApp, isShouxinApp, isTTMini } = device;

const notSupportSendBeaconArr = ['sinanews', 'Pipi', 'PiPiBar', 'AliApp', 'Youku', 'Kwai', 'dangdang'];

// 是否支持sendBeacon
const canUseSendBeacon = () => {
  if (typeof window.navigator.sendBeacon !== 'function') {
    return false;
  }
  let canUse = true;
  notSupportSendBeaconArr.forEach((item) => {
    if (window.navigator.userAgent.indexOf(item) > -1) {
      canUse = false;
    }
  });
  return canUse;
};

/**
   * 获取网络类型
   * @returns string
   */
const getNetworkType = () => {
  let network = 'unknown';
  const { connection } = window.navigator;
  if (connection) {
    const connectionType = connection.type;
    if (connectionType === 'cellular') {
      // 蜂窝网络
      network = connection.effectiveType ? connection.effectiveType : 'cellular';
    } else if (connectionType) {
      network = connectionType;
    } else {
      network = 'unknown';
    }
  }
  const ua = window.navigator.userAgent;
  if (/NetType/.test(ua)) {
    // 微信, qq, 抖音
    const type = ua.match(/NetType\/(\S*)/);
    network = type[1];
  }
  return network.toLowerCase();
};

/**
   * 数据组规定 0 h5 1 微信小程序内h5 2 公众号即微信内
   * @returns type 0 1 2
   */
export const getProductName = () => {
  if (isWeMini()) {
    return 1;
  }
  if (isWechat()) {
    return 2;
  }
  if (isJingjiApp()) {
    return 31;
  }
  if (isShouxinApp()) {
    return 33;
  }
  if (isTTMini()) {
    return 36;
  }
  return 0;
};

/**
   * 获取埋点产品名称
   * @returns buryProductName 埋点产品名称
   */
export const getBuryProductName = () => {
  let buryProductName = '10001';
  if (window.location.host.indexOf('shouxinbaoxian.com') !== -1) {
    buryProductName = '40001';
  }
  return buryProductName;
};

/**
   * 大数据埋点方法
   * TODO
   * @param {*} eventName 事件名，click_和page_表示点击和访问
   * @param {*} data 额外的数据
   * @param {*} useSendBeacon 使用sendbeacon方式上报埋点数据
   */
export const trackEvent = (eventName, data = {}, useSendBeacon = false) => {
  console.log('大数据埋点', eventName, data, isInIframe);
  if (__DEV__) {
    return;
  }
  const { userStore } = stores || {};
  const { userId, uuid, channel = '', hasLogin, openId = '', unionId = '' } = userStore || {};
  const fetchUrl = 'track/buryPoint';
  // 如果页面中有加密的用户id，上传给埋点数据
  const userIdencrypt = getUrlParam('userIdencrypt');
  // 新的埋点文档要求使用position_id，暂时保留两个字段，后续看是否可以替换原有字段
  if (data.position) {
    data.position_id = data.position;
  }
  const finalData = {
    openid: openId,
    unionid: unionId,
    source: isWechat() ? 1 : 0,
    product_name: getProductName(),
    app_version: __VERSION__,
    rqst_path: window.__pathname || window.location.pathname,
    rqst_url: window.location.href,
    hasLogin: !!hasLogin,
    userIdencrypt,
    screen_height: window.screen.height,
    screen_width: window.screen.width,
    network_type: getNetworkType(),
    busiTraceId: getSessionID(),
    isInIframe,
    isNodeBff: isNodeBff(),
    ...data,
  };

  const finalParams = {
    event: eventName,
    event_time: new Date().getTime(),
    request_id: uuid || storage.get('YB_LS_UUID_V1'),
    user_id: userId || storage.get('YB_LS_USERID_V1'),
    channel_id: channel || getUrlParam('channel'),
    data: finalData,
    event_platform: getBuryProductName(),
  };
  let result;
  // 使用sendBeacon上报，加入队列失败或浏览器不兼容则进行降级处理
  if (useSendBeacon && canUseSendBeacon()) {
    try {
      const msg = getFetchMsg(finalParams);
      const sign = getFetchSign(msg);
      const blob = new Blob([JSON.stringify({ msg, sign })], {
        type: 'application/json;charset=UTF-8',
      });
      const trackUrl = __PUB__ ? '//track.yuanbaobaoxian.com/' : '//data-track.yuanbaobaoxian.com/';
      const baseURL = __DEV__ ? '/' : trackUrl;
      result = navigator.sendBeacon(`${baseURL}${fetchUrl}`, blob);
    } catch (e) {
      result = false;
    }
  }
  if (!result) {
    TrackFetch.post(fetchUrl, finalParams);
  }
};
