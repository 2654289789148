export default {
  path: '/extra',
  routes: [
    {
      path: '/extra/service',
      component: () => import('./Service'),
    },
    {
      path: '/extra/new',
      component: () => import('./NewService'),
    },
  ],
};
