/*
 * @Author: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @Date: 2023-11-08 12:36:59
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2023-11-08 18:11:48
 * @FilePath: /fe-insurance-miniprograme-ali/Users/jixiaoyu/Downloads/programCodes/fe-insurance-web/packages/fe-main-web/src/sys/consult/route.js
 * @Description: 解约挽留 扣费咨询
 */
export default [
  {
    path: '/consult',
    routes: [
      {
        path: '/consult/product', // 解约挽留
        component: () => import('./product'),
        title: '保险咨询',
      },
      {
        path: '/consult/customerservice', // 扣费咨询
        component: () => import('./customService'),
        title: '扣费咨询',
      },
    ],
  },
];
