import md5 from 'md5';
import { Base64 } from 'js-base64';

/**
 * 生成md5字符串
 * @param msg
 * @returns
 */
// export const md5 = (msg:string): string => md5(msg);

/**
* @description: 请求参数处理
* @param {object} reqParams 请求参数
* @return {string} base6后结果，即msg参数
*/
export const getFetchMsg = (reqParams: object):string => Base64.encode(JSON.stringify(reqParams));

/**
 * @description: 获取请求签名
 * @param {string} msg msg参数
 * @return {string} 返回签名sign参数
 */
// TODO: 这里如过取的时服务器时间，是否会有问题？
export const getFetchSign = (msg:string): string => {
  const timezone = 8; // 目标时区时间，东八区
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
  const targetDate = new Date(nowDate + offsetGMT * 60 * 1000 + timezone * 60 * 60 * 1000);
  targetDate.setHours(0, 0, 0, 0);
  const key = `_nsip_play_${targetDate.getTime() - (offsetGMT / 60 + 8) * 60 * 60 * 1000}`;
  return md5(msg + key);
};
