/*
 * @Author: pyj
 * @Date: 2023-03-17 10:52:21
 * @LastEditors: pyj
 * @LastEditTime: 2023-03-17 15:25:31
 * @Description: 签约相关路由
 */
export default {
  path: '/resign',
  component: () => import('./Index'),
  routes: [
    {
      path: '/resign/result',
      component: () => import('./Result'),
    }, {
      path: '/resign/renew', // 银行卡重新签约
      component: () => import('./Renew'),
    }, {
      path: '/resign/unwind', // 解约
      component: () => import('./Unwind'),
    },
  ],
};
