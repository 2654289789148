/*
 * @Author: 朱晓真
 * @Date: 2023-06-16 11:49:32
 * @LastEditors: pyj
 * @LastEditTime: 2023-08-25 17:12:25
 * @Description: 业务工具--主要处理业务中公共业务逻辑功能
 */
import stores from 'src/store';
import { setCanUseLocalBackListener } from 'ybcommon/ybutils/common/backutil';
import AudioBGManager from 'src/common/audioBGManager';
import Fetch from 'fetch';
import { Toast } from '@yb/fe-component';
import { modalTaskRegister, modalTaskDelete } from './ModalTaskTools';

/**
   * 二要素校验
   * @param {*} params 二要素参数
   * @param {*} text 异常提示文
   * @param {*} needAutoPushPath 需要增加路由
   * @param {*} errAudioSrc 异常音频
   * @returns
   */
const checkPersionRealAsync = async (params, text, needAutoPushPath = true, errAudioSrc = '') => {
  const { userStore, toolStore } = stores;
  if (userStore.hasLogin) {
    try {
      Toast.loading('加载中...', 0);
      await Fetch.post('/api/h5/insurance/order/person/check', params);
      Toast.clear();
    } catch (error) {
      Toast.clear();
      if (errAudioSrc) AudioBGManager.getInstance().playBGAudio(errAudioSrc);
      return new Promise((resolve) => {
        toolStore.showModalByType(100, {
          contentText: text,
          isBottomBtn: true,
          isSingleBtn: true,
          confirmText: '知道了',
          onClose: () => {
            if (needAutoPushPath) setCanUseLocalBackListener(false);
            setTimeout(() => {
              resolve(false);
            }, 50);
          },
          needAutoBack: needAutoPushPath,
        }, needAutoPushPath);
      });
    }
  }
  return true;
};

export { checkPersionRealAsync, modalTaskRegister, modalTaskDelete };
