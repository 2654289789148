/*
 * @Author: shixiaoxia
 * @Date: 2022-10-25 14:47:01
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2022-10-26 17:43:54
 * @Description: 返回相关处理方法
 */
import { device } from 'fe-yb-tools';

const { isBaidu, isHuaWei, isIOS, isQuark, isAndroid, isUC } = device;

// 不需要回退的特殊ua列表
const noNeedBackSpecialUARegExpList = [
  'PGZ110 Build/TP1A\\.220905\\.001.*HeyTapBrowser',
  'PHB110 Build/TP1A\\.220905\\.001.*HeyTapBrowser',
];
const noNeedBackSpecialUARegExpStr = noNeedBackSpecialUARegExpList.map((item) => `(${item})`).join('|');

// 是否是不需要回退的特殊ua
const isNoNeedBackSpecialUA = () => new RegExp(noNeedBackSpecialUARegExpStr, 'i').test(navigator?.userAgent);

const noNeedBack = (isBaidu() && (isHuaWei() || isIOS())) || (isQuark() && isAndroid()) || isUC() || isNoNeedBackSpecialUA();

let _canUseLocalBackListner = true;

export const isNoNeedBack = () => noNeedBack;

export const autoAddHistory = () => {
  let currentUrl = window.location.href;
  setTimeout(() => {
    try {
      if (isAndroid()) {
        if (isBaidu()) {
          // 百度不能push相同的路径
          window.history.replaceState(null, null, currentUrl);
          window.history.pushState(window.history.state, null, currentUrl);
        } else if (isHuaWei()) {
          window.history.pushState(window.history.state, null, currentUrl);
        } else {
          // 其他浏览器下可以通过hash区别路径
          const flag = currentUrl.lastIndexOf('#');
          if (flag !== -1) {
            currentUrl = currentUrl.substring(0, flag);
          }
          // window.history.replaceState(window.history.state, null, currentUrl);

          const newurl = `${currentUrl}#${(Math.random() * 100).toFixed(2)}`;
          window.history.pushState(null, null, newurl);
        }
      } else if (isUC()) {
        // 其他浏览器下可以通过hash区别路径
        const flag = currentUrl.lastIndexOf('#');
        if (flag !== -1) {
          currentUrl = currentUrl.substring(0, flag);
        }
        // window.history.replaceState(window.history.state, null, currentUrl);

        const newurl = `${currentUrl}#${(Math.random() * 100).toFixed(2)}`;
        window.history.pushState(null, null, newurl);
      } else {
        window.history.pushState(window.history.state, null, currentUrl);
      }
    } catch (error) {
      if (window.yb && window.yb.uplogCatchError) {
        window.yb.uplogCatchError(JSON.stringify({ code: 'historyError', msg: { message: error?.message || 'history异常', stack: error?.stack ? error.stack.split(':') : '' } }));
      }
    }
  }, 0);
};

export const autoBackHistory = () => {
  try {
    _canUseLocalBackListner = noNeedBack;
    if (!noNeedBack) {
      window.history.back();
    } // else { // 安卓夸克 百度app华为机型不处理后退 }
  } catch (err) {
    console.log(err);
  }
};

/**
 * 检查是否可以后退
 * @returns
 */
export const isCanUseLocalBackListener = () => _canUseLocalBackListner;

/**
 * 设置是否可以后端
 * @param { boolean } canUse
 */
export const setCanUseLocalBackListener = (canUse) => {
  _canUseLocalBackListner = canUse;
};
