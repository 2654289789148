// 保费测评
export default {
  path: '/tgAppraisalNew',
  routes: [
    {
      path: '/tgAppraisalNew/detail',
      component: () => import('./Detail'),
    },
  ],
};
