/*
 * @Author: shixiaoxia
 * @Date: 2023-03-02 13:54:05
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-07-01 16:08:52
 * @Description: fetch引出
 */
import Fetch from './base';
import TrackFetch from './track';
import UCFetch from './usercenter';
import TianjiFetch from './gateway';
import VisualFetch from './visual';
import YbShuadanFetch from './ybshuadan';
import ShuntFetch from './shunt';
import UploadFetch from './upload';
import GWUploadFetch from './gatewayupload';
import MixFetch from './mix';

export { TrackFetch, TianjiFetch, UCFetch, VisualFetch, YbShuadanFetch, ShuntFetch, UploadFetch, GWUploadFetch, MixFetch };

const _defaultFetch = new Fetch().getInstance();
export default /** @type {any} */ (_defaultFetch);
