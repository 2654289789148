/*
 * @Author: shixiaoxia
 * @Date: 2023-06-06 17:08:07
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-06-07 11:46:26
 * @Description: 推广参数设置和获取（主要是mediaUuid）
 * 现有逻辑：
 *  https://yuanbaoshuke.feishu.cn/docs/doccnYHBXQawFExYuiaGKAwUBSa#f85Pcn
 * 需求逻辑：
 *  https://yuanbaoshuke.feishu.cn/docx/NzDkdgcFNoXTAVxSB39cLM4Xnvg
 */
export function setTgReferer() {
  if (window.sessionStorage) {
    const tgReferer = sessionStorage.getItem('__TGREFFERER__');
    if (!tgReferer) {
      sessionStorage.setItem('__TGREFFERER__', window.location.href);
    }
  }
}

export function getTgReferer() {
  let tgReferer;
  if (window.sessionStorage) {
    tgReferer = sessionStorage.getItem('__TGREFFERER__');
  }
  return tgReferer || window.location.href;
}

export function setMediaUuid(mediaUuid) {
  if (window.sessionStorage) {
    const tempTgId = sessionStorage.getItem('__TGID__');
    if (!tempTgId && mediaUuid) {
      sessionStorage.setItem('__TGID__', mediaUuid);
    }
  }
}

export function getMediaUuid() {
  let mediaUuid;
  if (window.sessionStorage) {
    mediaUuid = sessionStorage.getItem('__TGID__');
  }
  return mediaUuid || '';
}
