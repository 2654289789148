/*
 * @Author: jiangwei
 * @Date: 2022-08-17 10:49:22
 * @LastEditors: surun
 * @LastEditTime: 2022-10-14 12:20:10
 * @Descripttion: 页面骨架屏组件
 */
import React, { Component, Fragment } from 'react';
import styles from './index.less';

const skeletonClass = styles.fe_page_skeleton;

export default class PageSkeleton extends Component {
  static defaultProps = {
    page: 1, // 页面类型 1-批增登录页 2-批增落地页
  };

  // 批增登录页骨架屏
  _renderGJLogin = () => (
    <Fragment>
      <div className={skeletonClass} style={{ height: '38.981%', top: '0.000%', left: '4%', width: '92%' }} />
      <div className={skeletonClass} style={{ height: '2.849%', top: '41.679%', left: '12.142%', width: '8.529%' }} />
      <div className={skeletonClass} style={{ height: '2.849%', top: '41.679%', left: '26.000%', width: '48.000%' }} />
      <div className={skeletonClass} style={{ height: '2.849%', top: '41.679%', left: '79.329%', width: '8.529%' }} />
      <div className={skeletonClass} style={{ height: '7.494%', top: '47.976%', left: '4%', width: '92%' }} />
      <div className={skeletonClass} style={{ height: '9.3%', top: '57.791%', left: '4%', width: '92%', borderRadius: '35px' }} />
      <div className={skeletonClass} style={{ height: '30.6%', top: '69.336%', left: '4%', width: '92%' }} />
    </Fragment>
  );

  // 批增落地页骨架屏
  _renderGJDetail = () => (
    <Fragment>
      <div className={skeletonClass} style={{ height: '41.229%', top: '0.000%', left: '4%', width: '92%' }} />
      <div className={skeletonClass} style={{ height: '2.999%', top: '43.326%', left: '6.667%', width: '19.200%' }} />
      <div className={skeletonClass} style={{ height: '2.999%', top: '43.326%', left: '78.400%', width: '14.933%' }} />
      <div className={skeletonClass} style={{ height: '5.847%', top: '49.023%', left: '6.667%', width: '50.929%' }} />
      <div className={skeletonClass} style={{ height: '5.847%', top: '49.023%', left: '82.133%', width: '11.200%' }} />
      <div className={skeletonClass} style={{ height: '5.847%', top: '57.569%', left: '6.667%', width: '50.929%' }} />
      <div className={skeletonClass} style={{ height: '5.847%', top: '57.569%', left: '82.133%', width: '11.200%' }} />
      <div className={skeletonClass} style={{ height: '4.945%', top: '66.5%', left: '4%', width: '92%' }} />
      <div className={skeletonClass} style={{ height: '4.945%', top: '74.133%', left: '4%', width: '92%' }} />
      <div className={skeletonClass} style={{ height: '4.945%', top: '81.5%', left: '4%', width: '92%' }} />
      <div className={skeletonClass} style={{ height: '11%', top: '88.973%', left: '4%', width: '92%' }} />
    </Fragment>
  );

  // 普通产品落地页
  _renderGJDetailBW = () => (
    <div id="skeleton" className={styles.gj}>
      <div className={styles.moveDiv} />
      <div className={styles.gjHeader}>
        <div className={styles.gjHeaderCenter}>
          <div className={styles.gjTopLeft} />
          <div className={styles.gjHeaderContentBox}>
            <div className={styles.gjHeaderContent1} />
            <div className={styles.gjHeaderContent2} />
            <div className={styles.gjHeaderContent3} />
          </div>
        </div>
      </div>
      <div className={styles.gjBody}>
        <div className={styles.gjBodyTitle} />
        <div className={styles.gjBodyRow}>
          <div className={styles.gjBodyItemLabel} /> <div className={styles.gjBodyItemValue} />
        </div>
        <div className={styles.gjBodyRow}>
          <div className={styles.gjBodyItemLabel} /> <div className={styles.gjBodyItemValue} />
        </div>
        <div className={styles.gjBodyRow}>
          <div className={styles.gjBodyItemLabel} /> <div className={styles.gjBodyItemValue} />
        </div>
      </div>
    </div>
  );

  render() {
    const { page, mainContent } = this.props;
    let content = null;
    switch (page) {
      case 1: // 批增登录页
        content = this._renderGJLogin();
        break;
      case 2: // 批增落地页
        content = this._renderGJDetail();
        break;
      default:
        content = this._renderGJLogin();
        break;
    }
    if (page === 3) {
      return this._renderGJDetailBW();
    }
    return (
      <div id="skeleton" className={styles.fe_page_skeleton_wrap}>
        <div className={styles.moveDiv} />
        {mainContent || content}
      </div>
    );
  }
}
