/* eslint no-shadow: ["error", { "allow": ["DemoteType"] }] */
import { v1 as uuidv1 } from 'uuid';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { OPERATOR_NUM, SUPPLIER } from '../constant';

export enum DemoteType {
  Sdk = '1', // sdk超时
  UserCenter = '3', // 用户中心超时
}

class DemoteUitl {
  private cnt = 0; // 降级次数

  private degradeId = null; // 唯一id

  private startTime = null; // 单次降级的开始时间，降级后会被清空

  private historyRecord: Array<number> = []; // 全生命周期的历史版本

  // 降级前的一个版本
  public get preVersion() {
    if (this.historyRecord.length === 0) return undefined;
    return this.historyRecord[this.historyRecord.length - 1];
  }

  constructor() {
    this.degradeId = uuidv1();
  }

  setStartTime() {
    this.startTime = new Date().getTime();
  }

  sendLog({
    currentVersion,
    fromVersion,
    supplier,
    operator,
    overTimeType,
  }: {
    currentVersion: number, // 当前的版本
    fromVersion?: number, // 降级前版本
    supplier: SUPPLIER, // 供应商
    operator: OPERATOR_NUM, // 供应商
    overTimeType: DemoteType, // 超时类型
  }) {
    try {
      if (this.startTime === null) {
        // 未开始计时前触发的降级，不需要发送埋点
        return;
      }
      // 每降级一次降级次数+1
      this.cnt++;
      this.historyRecord.push(currentVersion);

      const now = new Date().getTime();
      trackEvent('jiyandegrade', {
        cnt: this.cnt,
        type: currentVersion,
        degradeFrom: fromVersion,
        supplier,
        operator,
        overTime: now - this.startTime,
        overTimeType,
        degradeId: this.degradeId,
      });

      // 清空初始时间
      this.startTime = null;
    } catch (e) {
      console.log(e);
    }
  }
}

export const DEMOTE_UTIL = new DemoteUitl();
