export default {
  path: '/question',
  routes: [
    {
      path: '/question/submit',
      component: () => import('./Submit'),
    },
    {
      path: '/question/detail',
      component: () => import('./Detail'),
    },
    {
      path: '/question/ask',
      component: () => import('./Ask'),
    },
  ],
};
