export default {
  path: '/calculate',
  routes: [
    { // 长险测算工具
      path: '/calculate/cx',
      component: () => import('./index'),
      registerOwnShare: {
        yuanbao: true,
      },
    },
    { // 测算成功页
      path: '/calculate/result',
      component: () => import('./result'),
      registerOwnShare: {
        yuanbao: true,
      },
    },
  ],
};
