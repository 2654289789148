/*
 * @Author: shixiaoxia
 * @Date: 2023-03-22 11:14:42
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-03-28 17:32:44
 * @Description: 请填写简介
 */
import React from 'react';
import { judgeProduct } from 'utils/common/common';

import PremiumTable from './PremiumTable';
import styles from './index.less';

const List = ({ titleData, data, insuranceId, category, curTab }) => {
  let text = '';
  if (judgeProduct(insuranceId, 'tkbw19') || judgeProduct(insuranceId, 'NEWHTBW')
    || judgeProduct(insuranceId, 'YGBW19')) {
    text = '首月1元，2-12月';
  } else if (judgeProduct(insuranceId, 'tkbw20')) {
    if (+category === 0) { // 1
      text = '首月1元，2-12月';
    } else if (+category === 1) { // 3
      text = '首月3元，2-12月';
    } else { // 5
      text = '首月5元，2-12月';
    }
  }
  return (
    <div className={styles.c}>
      <div className={styles.line} />
      {text && curTab === 1 ? (
        <React.Fragment>
          <div className={styles.p}>{text}</div>
          <div className={styles.line} />
        </React.Fragment>
      ) : <div />}
      <PremiumTable premiumHead={titleData || []} premiumData={data || []} />
    </div>
  );
};

export default List;
