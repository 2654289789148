/*
 * @Author: xiaotao xiaotao@yuanbaobaoxian.com
 * @Date: 2024-02-27 11:24:27
 * @LastEditors: xiaotao xiaotao@yuanbaobaoxian.com
 * @LastEditTime: 2024-02-27 11:24:39
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/videoLive/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
  {
    path: '/videoPage',
    component: () => import('./videoPage'),
    registerOwnShare: true,
  },
  {
    path: '/videoPlayEnd',
    component: () => import('./videoPage/VideoPlayEnd'),
  },
];
