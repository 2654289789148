/*
 * @Author: shixiaoxia
 * @Date: 2022-07-06 13:50:49
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-02-20 21:13:18
 * @Description: 页面基类，提供了：分享（已删除，可使用统一组件），返回监听，定时器设置和清除，事件上报，ybloading展示，骨架屏
 */
import { Component } from 'react';
import { getTitle } from '../../ybcommon/ybutils/common/company';
import { getLocationSearch } from '../../ybcommon/ybutils/common/common';
import { trackEvent } from '../../ybcommon/ybutils/statistic';
import { IProps } from './BasePropsState';

export interface IState {
  hasInit?: boolean; // location
  abtestComp?: any; // ab测组件
}

export default class BasePage<P extends IProps, S extends IState> extends Component<P, S> {
  params = null; // 链接参数

  timerMap = new Map(); // 定时器统一处理

  constructor(props: P) {
    super(props);
    this.params = getLocationSearch(window.location?.search);
    this.state = {
    } as S;
  }

  componentDidMount(): void {
    window.addEventListener('popstate', this._back);
    this.mountInit();
  }

  componentWillUnmount(): void {
    this.clearTimer();
    window.removeEventListener('popstate', this._back);
  }

  // 返回统一处理，子函数需进行继承，即调用super._back()
  _back = () => { };

  mountInit = () => { };

  /**
   *向cms传递数据
   * @param obj
   */
  postMessageCms = (obj) => {
    // 如果是在cms中打开
    const { fromCms } = this.params;
    if (+fromCms === 1) {
      window.parent.postMessage(obj, '*');
    }
  };

  /**
   * @description: 设置定时器，可以直接调用
   * @param {number} type 类型：1：setInterval；2：setTimeout
   * @param {string} key timer对应key，便于获取timerId和type
   * @param {function} callback 回调函数
   * @param {number} duration 时长
   * @return {void}
   */
  setTimer = (type, key, callback, duration) => {
    if (!this.timerMap) {
      this.timerMap = new Map();
    }
    const timerFunction = type === 1 ? setInterval : setTimeout;
    this.timerMap.set(key, { timerId: timerFunction(callback, duration), type });
  };

  /**
   * @description: 清除所有定时器，可以直接调用
   * @param {string} key timer对应key，便于获取timerId和type
   * @return {void}
   */
  clearTimer = (key?) => {
    if (!this.timerMap) {
      return;
    }
    if (key) {
      const { timerId, type } = this.timerMap.get(key) || {};
      const timerFunction = type === 1 ? clearInterval : clearTimeout;
      if (timerId) timerFunction(timerId);
    } else {
      this.timerMap.forEach((value) => {
        const { timerId, type } = value;
        const timerFunction = type === 1 ? clearInterval : clearTimeout;
        if (timerId) timerFunction(timerId);
      });
    }
  };

  /**
   * @description: 事件上报，可以直接调用，无需重复声明
   * @param {string} eventName 事件名
   * @param {object} params 额外参数
   * @return {void}
   */
  onTrackEvent = (eventName, params?) => {
    trackEvent(eventName, params);
  };

  /**
   * @description: 更新title
   * @param {string} name 要更新的title名
   * @return {void}
   */
  updateTitle = (name) => {
    document.title = name || getTitle();
  };

  // 渲染骨架屏，后续由插件自动生成，按需使用，可以直接调用
  renderSkeleton = () => null;

  // 错误页面，网络出错等，可以直接调用，也可以直接覆盖，无需继承
  renderError = () => null;

  // 主页面内容，可直接覆盖
  renderContent = () => null;

  render() {
    return this.renderContent();
  }
}
