/*
 * @Author: cuilinchao
 * @Date: 2023-05-17 11:04:28
 * @LastEditors: liulongjie liulongjie@yuanbaobaoxian.com
 * @LastEditTime: 2024-06-01 15:31:04
 * @Description: bbf层 store
 */
import { UserUtil } from 'utils';
import { getInsuranceType } from 'utils/common/common';
import { getShuntAbtestAll } from 'ybcommon/ybutils/common/shuntutil';
import { TianjiFetch } from 'ybcommon/ybfetch';
import userStore from './UserStore';

class BBFStore {
  // middle数据
  middleData = null;

  /**
   * 初始化
   * @param {object} params start参数
   * @param {object} urlParams 页面参数
   */
  getInitData = (params, urlParams) => {
    const fetchArr = [this.getStartData(params)];
    const isMiddlePage = window.__pathname === '/cargo/middle';
    if (isMiddlePage) {
      const { originalUrl } = params || {}; // 获取originalUrl传入分流
      fetchArr.push(this.getMiddleData(urlParams, originalUrl));
    }
    return new Promise((resolve, reject) => {
      Promise.all(fetchArr).then(([initData]) => {
        if (!isMiddlePage) { // 非middle页直接回调
          resolve(initData);
          return;
        }
        const { userId: oldUserId } = userStore;
        const { user } = initData || {};
        const { userId: newUserId } = user || {};
        if (oldUserId && !newUserId) {
          // 有旧的userId但无新的userId 清空userId且重新请求分流
          userStore.userId = '';
          UserUtil.setItem('userId', '', true);
          this.getMiddleData(urlParams).then(() => {
            resolve(initData);
          }).catch((error) => {
            reject(error);
          });
        } else {
          resolve(initData);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  };

  /**
   * 主站init + 用户中心getUserInfo
   * @param {object} params
   * type: 1、微信，2支付宝，其他不传
   * originalUrl?: 初始页面链接
   */
  getStartData = (params) => (new Promise((resolve, reject) => {
    TianjiFetch.post('api/mall/bff/start', params).then((data) => {
      const { initInfo = {}, userInfo } = data || {};
      const coreData = Object.assign(initInfo, { user: { ...userInfo } });
      resolve(coreData);
    }).catch((err) => {
      /* 推广链接重复访问，重定向404 */
      /** start接口 code 返回10002，重定向到404页面 */
      if (err?.code === 10002) {
        window.location.replace(`${window.location.origin}/error/404`);
      }
      reject(err);
    });
  }));

  /**
   * middle页请求接口
   * @param {object} urlParams 页面参数
   * @param {string | undefined} originalUrl 页面路径 可为空
   * @returns { Promise }
   */
  getMiddleData = (urlParams, originalUrl) => {
    const { insuranceId, shuntId, channel, isGift } = urlParams || {};
    let curProductAbb = '';
    if (insuranceId) curProductAbb = getInsuranceType(insuranceId);
    const params = [
      { configCode: 'pzSensitiveCityAbtest', extraLimit: { productAbb: curProductAbb, isGift } }, // 敏感城市
      { configCode: 'ZHPreShuntABtest', extraLimit: { shuntId, isGift } }, // 前置分流
      { configCode: 'payLaunchAbtest', extraLimit: { shuntId, channel } }, // 支付宝拉起ab测
    ];
    return new Promise((resolve, reject) => {
      getShuntAbtestAll(params, originalUrl).then((res) => {
        this.middleData = res; // 缓存分流结果
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  };
}

export default new BBFStore();
