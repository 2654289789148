/*
 * @Author: 朱晓真
 * @Date: 2023-03-09 10:08:09
 * @LastEditors: 朱晓真
 * @LastEditTime: 2023-08-24 14:35:47
 * @Description: 内存中跨组件数据的存、取
 */

export enum InputItemRefKey {
  SelfFormPhone = 'selfFormPhone',
  SelfFormName = 'selfFormName',
  SelfFormCertNo = 'selfFormCertNo',
  PushModalSelfFormName = 'pushModalSelfFormName',
  PushModalSelfFormCertNo = 'pushModalselfFormCertNo',
}

class DataStore {
  /** inputItemRef的map */
  inputItemRefMap: Map<string, React.ReactNode> = new Map();

  /**
   * 添加inputItem的ref
   * @param key 唯一标识key
   * @param inputItemRef ref组件实例
   */
  addInputItemRef = (key: string, inputItemRef: React.ReactNode) => {
    this.inputItemRefMap.set(key, inputItemRef);
  };

  /**
   * 删除key对应的inputItem的ref
   * @param key 唯一标识key
   */
  delInputItemRefByKey = (key: string) => {
    this.inputItemRefMap.delete(key);
  };

  /**
   * 获取key对应的inputItem的ref
   * @param key 唯一标识key
   * @returns
   */
  getInputItemRefByKey = (key: string) => this.inputItemRefMap.get(key);
}

export default new DataStore();
