// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lotteryContainer_J6SeiR39 {\n  width: 100%;\n  height: 8rem;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: coral;\n}\n.panel_8qwYgjcZ {\n  width: 7.6rem;\n}\n.pointer_pK3UIq\\+b {\n  position: absolute;\n  left: calc(50% - 1.38667rem);\n  top: calc(50% - 1.81333rem);\n  width: 2.77333rem;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lotteryContainer": "lotteryContainer_J6SeiR39",
	"panel": "panel_8qwYgjcZ",
	"pointer": "pointer_pK3UIq+b"
};
export default ___CSS_LOADER_EXPORT___;
