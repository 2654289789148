/** 触摸监听相关逻辑 */

let isSwiped = false;
let hasTouchMoveListener = false;
let startX = 0;
let startY = 0;

/** 触摸滑动回调逻辑 */
let touchScrollCB = null;

function handleTouchStart(event) {
  // 记录起始触摸点位置
  startX = event.touches[0].clientX;
  startY = event.touches[0].clientY;
  isSwiped = false; // 重置滑动状态
}

function handleTouchMove(event) {
  const moveX = event.touches[0].clientX;
  const moveY = event.touches[0].clientY;

  // 检查是否发生移动（定义一个最小移动距离来判断是否滑动）
  const deltaX = Math.abs(moveX - startX);
  const deltaY = Math.abs(moveY - startY);

  if (deltaX > 10 || deltaY > 10) {
    isSwiped = true; // 标记为滑动状态
  }
}

function handleTouchEnd() {
  if (isSwiped) {
    // console.log('用户滑动了屏幕');
    if (typeof touchScrollCB === 'function') touchScrollCB();
  } else {
    // console.log('用户没有滑动屏幕');
  }

  startX = 0;
  startY = 0;
  isSwiped = false;
}

/** 添加触摸监听 */
const addTouchListener = () => {
  // 添加事件监听
  document.addEventListener('touchstart', handleTouchStart, { once: true });
  if (!hasTouchMoveListener) {
    document.addEventListener('touchmove', handleTouchMove);
    hasTouchMoveListener = true;
  }
  document.addEventListener('touchend', handleTouchEnd, { once: true });
};

/** 开始监听触摸滑动操作 */
export const startTouchListener = (cb: () => void) => {
  touchScrollCB = cb;
  addTouchListener();
};
