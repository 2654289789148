/**
 * 抽奖圆盘
 * 属性：
 *  awards：奖品信息，包括award（后台返回的标识）、desc（奖品对应描述）、pos（在原盘上的位置）
 *  count：圆盘上的分割数
 *  outerClassName：外层样式
 *  pannelClassName：圆盘样式
 *  pointerClassName：指针样式
 *  remainTime：剩余次数
 *  pannelImg：圆盘图片
 *  pointerImg：指针图片
 *  fetchMethod: 抽奖请求函数
 *  onComplete：转动结束回调
 */
import React, { Component } from 'react';
import styles from './index.less';

export default class Lottery extends Component {
  constructor(props) {
    super(props);
    const { count } = this.props;
    this.count = count; // 总奖品数
    this.animateEndBool = true;
    this.awardId = -1; // 默认为第一个奖品
    this.steps = [];
    this.parsesteps();
  }

  // 处理步骤，计算每个奖品的位置
  parsesteps = () => {
    const each = 360 / this.count;
    for (let i = 0; i < this.count; i++) {
      this.steps.push(i * each);
    }
  }

  // ease-in-out计算
  easeInOutQuad = (currentTime, startValue, changeValue, duration) => {
    currentTime /= duration / 2;
    if (currentTime < 1) return changeValue / 2 * currentTime * currentTime + startValue;
    currentTime--;
    return -changeValue / 2 * (currentTime * (currentTime - 2) - 1) + startValue;
  }

  /**
   * 获取某个奖品的信息
   */
  getAward = (award) => {
    const { awards } = this.props;
    let data = {};
    for (let i = 0; i < awards.length; i++) {
      if (award === awards[i].awardId) {
        data = awards[i];
      }
    }
    return data;
  }

  onTransitionEnd = () => {
    const { onComplete } = this.props;
    const panel = document.getElementById('panel');
    if (!this.animateEndBool) {
      // 通知已结束动画，做相应的操作
      if (onComplete) {
        onComplete(this.awardData, this.err);
      }
      // 定位结束状态
      panel.style.cssText = `transform: rotateZ(${360 - this.steps[this.finalId]}deg);`;
      // 重置动画标志
      this.animateEndBool = true;
      panel.removeEventListener('transitionEnd', this.onTransitionEnd);
    }
  }

  // 开始旋转
  startRotate = () => {
    // 初始化动画结束标志
    this.animateEndBool = false;
    const panel = document.getElementById('panel');
    // 记录动画前时间
    const aniBefore = new Date().getTime();
    panel.style.cssText = `transform: rotateZ(${360 * 110}deg); transition:transform 30s ease-in-out 0s`;
    const { fetchMethod } = this.props;
    if (fetchMethod) {
      // 开始请求，回调中包含抽奖结果，以及错误信息
      fetchMethod((data, err) => {
        // 请求结束回调
        this.awardData = data;
        this.err = err;
        if (data.award < 0) { // 小于0的均表示未抽奖成功，此时默认定位最终位置为3
          this.finalId = this.getAward(0).awardPos;
        } else { // 否则，为对应award的位置
          this.finalId = this.getAward(data.award).awardPos;
          // 当没有找到相应的奖品信息时（用于mock环境下经常出现的情况），默认为未中奖状态。防止动画效果不正确的问题
          if (this.finalId === undefined) {
            this.finalId = this.getAward(0).awardPos;
            this.awardData.award = 0;
          }
        }
        // 记录请求结束后时间
        const aniIn = new Date().getTime();
        // 根据时间差计算转过的角度
        const curDegree = this.easeInOutQuad(aniIn - aniBefore, 360 - this.steps[this.finalId],
          (360 - this.steps[this.finalId]) + 360 * 110, 30000);
        // 计算已转多少圈
        const beishu = Math.floor(curDegree / 360);
        // 提前结束动画
        panel.style.cssText = `transform: rotateZ(${360 - this.steps[this.finalId] + 360 * (beishu + 10)}deg); transition:transform ${(aniIn - aniBefore) / 1000 + 5}s ease-out 0s`;
      });
    }
    const that = this;
    // 监听动画结束回调
    panel.addEventListener('transitionend', () => {
      that.onTransitionEnd();
    });
  }

  render() {
    const { outerClassName, pannelClassName, pointerClassName,
      remainTime, pannelImg, pointerImg, clickBtn } = this.props;
    return (
      <div className={outerClassName || styles.lotteryContainer}>
        <img
          id="panel"
          className={pannelClassName || styles.panel}
          src={pannelImg}
          alt=""
        />
        <img
          onClick={() => {
            if (clickBtn) {
              clickBtn((remain) => {
                if (this.animateEndBool) {
                  if (remainTime > 0) {
                    this.startRotate();
                  } else {
                    const { onNoneTime } = this.props;
                    if (onNoneTime) {
                      if (remain === 0) {
                        onNoneTime(0, '活动未开始');
                      } else if (remain === -1) {
                        onNoneTime(0, '活动已结束');
                      } else {
                        onNoneTime(1);
                      }
                    }
                  }
                }
              });
            }
          }}
          className={pointerClassName || styles.pointer}
          src={pointerImg}
          alt=""
        />
      </div>
    );
  }
}
