/*
 * @Author: jiangwei
 * @Date: 2023-08-14 10:49:22
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-05-24 19:18:50
 * @Descripttion: 支付加载loading页面，目前聚合支付页支付返回查询/跳转过程（聚合页、过渡页、结果页）使用
 */
import React, { Component } from 'react';
import styles from './index.less';

export default class PayLoading extends Component {
  static defaultProps = {
    styleType: 2, // loading样式 1-一个圆点绕双手转圈 2-两个圆点交叉移动 3-loading图片+文案
  };

  render() {
    const { styleType, children, loadingText } = this.props;

    if (styleType === 3) {
      return (
        <div className={styles.payLoading3}>
          <img className={styles.loadingImg} src={require('./img/img_loading.png')} alt="" />
          {loadingText ? <div className={styles.loadingText}>{loadingText}...</div> : null}
        </div>
      );
    }

    if (styleType === 1) {
      return (
        <div className={styles.payLoading}>
          <div className={styles.loading}>
            <div className={styles.dot} />
            <div className={styles.loadingImg}>
              <img className={styles.imgBao} src={require('./img/img_bao.png')} alt="" />
            </div>
          </div>
          <div className={styles.loadingText}>支付结果查询中，请稍后…</div>
        </div>
      );
    }

    return (
      <div className={styles.payLoading1}>
        <div className={styles.pointBox}>
          <div className={styles.point} />
          <div className={styles.point} />
        </div>
        {children}
      </div>
    );
  }
}
