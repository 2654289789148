export default [
  // 问答筛选结果
  {
    path: '/claim/service/online',
    component: () => import('./OnlineGuide'),
  },
  // 理赔问答
  {
    path: '/claim/service/question',
    component: () => import('./Question'),
  },
  // 理赔记录
  {
    path: '/claim/service/record',
    component: () => import('./ClaimRecord'),
  },
  // 理赔评价
  {
    path: '/claim/service/evaluate',
    component: () => import('./ClaimEvaluate'),
  },
  // 理赔申请页
  {
    path: '/claim/service/claimInfos',
    component: () => import('./ClaimInfos'),
  },
  // 提交结果页
  {
    path: '/claim/service/result',
    component: () => import('./Result'),
  },
  // 理赔详情
  {
    path: '/claim/service/claimDetail',
    component: () => import('./ClaimDetail'),
  },
  // 资料补传
  {
    path: '/claim/service/materialSupplement',
    component: () => import('./MaterialSupplement'),
  },
  // 理赔材料提示
  {
    path: '/claim/service/materialguide',
    component: () => import('./GetMaterialGuide'),
  },
  {
    path: '/claim/service/caseBook',
    component: () => import('./caseBook'),
  }, {
    // 理赔签字
    path: '/claim/service/Signature',
    component: () => import('./Signature'),
  }, {
    // 被保人签字
    path: '/claim/service/InsuranceSignature',
    component: () => import('./InsuranceSignature'),
  },
];
