/**
 * 红包展示组件
 * styleType=0 默认样式 styleType=1 红色背景样式 styleType=2 悬浮在按钮上方样式
 */
import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './index.less';

export default class Coupon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCouponClick = () => {
    const { history, userIdencrypt } = this.props;
    history.push(`/wallet/list?userIdencrypt=${userIdencrypt}`);
  }

  render() {
    const { className, amount = '', couponType = 1, styleType = 0, preText = '开通得' } = this.props;

    if (+styleType === 1) {
      // 处理红包金额
      const amountNum = amount.substr(0, amount.length - 1);
      return (
        <div className={classnames(className, styles.coupon1)}>
          <div className={styles.amount}><span>{amountNum}</span>元</div>
          <div className={styles.title}>{couponType === 1 ? '现金红包' : '手机话费'}</div>
          <div className={styles.btn} onClick={() => this.onCouponClick()}>点击领取</div>
        </div>
      );
    }
    if (+styleType === 2) {
      return (
        <div className={classnames(className, styles.coupon2)}>
          <div className={styles.tips}>
            {preText}{amount}{couponType === 1 ? '现金' : '话费'}
          </div>
          <img src={require('./img/btnTips_coupon.png')} alt="" />
        </div>
      );
    }

    return (
      <div className={classnames(className, styles.coupon)} onClick={() => this.onCouponClick()}>
        <div className={styles.title}>
          恭喜获得
          <span>{amount}</span>
          {+couponType === 1 ? '红包' : '话费'}
        </div>
        <div className={styles.tips}>请在【我的-钱包】查看</div>
      </div>
    );
  }
}
