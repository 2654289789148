/*
 * @Author: surun
 * @Date: 2022-11-11 14:17:58
 * @LastEditors: surun
 * @LastEditTime: 2022-12-27 11:38:25
 * @Description: 天机配置广告位
 */
import React, { Component } from 'react';
import { TianjiFetch } from 'fetch';
import { Toast } from '@yb/fe-component';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import { jumpByEnvType } from 'src/common/utils/common/jumpUtil';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { Carousel } from 'antd-mobile-v2';
import classnames from 'classnames';

import styles from './index.less';

export default class AdvertisingTianji extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adLists: [], // banner列表
    };
  }

  componentDidMount() {
    this._loadTianjiData(); // 加载天机数据
  }

  _loadTianjiData = () => {
    TianjiFetch.post('tianji/exchange/config/getMyPagesBanners')
      .then((res) => {
        this.setState({ adLists: res?.banners || [] });
      })
      .catch((err) => {
        Toast.fail(err);
      });
  };

  // 跳转页面
  _imgClick = (item, index) => {
    const { page, position } = this.props;
    const { env, jumpingLink, buryPoint, materialName = '', id = '', bucket } = item;
    jumpByEnvType(env, jumpingLink);
    const params = {
      banner_index: index + 1,
      banner_title: materialName,
      banner_ID: id,
      banner_page: page,
      position: position || '',
      bucket,
    };
    if (buryPoint) {
      trackEvent(buryPoint, params);
    }
  };

  _renderAdItem = (item, index) => {
    const { pic } = item;
    return (
      <img
        key={pic}
        src={pic}
        alt=""
        className={styles.contentImg}
        onLoad={() => {
          window.dispatchEvent(new Event('resize'));
        }}
        onClick={() => this._imgClick(item, index)}
      />
    );
  };

  render() {
    const { adLists } = this.state;
    const { className } = this.props;
    if (!adLists || !adLists.length) return null;
    return (
      <Carousel
        className={classnames(className, styles.adBg)}
        autoplay
        infinite
        autoplayInterval={5000}
        dotStyle={{ width: `${pxTOrem(4)}rem`, height: `${pxTOrem(4)}rem`, background: 'rgba(30, 196, 227, 0.2)', borderRadius: `${pxTOrem(4)}rem` }}
        dotActiveStyle={{ width: `${pxTOrem(8)}rem`, height: `${pxTOrem(4)}rem`, background: '#28B9CB', borderRadius: `${pxTOrem(4)}rem` }}
      >
        {adLists.map((item, index) => this._renderAdItem(item, index))}
      </Carousel>
    );
  }
}
