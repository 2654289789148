import React, { Component, Fragment } from 'react';
import { isWechat, isWeMini } from 'common/utils/device';
import userStore from 'store/UserStore';
import { hasConfigData } from 'ybcommon/wxApi';
import { url } from 'utils';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import { isShouxin } from 'ybcommon/ybutils/common/company';
import transparentBg from './transparent.png';

const disErrCodes = [20006];

const sevenDay = 7 * 24 * 60 * 60 * 1000;

/**
 * 微信订阅消息区域封装
 * @prop {Object} style 必填 必须包含width和height的对象 例子：{ width: 100,height: 100 }
 * @prop {Function} onClick 必填 点击的回调函数
 * @prop {Boolean} inlineBlock 选填 当前组件是块级还是行内元素
 * @prop {Object} innerStyle 背景图片的style 保留属性一般用不到
 */
export default class WxSubscribeView extends Component {
  constructor(props) {
    super(props);
    this.position = ''; // 渠道
    this.templateIds = []; // 当前模板id集合 数组形式
    this.tmpType = 1; // 当前模板属于的公众号 1商城 2元保
    this.state = {
      templateIdsStr: '', // 模板id逗号分隔字符串 要么是长期的，要么都是一次性的
      asyncShow: false,
      refresh: false,
      openDate: '',
      openCount: '',
    };
  }

  componentDidMount() {
    // 获取当前的position
    this.params = url(window.location.href).params;
    this.position = this.params.position || ''; // 默认渠道
    this._getDateCount();
    this._getTemplateIdsStr();
    /* 2021-06-30 sunch 确认不会有元保健康的模板了，只保留元保保险商城的埋点，所以默认tmpType都是1 */
    // const { isSubscribeYb, isSubscribeMall } = userStore;
    // if (isSubscribeMall) {
    //   this.tmpType = 1;
    // } else if (isSubscribeYb) {
    //   this.tmpType = 2;
    // } else {
    //   this.tmpType = 1;
    // }
    const { visible } = this.props;
    this.setState({
      asyncShow: visible,
    });
  }

  componentDidUpdate(preProps, preState) {
    const { visible } = this.props;
    const { refresh } = this.state;
    if (visible !== preProps.visible) {
      this._setAsyncShow(visible);
    }
    if (refresh && refresh !== preState.refresh) {
      // 重新获取模板数据
      userStore.getSubscribeTemplates().then(() => {
        // 重新获取模板和次数
        this._getDateCount();
        this._getTemplateIdsStr();
        this._setRefresh(false);
      });
    }
  }

  _setAsyncShow = (visible) => {
    setTimeout(() => {
      this.setState({
        asyncShow: visible,
      });
    }, 100);
  };

  _setRefresh = (refresh) => {
    setTimeout(() => {
      this.setState({
        refresh,
      });
    }, 0);
  };

  /**
   *
   */
  _getTemplateIdsStr = () => {
    const { longTmpList,
      disposableTmpList,
      longTmpIds,
      longTmpIdsStr,
      disposableTmpIds,
      disposableTmpIdsStr } = userStore;
    // 优先长期模板 判断是否拒绝
    const longHasReject = longTmpList.some((item) => String(item.status).toLowerCase() !== 'accept');
    const disposableHasReject = disposableTmpList.some((item) => String(item.status).toLowerCase() !== 'accept' || item.count <= 5);
    if (longTmpIdsStr && longHasReject) {
      this.setState({
        templateIdsStr: longTmpIdsStr,
      });
      this.templateIds = longTmpIds;
    } else if (disposableTmpIdsStr && disposableHasReject) {
      this.setState({
        templateIdsStr: disposableTmpIdsStr,
      });
      this.templateIds = disposableTmpIds;
    }
  };

  /**
   *
   * @returns
   */
  _getDateCount = () => {
    const openSubInfo = localStorage.getItem('openSubInfo');
    const openObj = openSubInfo ? JSON.parse(openSubInfo) : null;
    if (!openObj) return;
    const { date, count } = openObj;
    this.setState({
      openDate: date,
      openCount: count,
    });
  };

  /**
   * 单个模板上报
   * @param {*} templateId
   * @param {*} isrefuse
   * @param {*} issuccess
   * @param {*} failreason
   */
  _trackEvent = (templateId, isrefuse, issuccess, failreason) => {
    let eventName = '';
    // 埋点名称
    if (this.tmpType === 1) { // 如果是元保商城
      eventName = 'h5_bxMall_all_messagesq';
    } else { // 如果是元保
      eventName = 'h5_yb_all_messagesq';
    }
    trackEvent(eventName, {
      enter: this.position, messageID: templateId, isrefuse, issuccess, failreason,
    });
  };

  /**
 * 所有模板上报
 * @param {*} templateIds
 * @param {*} isrefuse
 * @param {*} issuccess
 * @param {*} failreason
 */
  _allTempTrackEvent = (templateIds, isrefuse, issuccess, failreason) => {
    templateIds.forEach((templateId) => {
      if (templateId) {
        this._trackEvent(templateId, isrefuse, issuccess, failreason);
      }
    });
  };

  /**
   * 更新当日的弹窗显示次数
   */
  _setOpenSubInfo = () => {
    const openSubInfo = localStorage.getItem('openSubInfo');
    const openObj = openSubInfo ? JSON.parse(openSubInfo) : null;
    let newCount = 1;
    let date = Date.now();
    // 小于7天更新次数不更新时间
    if (openObj && (date - openObj.date <= sevenDay)) {
      newCount += 1;
      date = openObj.date;
    }
    localStorage.setItem('openSubInfo', JSON.stringify({ date, count: newCount }));
  };

  _addListenOnWX = () => {
    if (this._wxRef) {
      // 调起成功回调
      this._wxRef.addEventListener('success', (e) => {
        // console.log(' ================ subscribe success ================ ');
        // console.log(e);
        // console.log(' ================ subscribe success ================ ');
        const errMsg = e.detail.errMsg || '';
        const { onSubscribeSuccess, onSubscribeFail, onClick } = this.props;
        if (errMsg === 'subscribe:ok') {
          this._setOpenSubInfo();
          // console.log('e.detail.subscribeDetails', e.detail.subscribeDetails);
          const subscribeDetails = JSON.parse(e.detail.subscribeDetails);
          // console.log('parse subscribeDetails: ', subscribeDetails);
          if (subscribeDetails) {
            for (const messageID in subscribeDetails) {
              if (subscribeDetails[messageID]) {
                const statusObj = JSON.parse(subscribeDetails[messageID]);
                // console.log('parse status object: ', statusObj);
                if (statusObj && statusObj.status === 'accept') {
                  this._trackEvent(messageID, false, true, '');
                } else {
                  this._trackEvent(messageID, true, false, 'user reject');
                }
              }
            }
          }
          if (onSubscribeSuccess) { onSubscribeSuccess(); }
        } else {
          this._allTempTrackEvent(this.templateIds, false, false, errMsg);
          if (onSubscribeFail) onSubscribeFail();
        }
        this.setState({
          refresh: true,
        });
        if (onClick) onClick();
      });

      // 调起失败回调
      this._wxRef.addEventListener('error', (e) => {
        // console.log(' ================ subscribe error ================ ');
        // console.log(e.detail);
        // console.log(' ================ subscribe error ================ ');
        /**
         * ！！！特别注意！！！
         * 如果页面上消息订阅按钮超过了10个，会走到错误这里，错误码为20006。需要做拦截处理，否则会自动触发回调方法。
         * 如果ios端点了消息订阅弹窗的“取消”按钮，会走到错误这里，错误码为10006。需要允许继续向下执行。并且需要更新已经弹出过消息订阅弹窗的次数。
         */
        if (disErrCodes.some((errCode) => +e.detail.errCode === errCode)) {
          // console.log(`未知的报错原因，错误码：${e.detail.errCode}，错误原因：${e.detail.errMsg}`);
          this._allTempTrackEvent(this.templateIds, false, false, `错误码：${e.detail.errCode}，错误原因：${e.detail.errMsg}`);
          return;
        }
        if (+e.detail.errCode === 10006) this._setOpenSubInfo();
        this._allTempTrackEvent(this.templateIds, false, false, `错误码：${e.detail.errCode}，错误原因：${e.detail.errMsg}`);
        const { onSubscribeFail, onClick } = this.props;
        if (onSubscribeFail) onSubscribeFail();
        if (onClick) onClick();
      });
    }
  };

  /**
   * 渲染插入的子元素
   * @returns {jsx} 插入的内容
   */
  _renderChilds = (children) => {
    if (Array.isArray(children)) {
      return children.map((child, index) => {
        const key = `fragment_${index}`;
        return <Fragment key={key}>{child}</Fragment>;
      });
    }

    if (children) {
      return children;
    }

    return null;
  };

  _renderWxSubscribeView = ({ children, templateIdsStr, className, inlineBlock, onClick, style = {}, bgStyle = {}, outerStyle }) => {
    this.clientWidth = this.clientWidth || document.body.clientWidth;
    const newStyle = { ...style };
    const newBgStyle = { ...bgStyle };
    if (this.clientWidth) {
      const { width, height } = style;
      const { width: innerStyleWidth, height: innerStyleHeight } = bgStyle;
      const factor = this.clientWidth / 375;
      newStyle.width = width && Math.floor(width * factor);
      newStyle.height = height && Math.floor(height * factor);
      newBgStyle.width = innerStyleWidth && Math.floor(innerStyleWidth * factor);
      newBgStyle.height = innerStyleHeight && Math.floor(innerStyleHeight * factor);
    }
    if (!hasConfigData() || isShouxin()) {
      return this._renderChilds(children);
    }
    return (
      <div className={className} style={{ position: 'relative', display: inlineBlock ? 'inline-block' : 'block', height: `${pxTOrem(style.height)}rem`, ...outerStyle }}>
        {
          this._renderChilds(children)
        }
        {/* 订阅组件通过绝对定位覆盖在子元素上 */}
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        >
          <wx-open-subscribe
            template={templateIdsStr}
            ref={(ref) => {
              if (ref && !this._wxRef) {
                this._wxRef = ref;
                this._addListenOnWX();
              }
            }}
            style={{ ...newStyle, overflow: 'hidden' }}
          >
            {__DEV__ ? (
              <div
                style={{
                  backgroundImage: `url(${transparentBg})`,
                  backgroundSize: '100% auto',
                  backgroundRepeat: 'no-repeat',
                  ...newBgStyle,
                }}
                onClick={onClick}
              />
            ) : (
              <script type="text/wxtag-template">
                <div
                  style={{
                    backgroundImage: `url(${transparentBg})`,
                    backgroundSize: '100% auto',
                    backgroundRepeat: 'no-repeat',
                    ...newBgStyle,
                  }}
                />
              </script>
            )}
          </wx-open-subscribe>
        </div>
      </div>
    );
  };

  _renderSubscribeBtn = () => {
    const { children, className, inlineBlock, wrapStyle, style, innerStyle, onClick, async } = this.props;
    const { templateIdsStr, asyncShow, openDate, openCount } = this.state;
    // 如果7日已显示两次 不再弹出订阅
    if (openDate && openCount && (Date.now() - openDate <= sevenDay) && openCount > 1) {
      // console.log(' &&&&&&&&&&& 今日打开次数已超过2次 &&&&&&&&&&');
      return this._renderChilds(children);
    }
    const isWechatStatus = __DEV__ ? true : isWechat() && !isWeMini(); // 只能在微信内且不能是小程序
    if (!isWechatStatus || !style || !style.height || !style.width) {
      // console.log(' &&&&&&&&&&& 不在微信环境或无宽高 &&&&&&&&&&');
      return this._renderChilds(children);
    }
    const { longTmpList, disposableTmpList } = userStore;
    // 优先长期模板 判断是否拒绝
    const longHasReject = longTmpList.some((item) => String(item.status).toLowerCase() !== 'accept');
    const disposableHasReject = disposableTmpList.some((item) => String(item.status).toLowerCase() !== 'accept' || item.count <= 5);
    // console.log(` %%%%%% longHasReject : ${longHasReject} %%%%%%  disposableHasReject: ${disposableHasReject} %%%%%%`);
    if (!templateIdsStr) {
      // console.log(' &&&&&&&&&&& 两种模板都不存在 &&&&&&&&&&');
      return this._renderChilds(children);
    }
    if (templateIdsStr && !longHasReject && !disposableHasReject) {
      // console.log(' &&&&&&&&&&& 两种模板都满足订阅条件 &&&&&&&&&&');
      return this._renderChilds(children);
    }
    const bgStyle = innerStyle || style;
    const outerStyle = wrapStyle || {};
    // console.log(' &&&&&&&&&&& 渲染订阅消息开放标签 &&&&&&&&&&');

    const argu = { children, templateIdsStr, className, inlineBlock, onClick, style, bgStyle, outerStyle };
    if (async) {
      return asyncShow ? this._renderWxSubscribeView(argu) : this._renderChilds(children);
    }
    return this._renderWxSubscribeView(argu);
  };

  render() {
    return this._renderSubscribeBtn();
  }
}
