export default {
  path: '/test',
  component: () => import('./index'),
  routes: [
    {
      path: '/test/xcx',
      component: () => import('./XcxTest'),
    },
    {
      path: '/test/style',
      component: () => import('./StyleTest'),
    },
    {
      path: '/test/comp',
      component: () => import('./CompTest'),
    },
    {
      path: '/test/device',
      component: () => import('./DeviceTest'),
    },
    {
      path: '/test/pay',
      component: () => import('./PayTest'),
    },
    {
      path: '/test/baseclass',
      title: '基类测试',
      component: () => import('./baseclass'),
    },
    {
      path: '/test/ctx',
      component: () => import('./ContextTest'),
    },
    {
      path: '/test/mobx',
      component: () => import('./ContextTest/mobxRender'),
    },
    {
      path: '/test/plugin',
      component: () => import('./plugin'),
    },
  ],
};
