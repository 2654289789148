/*
 * @Author: xiaotao xiaotao@yuanbaobaoxian.com
 * @Date: 2024-01-26 10:30:19
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-07-26 17:49:28
 * @FilePath: /fe-insurance-audio/packages/fe-main-web/src/sys/shouxin/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
  {
    path: '/',
    component: () => import('./home'),
    exact: true,
  },
  {
    path: '/my',
    component: () => import('./my'),
    exact: true,
  },
  {
    path: '/middle',
    component: () => import('./middle'),
  },
];
