/*
 * @Author: shixiaoxia
 * @Date: 2022-07-27 13:33:35
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-08-29 19:51:53
 * @Description: 用户中心fetch
 */
import { getEnv, getHostSuffix } from 'ybcommon/envUtil';
import Fetch from './base';

const hostsuffix = getHostSuffix();

let _ucFetch;
if (__PUB__) {
  // TODO: WAITING FOR MODIFY
  _ucFetch = new Fetch({ baseURL: `//passport.${hostsuffix}`, withCredentials: true });
} else if (__TEST__) {
  // const envName = getEnv() === 'test2' ? 'dev' : getEnv();
  const envName = getEnv();
  _ucFetch = new Fetch({ baseURL: `//auth-center-${envName}.${hostsuffix}`, withCredentials: true });
} else {
  // 本地暂以代理的形式调用户中心的接口
  _ucFetch = new Fetch({ baseURL: '/authCenter' });
}
const UCFetch = _ucFetch.getInstance();

export default /** @type {any} */ (UCFetch);
