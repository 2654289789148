export default {
  path: '/promote',
  component: () => import('./index'),
  routes: [
    {
      path: '/promote/wxstep',
      component: () => import('./WXStep'),
      exact: true,
    },
    {
      path: '/promote/sms4fee',
      component: () => import('./Sms4Fee'),
      exact: true,
    },
    {
      path: '/promote/rescind',
      component: () => import('./Rescind'),
      exact: true,
    },
    {
      path: '/promote/guide',
      component: () => import('./Guide'),
      exact: true,
    },
  ],
};
