import React, { Component } from 'react';
import transparentBg from './transparent.png';
/**
 * 微信小程序跳转按钮封装
 */
export default class WxminBtn extends Component {
  _addListenOnWX = () => {
    const { eleId } = this.props;
    const btn = document.getElementById(eleId);
    if (btn) {
      btn.addEventListener('ready', (e) => {
        console.log('==============');
        console.log(e, 'we-launch-ready');
        console.log('==============');
      });

      // 调起成功回调
      btn.addEventListener('launch', (e) => {
        console.log(e, 'success');
        const { onLaunchSuccess } = this.props;
        if (onLaunchSuccess) onLaunchSuccess();
      });

      // 调起失败回调
      btn.addEventListener('error', (e) => {
        const { onLaunchFail } = this.props;
        if (onLaunchFail) onLaunchFail();
        console.log('fail', e.detail);
      });
    }
  }

  _renderToMini = () => {
    const { eleId, style = {}, innerStyle = {},
      miniPath = 'pages/home/index.html', appId = 'wx675d4832af8b052d', appUsername = 'gh_a14d34cd670d' } = this.props;
    this.clientWidth = this.clientWidth || document.body.clientWidth;// 避免重复查询dom 影响性能
    const newStyle = { ...style };
    const newInnerStyle = { ...innerStyle };
    if (this.clientWidth) {
      const { width, height } = style;
      const { width: innerStyleWidth, height: innerStyleHeight } = innerStyle;
      const factor = this.clientWidth / 375;
      newStyle.width = width && Math.floor(width * factor);
      newStyle.height = height && Math.floor(height * factor);
      newInnerStyle.width = innerStyleWidth && Math.floor(innerStyleWidth * factor);
      newInnerStyle.height = innerStyleHeight && Math.floor(innerStyleHeight * factor);
    }
    const _tmpView = (
      <div
        style={{
          backgroundImage: `url(${transparentBg})`,
          backgroundSize: '100% auto',
          backgroundRepeat: 'no-repeat',
          ...newInnerStyle,
        }}
      />
    );
    return (
      <div style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      >
        <wx-open-launch-weapp
          id={eleId}
          appId={appId}
          username={appUsername}
          path={miniPath}
          ref={(ref) => {
            if (ref && !this._wxRef) {
              this._wxRef = ref;
              this._addListenOnWX();
            }
          }}
          style={newStyle}
        >
          {__DEV__ ? (_tmpView) : (
            <script type="text/wxtag-template">
              {_tmpView}
            </script>
          )}
        </wx-open-launch-weapp>
      </div>
    );
  }

  render() {
    return this._renderToMini();
  }
}
