export default {
  path: '/triger',
  routes: [
    {
      path: '/triger/detail',
      component: () => import('./index'),
    },
    {
      path: '/triger/list',
      component: () => import('./List/index'),
    },
    {
      path: '/triger/public',
      component: () => import('./Public/index'),
    },
    {
      path: '/triger/award',
      component: () => import('./Award/index'),
    },
    {
      path: '/triger/awardTemp',
      component: () => import('./AwardTemp/index'),
    },
  ],
};
