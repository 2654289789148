/*
 * @Author: cuilinchao
 * @Date: 2024-10-12 14:52:20
 * @LastEditors: cuilinchao
 * @LastEditTime: 2024-10-12 14:56:48
 * @Description: 相互重疾 会员权益相关路由
 */
export default {
  path: '/equity',
  routes: [
    {
      path: '/equity/detail',
      component: () => import('./Detail'),
      title: '会员权益值',
    },
    {
      path: '/equity/list',
      component: () => import('./List'),
      title: '权益值记录',
    },
  ],
};
