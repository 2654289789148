/**
 * 理赔流程公用组件
 */
import React, { Component } from 'react';
import { Steps } from 'antd-mobile-v2';

import { isAndroid } from 'common/utils/device';

import classNames from 'classnames';
import styles from './index.less';

export default class ClaimFlow extends Component {
  _renderInnerHtml = (value) => <div className={styles.value} dangerouslySetInnerHTML={{ __html: value }} />;

  _renderValue = (value, isHtml) => {
    const { valueStyle = {} } = this.props;
    if (isHtml) return this._renderInnerHtml(value);
    return (
      <div className={styles.value} style={valueStyle}>
        {value}
      </div>
    );
  };

  _renderIndex = (index) => {
    const { iconStyle = {}, theme } = this.props;
    return (
      <div className={classNames(styles.icon, { [styles.icon1]: theme === 1 })} style={iconStyle}>
        {index}
      </div>
    );
  };

  render() {
    const { style, dashed = true, className, processJson, theme, isShowCardStyle } = this.props;
    // eslint-disable-next-line no-nested-ternary
    const conclass = dashed ? (isAndroid() ? styles.claimconAndroid : styles.claimcon) : styles.claimcon2;
    return (
      <div style={style} className={classNames(conclass, className, { [styles.conclass1]: theme === 1, [styles.inCard]: isShowCardStyle })}>
        <Steps>
          {processJson
            && processJson.map((s, index) => <Steps.Step key={`${s.key}_${index + 1}`} title={s.key} description={this._renderValue(s.value, s.isHtml)} icon={this._renderIndex(index + 1)} />)}
        </Steps>
      </div>
    );
  }
}
