/*
 * @Author: wangchen wangchen@yuanbaobaoxian.com
 * @Date: 2023-09-07 13:59:31
 * @LastEditors: wangchen wangchen@yuanbaobaoxian.com
 * @LastEditTime: 2024-02-28 19:12:21
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/about/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  path: '/about',
  component: () => import('./index'),
  routes: [
    {
      path: '/about/qualification',
      component: () => import('./Qualification'),
    },
    {
      path: '/about/partner',
      component: () => import('./Partner'),
    },
    {
      path: '/about/rights',
      component: () => import('./rights'),
      routes: [
        {
          path: '/about/rights/article',
          component: () => import('./rights/reprintedArticleDetail'),
        },
      ],
    },
  ],
};
