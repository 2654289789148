// 是否在iframe里
const isInIframeFunc = () => {
  try {
    if (window.frameElement && window.frameElement.tagName === 'IFRAME') {
      return true;
    }
    if (window !== window.top) {
      return true;
    }
    if (window.parent !== window && window.frames.length > 0) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const isInIframe = isInIframeFunc();
