// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pophoverCon_B\\+dojDvM {\n  width: 5.06667rem;\n  position: absolute;\n  z-index: 9999;\n  padding: 0.26667rem 0.4rem;\n  line-height: 0.48rem;\n  font-size: 0.32rem !important;\n  background-color: rgba(0, 0, 0, 0.5);\n  border-radius: 0.10667rem;\n  text-align: justify;\n  color: #fff;\n  box-sizing: border-box;\n}\n.pophoverCon_B\\+dojDvM .pophoverTri_tfs9nRHc {\n  position: absolute;\n  border-color: transparent transparent rgba(0, 0, 0, 0.5);\n  border-style: solid;\n  border-width: 0 0.26667rem 0.26667rem;\n  top: -0.26667rem;\n  left: 50%;\n  -webkit-transform: translateX(-50%);\n  -ms-transform: translateX(-50%);\n      transform: translateX(-50%);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pophoverCon": "pophoverCon_B+dojDvM",
	"pophoverTri": "pophoverTri_tfs9nRHc"
};
export default ___CSS_LOADER_EXPORT___;
