import { UCFetch } from 'fetch';
import { isShouxin } from 'ybcommon/ybutils/common/company';
import { openHrefUrl } from 'store/UrlTools';
import { addNewUserInfoToUrl } from 'utils/common/common';
import { addUrlParams } from 'ybcommon/ybutils/common/common';
import { getMediaUuid } from 'ybcommon/ybmodel/tgUtil';
import { getSessionID } from 'ybcommon/ybutils/common/sessionIdUtil';
import userStore from '../../store/UserStore';
/**
   * 跨域同步
   * @param {*} redirectUrl 重定向地址
   * @param {*} isrep 是否替换当前链接
   */
export const crossRegion = (redirectUrl, isrep = true) => {
  let tempUrl = redirectUrl.split('#')[0];
  const { host } = window.location;
  if (!userStore.hasLogin || tempUrl.indexOf(host) !== -1 || isShouxin()) {
    openHrefUrl(redirectUrl, isrep);
    return;
  }
  const tempMediaUuid = getMediaUuid();
  tempUrl = addUrlParams(tempUrl, { mediaUuid: tempMediaUuid, busiTraceId: getSessionID() });
  tempUrl = addNewUserInfoToUrl(tempUrl);

  // 跨域
  UCFetch.post('/api/cross/encrypt')
    .then((res) => {
      tempUrl = addUrlParams(tempUrl, { encryData: res });
      openHrefUrl(tempUrl, isrep);
    })
    .catch((err) => {
      console.log(err);
    });
};
