// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tipsBg_cwdIg7j- {\n  position: fixed;\n  top: 0;\n  z-index: 40;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 10rem;\n  height: 0.88rem;\n}\n.tipsBg_cwdIg7j- .bgImg_qjzsODuU {\n  width: 100%;\n  height: 0.88rem;\n}\n.tipsBg_cwdIg7j- .text_jydFxVfL {\n  position: absolute;\n  z-index: 1;\n  font-size: 0.4rem;\n  font-family: PingFangSC-Medium, PingFang SC;\n  font-weight: 500;\n  color: #FFFFFF;\n  line-height: 0.56rem;\n  letter-spacing: 1px;\n  text-shadow: 0px 1px 1px #FD4B09;\n}\n.tipsBg_cwdIg7j- .text_jydFxVfL > span {\n  color: #FFF1A9;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tipsBg": "tipsBg_cwdIg7j-",
	"bgImg": "bgImg_qjzsODuU",
	"text": "text_jydFxVfL"
};
export default ___CSS_LOADER_EXPORT___;
