/*
 * @Author: shixiaoxia
 * @Date: 2022-10-27 16:30:41
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-01-09 16:01:16
 * @Description: 404页面
 */
import * as React from 'react';
import { isYB } from 'ybcommon/ybutils/common/company';
import { NotFound } from '@yb/fe-component';
import styles from './style.less';

interface IProps {
  history: any;
}

export default class Page404 extends React.Component<IProps> {
  _onClick = () => {
    const { history } = this.props;
    history.push('/');
  }

  render() {
    return (
      <NotFound onClick={this._onClick} className={isYB() ? styles.container : null} />

    );
  }
}
