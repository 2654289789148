// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".suspendBtn_loyu-Qr5 {\n  position: fixed;\n  bottom: 15%;\n  right: calc(50vw - 5rem + 0.32rem);\n  width: 1.4rem;\n  height: 1.4rem;\n  border-radius: 0.72rem;\n  background: #fff;\n}\n.pactive_jsfpXtFG {\n  -webkit-animation: rotate_ZSnbvIC1 8s linear infinite;\n          animation: rotate_ZSnbvIC1 8s linear infinite;\n}\n@-webkit-keyframes rotate_ZSnbvIC1 {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg);\n  }\n  25% {\n    -webkit-transform: rotate(90deg);\n            transform: rotate(90deg);\n  }\n  50% {\n    -webkit-transform: rotate(180deg);\n            transform: rotate(180deg);\n  }\n  75% {\n    -webkit-transform: rotate(270deg);\n            transform: rotate(270deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg);\n  }\n}\n@keyframes rotate_ZSnbvIC1 {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg);\n  }\n  25% {\n    -webkit-transform: rotate(90deg);\n            transform: rotate(90deg);\n  }\n  50% {\n    -webkit-transform: rotate(180deg);\n            transform: rotate(180deg);\n  }\n  75% {\n    -webkit-transform: rotate(270deg);\n            transform: rotate(270deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg);\n  }\n}\n.suspendBtn_loyu-Qr5 img {\n  width: 100%;\n  height: 100%;\n}\n.suspendBtnanimate_pZib5aMi {\n  transition-duration: 0.4s;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suspendBtn": "suspendBtn_loyu-Qr5",
	"pactive": "pactive_jsfpXtFG",
	"rotate": "rotate_ZSnbvIC1",
	"suspendBtnanimate": "suspendBtnanimate_pZib5aMi"
};
export default ___CSS_LOADER_EXPORT___;
