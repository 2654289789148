import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Tabs, Icon } from 'antd-mobile-v2';
import { isNoNeedBack, autoBackHistory } from 'ybcommon/ybutils/common/backutil';
import { YBModal } from 'src/component';
import ProtocolShowModal from '../ProtocolShowModal';
import { SubMenu, CombineSubMenu } from '../SubMenu';

import styles from './index.less';

const isGift = window.location.pathname.includes('gift/detail');

/**
 * 协议优化弹窗
 */
@inject('userStore', 'toolStore')
@observer
class ProtocalOptimizeModal extends Component {
  static getDerivedStateFromProps(props, state) {
    let update = null;

    if (props?.refClassName && state.refClassName !== props.refClassName) {
      if (!update) update = {};
      update.refClassName = props.refClassName;
      const dom = document.querySelector(`.${props.refClassName}`);

      if (dom) {
        const { height: h } = dom.getBoundingClientRect();
        update.height = h;
      }
    }

    if (!ProtocalOptimizeModal.show && props.visible) {
      ProtocalOptimizeModal.show = true;
      if (!update) update = {};
      if (props.tabVal !== state.tabVal) {
        update.tabVal = props.tabVal;
      }
      if (props.isFold !== state.isFold) {
        update.isFold = props.isFold;
        if (update.isFold === 3) {
          update.tabs = [
            { title: '保险条款' },
            { title: '重要协议' },
            { title: '隐私政策' },
          ];
        } else if (update.isFold === 4) {
          update.tabs = [
            { title: '保险条款' },
            { title: '隐私政策' },
          ];
        }
      }
    }

    if (update) return update;

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      tabVal: props.tabVal,
      modalVisible: false,
      modalProps: {},
      refClassName: '',
      height: '10rem',
      tabs: [
        { title: '保险条款' },
        { title: '重要协议' },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    const { visible, scrollToActive } = this.props;
    if (scrollToActive && !prevProps.visible && visible) {
      const container = document.getElementById('protocal-optomize-modal-combine-list');
      const item = document.getElementById('protocal-optomize-modal-combine-active-item');
      if (container && item) {
        setTimeout(() => {
          container.scrollTo(0, item.offsetTop - item.clientHeight / 2);
        }, 0);
      }
    }
  }

  componentWillUnmount() {
    ProtocalOptimizeModal.show = null;
    ProtocalOptimizeModal.refClassName = null;
    ProtocalOptimizeModal.height = null;
  }

  /**
   * 协议展示modal
   * @param {*} e 鼠标事件
   * @param {*} title 协议标题
   * @param {*} path 协议id
   */
  _nextPageClickModal = (e, title, path, version) => {
    e.stopPropagation();
    this.setState({
      modalVisible: true,
      modalProps: {
        protocalTitle: title,
        protocolId: path,
        protocolVersion: version,
        onClose: () => {
          this.setState({ modalVisible: false, modalProps: {} });
        },
      },
    }, () => {
      const { onStatic, protocalType } = this.props;
      if (onStatic && (protocalType || isGift)) onStatic('h5_cargodetail_xieyixiangqing_show', { agreement_ID: path, agreement_place: protocalType || '' }); // 协议详情浏览
    });
  };

  // tab切换
  onTabChange = (tab, index) => {
    this.setState(
      {
        tabVal: index,
      },
    );
  };

  _renderTabUnderline = (ulProps) => {
    const { style: underLineStyle } = ulProps;
    const ulStyle = {
      ...underLineStyle,
      position: 'absolute',
      border: 'none',
      bottom: 0,
    };
    return (
      <div style={ulStyle}>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '1.6rem',
            height: '3px',
            backgroundColor: '#FF6E11',
            borderRadius: '3px',
          }}
        />
      </div>
    );
  };

  _renderTabBar = (tabbarProps) => <Tabs.DefaultTabBar {...tabbarProps} renderTab={(tab) => <div>{tab.title}</div>} renderUnderline={this._renderTabUnderline} />;

  _renderTabs = () => {
    const { tabVal, tabs } = this.state;
    const { protocolList, hideTab } = this.props;

    if (!protocolList) return null;

    // 隐藏Tab时，只展示一个标题，所有内容合并到一个列表
    if (hideTab) {
      return <div className={styles.title}>条款内容</div>;
    }

    return (
      <div
        id="remind"
      >
        <Tabs
          tabs={tabs}
          page={tabVal}
          onChange={this.onTabChange}
          swipeable={false}
          animated={false}
          renderTabBar={this._renderTabBar}
          tabBarTextStyle={
            {
              fontWeight: 500,
              fontSize: '0.48rem',
            }
          }
          tabBarInactiveTextColor="#454545"
          tabBarActiveTextColor="#FF6E11"
        />
      </div>
    );
  };

  _onClickSubMenuItem = (e, item) => {
    if (e) e.stopPropagation();
    const { protocolId, version } = item;
    this._nextPageClickModal(e, '保险条款', protocolId, version);
  };

  _onClickCombineSubMenuItem = (e, item) => {
    if (e) e.stopPropagation();
    const { value, key, defaultKey, version } = item;
    this._nextPageClickModal(e, defaultKey || key, value, version);
  };

  // 协议列表模式
  _renderCombineProtocolList = () => {
    const { protocolList, protocalTitle } = this.props;
    const { tabVal } = this.state;

    // console.log('arr....', protocolList, tabVal, protocalTitle);

    if (!protocolList || !protocolList[tabVal]) return null;

    return (
      <div id="protocal-optomize-modal-combine-list" className={styles.proList}>
        {
          protocolList[tabVal].length ? (
            protocolList[tabVal].map((item, index) => (
              <Fragment key={`${item.key}-${index + 1}`}>
                {
                  (item?.list?.length) // 当协议类型为保险条款(type为5)且子菜单数量超过1个时渲染二级列表组件
                    ? <CombineSubMenu data={item} protocalTitle={protocalTitle} clickItemHandler={this._onClickCombineSubMenuItem} extendsClassName={styles.subList} />
                    : (
                      <div
                        className={styles.listItem}
                        onClick={
                          item.key ? ((e) => { this._nextPageClickModal(e, item.key, item.value, item.version); }) : item.handleClick
                        }
                      >
                        <div style={{ color: '#000' }}>{item.key || item.title}</div>
                        <Icon type="right" size="xxs" color="#A8ADB1" />
                      </div>
                    )
                }
                {index !== protocolList[tabVal].length - 1 ? <div className={styles.hline} /> : <div />}
              </Fragment>
            ))
          ) : (
            <div className={styles.withoutProtocol}>抱歉，没有更多协议啦～</div>
          )
        }
      </div>
    );
  };

  // 协议列表模式
  _renderProtocolList = () => {
    const { protocolList, isCombineProtocol } = this.props;
    const { tabVal } = this.state;

    if (isCombineProtocol) {
      return this._renderCombineProtocolList();
    }
    // console.log('arr....', protocolList, tabVal);

    if (!protocolList || !protocolList[tabVal]) return null;
    return (
      <div className={styles.proList}>
        {
          protocolList[tabVal].length ? (
            protocolList[tabVal].map((item, index) => (
              <Fragment key={`${item.key}-${index + 1}`}>
                {
                  (item.type === 5 && item?.contractTerms?.length > 1) // 当协议类型为保险条款(type为5)且子菜单数量超过1个时渲染二级列表组件
                    ? <SubMenu data={item} clickItemHandler={this._onClickSubMenuItem} extendsClassName={styles.subList} />
                    : (
                      <div
                        className={styles.listItem}
                        onClick={
                          item.key ? ((e) => { this._nextPageClickModal(e, item.key, item.value, item.version); }) : item.handleClick
                        }
                      >
                        <div style={{ color: '#000' }}>{item.key || item.title}</div>
                        <Icon type="right" size="xxs" color="#A8ADB1" />
                      </div>
                    )
                }
                {index !== protocolList[tabVal].length - 1 ? <div className={styles.hline} /> : <div />}
              </Fragment>
            ))
          ) : (
            <div className={styles.withoutProtocol}>抱歉，没有更多协议啦～</div>
          )
        }
      </div>
    );
  };

  // 关闭弹窗
  _onCloseProtocal = () => {
    const { toolStore, onClose } = this.props;
    const { modalVisible } = this.state;

    // 弹窗展示标识初始化
    if (ProtocalOptimizeModal.show) ProtocalOptimizeModal.show = null;
    // 弹窗history记录去除
    if (toolStore.optimizeModalVisible) autoBackHistory();
    // history后退时，关闭协议内容弹窗
    if (modalVisible) {
      this.setState({ modalVisible: false, modalProps: {} });
    }
    if (isNoNeedBack()) {
      toolStore.hideOptimizeModal();
    }
    if (onClose) {
      onClose();
    }
  };

  render() {
    const { modalVisible, modalProps, height } = this.state;
    const { visible, refClassName } = this.props;
    let contentHeight = refClassName ? height ?? '11.63rem' : '11.63rem';
    if (!visible) {
      contentHeight = 0;
    }
    return (
      <Fragment>
        <YBModal modalStyle={{ zIndex: 1005 }} popup visible={visible} onMaskClick={this._onCloseProtocal} onClose={this._onCloseProtocal} animationType="slide-up" wrapClassName={styles.protocalModalWrap}>
          <div className={styles.contentWrap} style={{ height: contentHeight }}>
            {this._renderTabs()}
            <span className={styles.close} onClick={this._onCloseProtocal}>
              <img src={require('./img/close.png')} alt="关闭" />
            </span>
            {this._renderProtocolList()}
          </div>
        </YBModal>
        <ProtocolShowModal visible={modalVisible} {...modalProps} />
      </Fragment>
    );
  }
}

export default ProtocalOptimizeModal;
