// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap_q-E4UxOq {\n  width: 6.66667rem;\n  height: 0.93333rem;\n  background-color: rgba(0, 0, 0, 0.35);\n  border-radius: 0.53333rem;\n  position: fixed;\n  top: 0.18667rem;\n  left: calc(50% - 3.33333rem);\n  z-index: 1001;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: 500;\n  font-size: 0.37333rem;\n  letter-spacing: 0.5px;\n}\n.wrap_q-E4UxOq .icon_Hz2t4g7z {\n  width: 0.37333rem;\n  height: 0.37333rem;\n  margin-right: 0.17333rem;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap_q-E4UxOq",
	"icon": "icon_Hz2t4g7z"
};
export default ___CSS_LOADER_EXPORT___;
