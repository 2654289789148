/*
 * @Author: pyj
 * @Date: 2023-08-25 16:52:29
 * @LastEditors: pyj
 * @LastEditTime: 2023-08-25 17:13:12
 * @Description: 弹窗任务相关回调，主要作用于弹窗打开之前、关闭之后要执行相关任务回调。
 * 任务编写执行主要有以下三点注意事项
 * 任务 注册/销毁 时机：在需要使用到该弹窗的页面文件中mount进行注册，unmount进行销毁
 * 任务执行时机：相关任务将会分别在弹窗打开之前、关闭之后进行调用
 * 任务编写规范：任务进行注册时需要成对注册。第一个代表打开之前执行的任务，第二个代表关闭之后执行的任务。
 *             如需进行标识判断，在任务内部进行相关判断逻辑的处理
 */
import stores from 'store';

type ITasks = [(props) => void | null, (props) => void | null | undefined];

const { toolStore } = stores;

/**
 * 注册任务
 * @param key 任务key
 * @param tasks 任务数组 - 打开之前的任务、关闭之后的任务(成对)
 * @returns boolean 注册成功/失败
 */
const modalTaskRegister = (key: string, tasks: ITasks): boolean => {
  if (toolStore?.modalShowInterceptor?.get && !toolStore?.modalShowInterceptor?.get?.(key)) {
    toolStore?.modalShowInterceptor?.set?.(key, tasks);
    return true;
  }

  return false;
};

/**
 * 删除任务
 * @param key 任务key
 * @returns boolean 删除成功/失败
 */
const modalTaskDelete = (key: string): boolean => {
  if (toolStore?.modalShowInterceptor?.has?.(key)) {
    toolStore?.modalShowInterceptor.delete(key);
    return true;
  }

  return false;
};

export {
  modalTaskRegister,
  modalTaskDelete,
};
