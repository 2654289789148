export default {
  path: '/welfare',
  component: () => import('./index'),
  routes: [
    {
      path: '/welfare/record',
      component: () => import('./Record'),
    },
    {
      path: '/welfare/address',
      component: () => import('./Address'),
    },
  ],
};
