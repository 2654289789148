/*
 * @Author: shixiaoxia
 * @Date: 2022-10-27 16:30:40
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2023-09-05 20:02:24
 * @Description: fetch统一入口
 */
import Fetch, { TrackFetch, TianjiFetch, UCFetch, VisualFetch, YbShuadanFetch, ShuntFetch, UploadFetch, GWUploadFetch } from 'ybcommon/ybfetch';

export { TrackFetch, TianjiFetch, UCFetch, VisualFetch, YbShuadanFetch, ShuntFetch, UploadFetch, GWUploadFetch };

export default Fetch;
