// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".adBg_q0vuawTy {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  background: transparent;\n  position: relative;\n  z-index: 1;\n}\n.contentImg_m13cm4iV {\n  width: 100%;\n  height: auto;\n  object-fit: unset;\n}\n.adImgWrap_2VcrtaLO {\n  position: relative;\n}\n.adImgWrap_2VcrtaLO .adsubscribeWrap_I18MujEx {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 3;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adBg": "adBg_q0vuawTy",
	"contentImg": "contentImg_m13cm4iV",
	"adImgWrap": "adImgWrap_2VcrtaLO",
	"adsubscribeWrap": "adsubscribeWrap_I18MujEx"
};
export default ___CSS_LOADER_EXPORT___;
