/*
 * @Author: shixiaoxia
 * @Date: 2022-11-04 18:10:29
 * @LastEditors: 朱晓真
 * @LastEditTime: 2023-04-13 21:16:34
 * @Description: 输入框
 */
import { InputItem } from 'ybcomponent';

export default InputItem;
