/*
 * @Author: jiangwei
 * @Date: 2022-12-23 15:46:18
 * @LastEditors: pyj
 * @LastEditTime: 2023-07-19 17:34:01
 * @Description: 添加银行卡弹窗内容
 */
import React, { Component } from 'react';
import { InputItem } from 'antd-mobile-v2';
import ybModal from 'fe-yb-modal';
import { CommonUtil as YBCommonUtil } from 'fe-yb-tools';
import { Toast } from '@yb/fe-component';
import { TianjiFetch } from 'ybcommon/ybfetch';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import classNames from 'classnames';
// import InputItem from '../InputItem';
import styles from './index.less';

export default class BankList extends Component {
  state = {
    cardNum: '', // 银行卡号
  };

  // 添加银行卡-提交卡号
  _onSubmitCard = () => {
    trackEvent('h5_all_tjbankcardpage_clicktj');
    const { modalId, tradeNo, successCB, bankCards } = this.props;

    if (bankCards?.length >= 10) {
      Toast.fail('最大限制暂定为10张银行卡');
      return;
    }

    const { cardNum } = this.state;
    const { isEmpty, isValid } = YBCommonUtil._checkValue(cardNum, 'bankCard');
    if (isEmpty || !isValid) {
      Toast.info('请输入正确的银行卡号');
      return;
    }
    const _tmp = cardNum.replace(/\s*/g, '');
    TianjiFetch.post('api/cashier/standard/binQuery', { tradeNo, cardNo: _tmp }).then(() => {
      ybModal.close(modalId);
      if (successCB) successCB(_tmp);
    }).catch((err) => {
      Toast.fail(err);
    });
  };

  render() {
    const { modalId } = this.props;

    return (
      <div className={styles.addCardModal}>
        <div className={styles.modalTitle}>请添加银行卡</div>
        <div className={styles.subTitle}>
          建议使用常用银行储蓄卡，查看
          <span
            onClick={() => {
              ybModal.close(modalId);
              window.location.href = `${window.location.origin}/cashier/banklist`;
            }}
          >支持银行
          </span>
        </div>
        <InputItem
          className={`${styles.modalInput} txr-asterisk`}
          clear
          type="bankCard"
          maxLength={23}
          placeholder="请输入本人银行卡号"
          onFocus={() => YBCommonUtil._textScrollToView()}
          onChange={(val) => { this.setState({ cardNum: val }); }}
        >
          银行卡号
        </InputItem>
        <div className={classNames(styles.submit, 'tx-sync-pointevent-none')} onClick={this._onSubmitCard}>提交卡号</div>
      </div>
    );
  }
}
