/**
 * canvas倒计时
 */
import React, { Component } from 'react';

class CanvasCountDown extends Component {
  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) onRef(this);
    this.devicePixelRatio = window.devicePixelRatio;
  }

  // 背景
  _renderRect = (ctx, x, y, w, h, r, bgColor) => {
    x *= this.devicePixelRatio;
    y *= this.devicePixelRatio;
    w *= this.devicePixelRatio;
    h *= this.devicePixelRatio;
    r *= this.devicePixelRatio;
    ctx.save();
    if (w < 2 * r) { r = w / 2; }
    if (h < 2 * r) { r = h / 2; }
    ctx.strokeStyle = bgColor;
    ctx.beginPath();
    ctx.fillStyle = bgColor;
    ctx.lineWidth = 0.5 * this.devicePixelRatio;
    ctx.moveTo(x + r, y);
    // 画圆弧
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.stroke();
    ctx.fill();
    ctx.closePath();
  }

  // 倒计时数字
  _renderDgtal = (ctx, minutes, color, x) => {
    x *= this.devicePixelRatio;
    ctx.moveTo(x, x);
    ctx.fillStyle = color; // 填充颜色,默认是黑色
    ctx.fillText(minutes, x, 55.5 * this.devicePixelRatio);
  }

  // 倒计时冒号
  _renderCircle = (ctx, color, x, y) => {
    x *= this.devicePixelRatio;
    y *= this.devicePixelRatio;
    ctx.beginPath();
    ctx.fillStyle = color; // 填充颜色,默认是黑色
    ctx.arc(x, y, 1 * this.devicePixelRatio, 0, 360, false);
    ctx.fill(); // 画实心圆
    ctx.closePath();
  }

  // 更新画板
  update = (minutes, second, msecond, color, bgColor) => {
    const cdDom = document.getElementById('countdown');
    const width = 100;
    const height = 100;
    cdDom.style.width = `${width}px`;
    cdDom.style.height = `${height}px`;
    cdDom.width = width * this.devicePixelRatio;
    cdDom.height = width * this.devicePixelRatio;
    const ctx = cdDom.getContext('2d');
    cdDom.width = cdDom.width;
    const fontsise = 12 * this.devicePixelRatio;
    ctx.font = `${fontsise}px Arial`; // 字体和字号
    this._renderRect(ctx, 10, 42, 20, 18, 2, bgColor);
    this._renderDgtal(ctx, minutes, color, 13);
    this._renderCircle(ctx, bgColor, 35, 50);

    this._renderCircle(ctx, bgColor, 35, 55);

    this._renderRect(ctx, 40, 42, 20, 18, 2, bgColor);
    this._renderDgtal(ctx, second, color, 43);

    this._renderCircle(ctx, bgColor, 64, 50);
    this._renderCircle(ctx, bgColor, 64, 55);

    this._renderRect(ctx, 70, 42, 20, 18, 2, bgColor);
    this._renderDgtal(ctx, msecond, color, 73);
  }

  render() {
    return (
      <canvas id="countdown" width="100%" height="100%" />
    );
  }
}

export default CanvasCountDown;
