// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notify_nPpVLemo {\n  height: 0px;\n  overflow: visible;\n}\n.notify_nPpVLemo .am-modal-transparent .am-modal-content {\n  border-radius: 0px !important;\n  background-color: transparent;\n  padding-top: 0px;\n}\n.notify_nPpVLemo .am-modal-transparent .am-modal-body {\n  padding: 0px;\n  margin: 0px;\n}\n.notify_nPpVLemo .am-modal-transparent .am-modal-mask {\n  background-color: transparent;\n}\n.notify2_CIWv90Rb {\n  height: 0px;\n  overflow: visible;\n}\n.notify2_CIWv90Rb .am-modal-transparent .am-modal-content {\n  border-radius: 0px !important;\n  background-color: transparent;\n  padding-top: 0px;\n}\n.notify2_CIWv90Rb .am-modal-transparent .am-modal-body {\n  padding: 0px;\n  margin: 0px;\n}\n.notify2_CIWv90Rb .am-modal-transparent .am-modal-mask {\n  background-color: transparent;\n}\n.notify2_CIWv90Rb .am-modal-transparent .am-modal-content {\n  border-radius: 0px !important;\n  background-color: transparent;\n  padding-top: 0px;\n}\n.notifyText_6oZjYk1S {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  font-size: 0.42667rem;\n  padding: 0.26667rem 0.42667rem;\n  line-height: 0.58667rem;\n  font-weight: 400;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notify": "notify_nPpVLemo",
	"notify2": "notify2_CIWv90Rb",
	"notifyText": "notifyText_6oZjYk1S"
};
export default ___CSS_LOADER_EXPORT___;
