/**
 * 广告位
 * 我的页面、产品列表页面、赠险保单介绍页面、保单列表页、历史保单页、主动续费结果页
 */
import React, { Component } from 'react';
import classnames from 'classnames';
import { Carousel } from 'antd-mobile-v2';

import Fetch from 'fetch';
import { isWechat } from 'utils/device';
import { Toast as ToastUtil } from '@yb/fe-component';
import { getInsuranceId } from 'utils/common/common';
import { pxTOrem, getImgPath } from 'ybcommon/ybutils/common/common';
import { isCN } from 'ybcommon/ybutils/common/company';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { openHrefUrl } from 'store/UrlTools';
import stores from 'store';
import { WxSubscribeView } from 'component';
import styles from './index.less';

export default class Advertising extends Component {
  dnReplaceType = isCN() ? 2 : 1;

  state = {
    data: null,
    adLists: [],
  };

  componentDidMount() {
    const page = window.__pathname || '';
    const { strategy = 0, customizePage, dnReplaceType = this.dnReplaceType } = this.props;
    Fetch.post('/api/tg/queryAdds', {
      page: customizePage || page,
      source: isWechat() ? 0 : 1,
      strategy,
      dnReplaceType,
    })
      .then((data) => {
        this._updateData(data);
      })
      .catch((err) => {
        ToastUtil.fail(err);
      });
  }

  _updateData = (data) => {
    const { strategy = 0 } = this.props;
    if (+strategy === 1) {
      // 直连采用轮播
      this.setState(
        {
          adLists: data.result || [],
        },
        () => {
          const { result } = data || {};
          if (result.length > 0) {
            const item = result[0];
            item.hasBG = true;
            if (item.traceId) {
              trackEvent('smart_recommend_callback', { bhv_type: 'expose', trace_id: item.traceId, position: item.channel });
            } else {
              trackEvent(`page_ad_banner${item.adRemark}`, { ...item, position: item.channel });
            }
          }
        },
      );
    } else {
      this.setState(
        {
          data: data.result[0] || {},
        },
        () => {
          const { result } = data || {};
          if (result.length > 0) {
            const item = result[0];
            if (item.traceId) {
              trackEvent('smart_recommend_callback', { bhv_type: 'expose', trace_id: item.traceId, position: item.channel });
            } else {
              trackEvent(`page_ad_banner${item.adRemark}`, { ...item, position: item.channel });
            }
          }
        },
      );
    }
  };

  _onItemChange = (index) => {
    const { adLists } = this.state;
    if (Array.isArray(adLists) && adLists.length > index) {
      const item = adLists[index];
      if (!item.hasBG) {
        console.log(index);
        adLists[index].hasBG = true;
        if (item.traceId) {
          trackEvent('smart_recommend_callback', { bhv_type: 'expose', trace_id: item.traceId, position: item.channel });
        } else {
          trackEvent(`page_ad_banner${item.adRemark}`, { ...item, position: item.channel });
        }
      }
    }
  };

  _imgClick = (item, index) => {
    const { userIdencrypt = '', onClose } = this.props;
    if (onClose) onClose();
    const { isWeChatMiniApp } = stores.userStore;
    const path = window.__pathname || '';
    let bannerPage = '其他';
    if (path === '/my') {
      bannerPage = '我的页';
    } else if (path.indexOf('/order/list') > -1) {
      bannerPage = '保单列表页';
    } else if (path.indexOf('/publicity/detail') > -1) {
      bannerPage = '保单说明页';
    }
    bannerPage = `${+isWeChatMiniApp === 1 ? '小程序' : 'H5'}${bannerPage}`;
    trackEvent('banner_click', { banner_index: index + 1, banner_title: item.itemTitle, banner_page: bannerPage });

    if (item && item.itemLinkUrl) {
      let url = `${item.itemLinkUrl}`;
      if (userIdencrypt) {
        if (item.itemLinkUrl.indexOf('?') !== -1) {
          url = `${item.itemLinkUrl}&userIdencrypt=${userIdencrypt}`;
        } else {
          url = `${item.itemLinkUrl}?userIdencrypt=${userIdencrypt}`;
        }
      }
      openHrefUrl(url);
    }
  };

  _renderAdItem = (item, index) => {
    let { itemImageUrl } = item;
    itemImageUrl = itemImageUrl ? getImgPath(itemImageUrl) : '';
    const { subType, subWidth, subHeight } = this.props;
    if (subType && subWidth && subHeight) {
      return (
        <WxSubscribeView
          style={{
            width: subWidth,
            height: subHeight,
          }}
          onClick={() => this._imgClick(item, index)}
        >
          <img
            src={itemImageUrl}
            alt=""
            className={styles.contentImg}
            onLoad={() => {
              window.dispatchEvent(new Event('resize'));
            }}
            onClick={() => this._imgClick(item, index)}
          />
        </WxSubscribeView>
      );
    }
    return (
      <img
        key={itemImageUrl}
        src={itemImageUrl}
        alt=""
        className={styles.contentImg}
        onLoad={() => {
          window.dispatchEvent(new Event('resize'));
        }}
        onClick={() => this._imgClick(item, index)}
      />
    );
  };

  _renderBanner = () => {
    const page = window.__pathname || '';
    const { className } = this.props;
    let imgUrl = 'https://cdn.ybaobx.com/product/insurance/popup/0306/baiwan.jpg';
    let width = '10rem';
    if (page === '/order/list' || page === '/order/listH') {
      imgUrl = 'https://cdn.ybaobx.com/product/insurance/banner/baiwanyiliao.png';
      width = '9.62667rem';
    }
    return (
      <div className={classnames(className, styles.adBg)} style={{ width }}>
        <img
          src={imgUrl}
          alt=""
          className={styles.contentImg}
          onLoad={() => {
            window.dispatchEvent(new Event('resize'));
          }}
          onClick={() => {
            const url = `/cargo/detail?insuranceId=${getInsuranceId('tkbw19')}&position=bw_ybmp_yb_ad1`;
            openHrefUrl(url);
          }}
        />
      </div>
    );
  };

  render() {
    const { data, adLists } = this.state;
    let { imgSize = '' } = data || {};
    imgSize = imgSize.replace(/\s*/g, '');
    const size = imgSize === '' ? '100%' : imgSize.split('*')[0];
    const width = imgSize === '' ? size : `${pxTOrem(+size / 2)}rem`;
    const { className, strategy, type = 0 } = this.props;
    if (+strategy === 1 && adLists.length > 0 && +type === 0) {
      if (adLists.length === 1) {
        return this._renderAdItem(adLists[0], 0);
      }
      return (
        <Carousel
          className={classnames(className, styles.adBg)}
          style={{ width }}
          autoplay
          infinite
          autoplayInterval={5000}
          dotStyle={{ width: `${pxTOrem(4)}rem`, height: `${pxTOrem(4)}rem`, background: 'rgba(30, 196, 227, 0.2)', borderRadius: `${pxTOrem(4)}rem` }}
          dotActiveStyle={{ width: `${pxTOrem(8)}rem`, height: `${pxTOrem(4)}rem`, background: '#28B9CB', borderRadius: `${pxTOrem(4)}rem` }}
          afterChange={this._onItemChange}
        >
          {adLists.map((item, index) => this._renderAdItem(item, index))}
        </Carousel>
      );
    }

    // type=1 广告平铺展示 默认type=0
    if (type && +type === 1 && adLists.length > 0) {
      return (
        <div className={classnames(className, styles.adBg)} style={{ width }}>
          {adLists.map((item, index) => this._renderAdItem(item, index))}
        </div>
      );
    }

    return (
      <div className={classnames(className, styles.adBg)} style={{ width }}>
        {data && data.itemImageUrl ? this._renderAdItem(data, 0) : null}
      </div>
    );
  }
}
