export default {
  path: '/renewal',
  routes: [
    { // 主动（手动）续保
      path: '/renewal/detail',
      component: () => import('./Detail'),
    },
    { // 自动续保
      path: '/renewal/auto',
      component: () => import('./Auto'),
    },
  ],
};
