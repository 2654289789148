/*
 * @Author: shixiaoxia
 * @Date: 2022-11-04 18:10:29
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-05-15 15:19:34
 * @Description: 推广输入框
 */
import { forwardRef, useRef, useEffect } from 'react';
import { InputItem } from 'ybcomponent';
import IDCard from 'ybcomponent/IDCard';
import { encryptUserToLogin } from 'common/utils/encryptUtil';
import DataStore, { InputItemRefKey } from 'sys/product/store/dataStore';
import { type as UtilType } from 'fe-yb-tools';

type IProps = {
  inputType: number, // 输入框类型 1-input 2、3、4、5、6-IDCard
  isLinkType3?: boolean;// 是否为链路3，只有IDCard组件中才会用到
  isAllowOtherEvent: boolean; // 失焦之后是否允许其他事件(即不拦截其他事件)
  isPreventFocus: false; // 是否阻止聚焦
  onPreventFocus?: () => void; // 当阻止聚焦发生事触发
  inputItemRefKey?: InputItemRefKey; // 存储的itemRef的key
} & any;

function InputItemEnhance(props: IProps, ref) {
  const { inputType = 1, isLinkType3, isAllowOtherEvent, isPreventFocus, inputItemRefKey, onPreventFocus, onPreventFocusEmit, ...otherProps } = props;
  const curItemRef = useRef();

  const commonProps = {
    isPreventFocus,
    onPreventFocusEmit: onPreventFocusEmit || (() => {
      if (!encryptUserToLogin()) {
        if (onPreventFocus) onPreventFocus();
      }
    }),
  };

  useEffect(() => {
    // ref的store的存
    if (inputItemRefKey) DataStore.addInputItemRef(inputItemRefKey, curItemRef.current);
    return () => {
      // ref的store的删除
      if (inputItemRefKey) DataStore.delInputItemRefByKey(inputItemRefKey);
    };
  }, []);

  const refFun = (curRef) => {
    if (curRef) {
      curItemRef.current = curRef;
      if (ref) {
        // 父组件传递了ref进来，需要给父组件的ref赋值
        if (UtilType.isFunction(ref)) {
          // 如果父组件传递的ref是一个方法调用就行
          ref(curRef);
        } else {
          // 非方法那父组件使用的就是 useRef或者React.createRef
          ref.current = curRef;
        }
      }
    }
  };

  const content = (
    inputType === 1
      ? <InputItem ref={refFun} {...commonProps} {...otherProps} /> // 修复原有的InputItem组件中不接收非dom属性导致的报错问题
      : <IDCard ref={refFun} isLinkType3={isLinkType3} isAllowOtherEvent={isAllowOtherEvent} {...commonProps} {...otherProps} />
  );

  return content;
}
export default forwardRef(InputItemEnhance);
