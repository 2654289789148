export default {
  path: '/health',
  routes: [
    {
      path: '/health/detail',
      component: () => import('./Detail'),
    },
    {
      path: '/health/video',
      component: () => import('./Video'),
    },
  ],
};
