/* eslint-disable no-sparse-arrays */
// url解析
// 根据给定的url生成一个对象，对象内容包含网络请求的头部信息

export default function (url, useCom = true) {
  let finalUrl = url;
  const curHistory = window.__YB_MALL_HISTORY__;
  // 针对直接对url为window.location.href，但是与我们本地路由不符的情况，进行处理
  if (!__DEV__ && url === window.location.href
    && curHistory && window.location.pathname !== curHistory.location.pathname) {
    const { protocol, hostname, port } = window.location;
    const { pathname, search } = curHistory.location;
    finalUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}${pathname}${search}`;
  }
  const a = document.createElement('a');
  a.href = finalUrl;
  return {
    source: finalUrl,
    protocol: a.protocol.replace(':', ''),
    host: a.hostname,
    port: a.port,
    query: a.search,
    origin: a.origin,
    search: a.search,
    pathname: a.pathname,
    params: (() => {
      const ret = {};
      const seg = a.search.replace(/^\?/, '').split('&');
      for (let i = 0; i < seg.length; i++) {
        if (seg[i]) {
          const s = seg[i].replace(/=/, '&').split('&');
          if (s.length >= 2) {
            if (s[0] === 'channel' && !!ret[s[0]]) {
              // 已存在channel就不处理了
            } else {
              ret[s[0]] = useCom ? decodeURIComponent(s[1]) : decodeURI(s[1]);
            }
          }
        }
      }
      return ret;
    })(),
    // eslint-disable-next-line no-useless-escape
    file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
    hash: a.hash.replace('#', ''),
    // eslint-disable-next-line no-useless-escape
    path: a.pathname.replace(/^([^\/])/, '/$1'),
    // eslint-disable-next-line no-useless-escape
    relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
    segments: a.pathname.replace(/^\//, '').split('/'),
  };
}

/**
 * 删除url中的指定query参数
 * @param {*} url
 * @param {*} paramName
 * @returns
 */
export const delUrlQueryParam = (url, paramName) => {
  if (!url) return '';
  const delExp = new RegExp(`(${paramName}=[^&]*&)|(&${paramName}=[^&]*)|(${paramName}=[^&]*)`);
  return url.replace(delExp, '');
};
