/*
 * @Author: shixiaoxia
 * @Date: 2024-08-29 15:43:52
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-05 21:34:12
 * @Description: 请填写简介
 */
import { initEnvConfiguration, initWebLogger } from 'fe-web-common';
import logger from 'ybcommon/yblogger';

initEnvConfiguration(__ENV_CONFIG__, window.location.hostname);
initWebLogger(logger);
