/**
 * 顶部错误提示
 *  使用该组件
 *  需要使用ref调用show函数，参数为提示text
 * 可以更改属性duration（显示时间），默认两秒
 */
import React, { Component } from 'react';
import { Modal } from 'antd-mobile-v2';
import classnames from 'classnames';
import { type } from 'fe-yb-tools';
import { Toast as ToastUtil } from '@yb/fe-component';

import styles from './index.less';

export default class Notify extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    const { duration = 2500 } = this.props;
    this.duration = duration;
    const { background = '#FF3F3F', color = '#FFFFFF' } = props;
    this.state = {
      text: '', // 提示信息
      visible: false, // 是否可见
      background, // 错误条颜色
      color, // 文字颜色
    };
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  show = (text) => {
    const { isToast } = this.props;
    if (isToast) {
      // toast形式错误提示
      ToastUtil.info(text);
      return;
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const { onBeforeShow } = this.props;
    if (onBeforeShow) onBeforeShow(text);

    this.setState(
      {
        text,
        visible: true,
      },
      () => {
        setTimeout(() => {
          document.body.style.overflow = '';
        }, 300);
        this.timer = setTimeout(() => {
          this.setState({
            // text: '',
            visible: false,
          });
        }, this.duration);
      },
    );
  };

  render() {
    const { className, renderIcon } = this.props;
    const { visible, text, background, color } = this.state;
    return (
      <Modal transparent popup visible={visible} animationType="slide-down" wrapClassName={styles.notify} maskStyle={{ backgroundColor: 'rgba(0,0,0,0)', height: 0 }}>
        <div className={classnames(styles.notifyText, className)} style={{ background, color }}>
          {type.isFunction(renderIcon) ? renderIcon() : null}
          {text}
        </div>
      </Modal>
    );
  }
}
