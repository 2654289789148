/*
 * @Author: shixiaoxia
 * @Date: 2022-12-13 17:56:03
 * @LastEditors: cuilinchao
 * @LastEditTime: 2024-01-15 15:57:36
 * @Description: 请求工具
 */

export const getEnv = () => {
  const reg = /.+(?=exchange)/;
  const host = window.location.host;
  const r1 = host.match(reg);
  let finalenv = 'dev'; // 默认dev
  if (__PUB__) {
    finalenv = 'online';
  } else if (host.indexOf('-pre') > -1) { // 如果有pre，表示预发布环境
    finalenv = 'pre';
  } else if (r1) {
    finalenv = r1[0];
  }
  return finalenv;
};

/**
   * 根据当前访问域名获取后缀
   * @returns hostsuffix 一级域名
   */
export const getHostSuffix = () => {
  const hostarr = window.location.host.split('.');
  const hostarrlen = hostarr.length;
  let hostsuffix = '';
  if (hostarrlen >= 2 && !__DEV__) {
    hostsuffix = `${hostarr[hostarrlen - 2]}.${hostarr[hostarrlen - 1]}`;
  }
  return hostsuffix;
};
