/**
 * 保险品牌
 * 在首页、我的页面使用
 */
import React, { Component } from 'react';
import classnames from 'classnames';
import { isShouxin, isCN } from 'ybcommon/ybutils/common/company';

import styles from './index.less';

export default class Brand extends Component {
  render() {
    const { className, type = 2 } = this.props;
    const _c = classnames(className, styles.c);
    let logo = require('./logoCN.png');
    let logoStyle = styles.logoCN;
    if (isCN()) {
      logo = require('./logoCN.png');
      logoStyle = styles.logoCN;
    } else if (isShouxin()) {
      logo = require('./logo_sx.png');
      logoStyle = styles.sxlogo;
    } else {
      logo = require('./logo2.png');
      logoStyle = styles.logo2;
    }
    return (
      <div {...this.props} className={_c}>
        {/* {+type === 1 && <img src={require('./logo.png')} alt="" className={styles.logo" />} */}
        {+type === 2 && <img src={logo} alt="" className={logoStyle} onClick={() => false} />}
      </div>
    );
  }
}
