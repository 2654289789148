/*
 * @Author: liuziyuan
 * @Date: 2022-07-19 09:37:59
 * @LastEditors: wangchen wangchen@yuanbaobaoxian.com
 * @LastEditTime: 2024-01-19 15:50:22
 * @Description: 引流页面相关路由
 */
import CXRoute from './changxian/route';
import tgAppraisalNewRoute from './tgAppraisalNew/route';
import cxCalculateRoute from './calculate/route';

export default [
  CXRoute, // 三个长险产品
  tgAppraisalNewRoute, // 直播1v1
  cxCalculateRoute,
  { // 企微分流中间页
    path: '/qw/middle',
    component: () => import('./qw'),
  },
];

export { cxCalculateRoute };
