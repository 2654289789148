// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorMsg_Q7VULk4e {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n.errorMsg_Q7VULk4e .title_8Ur21Sdb {\n  font-size: 0.8rem;\n  letter-spacing: 1px;\n  line-height: 1.8;\n  font-weight: bold;\n}\n.container_X5jD75DY div:last-child {\n  display: none;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMsg": "errorMsg_Q7VULk4e",
	"title": "title_8Ur21Sdb",
	"container": "container_X5jD75DY"
};
export default ___CSS_LOADER_EXPORT___;
