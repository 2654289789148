export default {
  path: '/simulation',
  component: () => import('./index'),
  routes: [
    {
      path: '/simulation/claim',
      component: () => import('./claim'),
    },
    {
      path: '/simulation/qwcode',
      component: () => import('./QrCode'),
    },
    {
      path: '/simulation/complete',
      component: () => import('./Complete'),
    },
  ],
};
