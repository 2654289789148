import Complaints from './Complaints/route';

export default {
  path: '/qiwei',
  routes: [{
    path: '/qiwei/membersWelfare',
    component: () => import('./membersWelfare/index'),
  }, {
    path: '/qiwei/memberaEquity',
    component: () => import('./memberaEquity/index'),
  }, ...Complaints],
};
