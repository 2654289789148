export default [
  {
    path: '/',
    component: () => import('./home'),
    exact: true,
  },
  {
    path: '/rights',
    component: () => import('./rights'),
    exact: true,
  },
];
