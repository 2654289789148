import { AxiosResponse } from 'axios';

import { CommonHeader, DataResponse } from '../../base';
import { CommonApiParams } from '../../../types';
import { StartReq } from './StartReq';
import { StartResult } from '../types';
import { bffFetch } from '../bffFetch';

/**
 * 获取Start接口数据
 */
export async function getBffStartData(
  params: StartReq,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
):Promise<AxiosResponse<DataResponse<StartResult>, unknown>> {
  const dataResponse = await bffFetch(commonHeader).post('/api/mall/bff/start', { ...commonApiParams, ...params });
  return dataResponse;
}
