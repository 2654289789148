/*
 * @Author: liulongjie liulongjie@yuanbaobaoxian.com
 * @Date: 2023-07-12 13:43:25
 * @LastEditors: xiaotao xiaotao@yuanbaobaoxian.com
 * @LastEditTime: 2024-05-29 19:08:56
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/component/WxShare/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 微信分享组件
 * 注意：
 * 1. 后续新页面需要单独注册分享信息时，在页面的route中添加registerOwnShare:{yuanbao:true,shouxin:true}防止重复注册
 * 2. 后续非单独注册分享信息的新页面，若不用展示home悬浮按钮，在页面的route中添加
 *    shareNotShowHome:true作为标识
 * 3. 需要隐藏分享按钮的页面，在页面的route中添加registerOwnShare:{hideShre:true}
 */
import React, { Component } from 'react';
import { doShare as wxApiDoShare } from 'ybcommon/wxApi';
import { isShouxin, getTitle } from 'ybcommon/ybutils/common/company';
import { isWechat, isWeMini } from 'utils/device';
import { addUrlParams } from 'ybcommon/ybutils/common/common';
import stores from 'store';

// 现有单独注册分享信息的页面
const ownRegisterPaths = ['/cargo/detail', '/collect', 'springdraw', '/extra/new', '/extra/service', '/gift/rights', '/gift/suppliersqy', '/health/detail', '/order/list', '/question/ask', '/question/detail', '/report/month', '/rights', '/rights/detail', '/risktest/result', '/service/introduce', '/test', '/textDetail', '/triger/detail'];
// 现有不展示home悬浮按钮的页面
const notShowHomePaths = ['/', '/cargo/index', '/my'];
// 默认分享信息
const defaultShareTitle = getTitle();
const defaultSubTitle = '专注于为您提供保险咨询服务和解决方案';
const defaultShareLink = window.location.href;
const defaultShareImg = 'https://cdn.ybaobx.com/h5/share/newlogo1.png';
const defaultMiniShareImg = 'https://cdn.ybaobx.com/minip/common/share/mcs.png';

export default class WxShare extends Component {
  static defaultProps = {
    wechatShare: true, // 微信内是否需要分享
    shareTitle: defaultShareTitle, // 分享标题
    shareSubTitle: defaultSubTitle, // 分享子标题
    shareLink: defaultShareLink, // 分享链接
    shareImg: defaultShareImg, // 分享图片
    needShowHome: true, // 是否展示回到首页浮窗
    miniShare: false, // 小程序是否需要分享
    miniShareTitle: '', // 小程序分享标题，不传则使用shareTitle
    miniShareLink: '', // 小程序分享链接，不传则使用shareLink
    miniShareImg: defaultMiniShareImg, // 小程序分享图片，不传则使用shareImg
    path: '', // 当前页面路由（仅router传，单独注册分享信息的组件不传，用于处理router中单独注册分享信息和不展示home悬浮按钮的逻辑）
  };

  componentDidMount() {
    this._registerShareInfo();
  }

  componentDidUpdate(preProps) {
    const { path, shareTitle, miniShareTitle } = preProps;
    const { path: curPath, shareTitle: curShareTitle, miniShareTitle: curMiniShareTitle } = this.props;
    if ((curPath && curPath !== path) || curShareTitle !== shareTitle || miniShareTitle !== curMiniShareTitle) {
      this._registerShareInfo();
    }
  }

  _registerShareInfo = () => {
    const { userStore } = stores;
    const { userId } = userStore;
    const { path, wechatShare, shareTitle, shareSubTitle, shareLink, shareImg, needShowHome, miniShare, miniShareTitle, miniShareLink, miniShareImg, shouxinOwnShare = false, isHome, force, hideShare } = this.props;

    // 单独注册分享信息的页面，不需要再次注册 || 首信域名走默认分享（不走单独注册）首信shouxinOwnShare为true 单独注册
    if ((path && ownRegisterPaths.indexOf(path) > -1 && !isShouxin()) || (!path && isShouxin() && !shouxinOwnShare)) return;

    if (miniShare && isWeMini() && window.wx && window.wx.miniProgram) {
      // 微信小程序
      const shareUrl = miniShareLink || shareLink || defaultShareLink;
      window.wx.miniProgram.postMessage({
        data: {
          miniShare: 1,
          webviewUrl: addUrlParams(shareUrl, { share_source_usr: userId }),
          miniappShareTitle: miniShareTitle || shareTitle || defaultShareTitle,
          shareImage: miniShareImg || shareImg || defaultMiniShareImg,
          isHome,
        },
      });
    } else if (wechatShare && !__DEV__ && isWechat()) {
      const showHome = notShowHomePaths.indexOf(path) > -1 ? false : needShowHome;
      const shareUrl = shareLink || defaultShareLink;
      // 微信内
      wxApiDoShare(
        window.location.href,
        shareTitle || defaultShareTitle,
        shareSubTitle || defaultSubTitle,
        shareImg || defaultShareImg,
        addUrlParams(shareUrl, { share_source_usr: userId }),
        hideShare,
        showHome,
        force
      );
    }
  };

  render() {
    return null;
  }
}
