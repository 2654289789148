import React, { Component } from 'react';

import { CommonUtil } from 'fe-yb-tools';
import { getHost } from 'ybcommon/ybutils/common/company';
import { isIOS } from 'common/utils/device';
import YBModal from '../YBModal';
import { TianjiFetch } from '../../../../../ybcommon/ybfetch';
import ProtocolLoading from '../ProtocalModal/ProtocolLoading';

import styles from './index.less';

/**
 * 协议优化展示协议具体内容
 */
export default class ProtocolShowModal extends Component {
  state = {
    content: '', // 当前协议内容
    configType: 1, // 保险协议类型 1：富文本, 2：pdf
    protocolPdfUrl: '', // 当前保险协议pdf地址
    isLoading: false, // 是否正在请求协议loading
    loadingText: '', // loading相关提示文本
    managerVisible: true, // ProtocolModalManager控制弹窗是否展示
  };

  componentDidMount() {
    this._doInit();
  }

  componentDidUpdate(prevProps) {
    const { protocolId } = this.props;

    if (protocolId && protocolId !== prevProps.protocolId) {
      this._doInit();
    }
  }

  // 初始化弹窗协议内容
  _doInit = () => {
    const { protocolId, protocolVersion } = this.props;

    if (!protocolId) return;

    this._loadData(protocolId, protocolVersion);
  };

  // 根据协议id获取协议内容
  _loadData = (protocolId, protocolVersion) => {
    this.setState({
      content: '', // 打开协议时先置空
      isLoading: true,
      loadingText: '加载中，请稍候',
      protocolPdfUrl: '', // 打开协议时先置空
    }, () => {
      // ToastUtil.loading('加载中', 0);
      (
        protocolId.indexOf(',') > -1
          // 当协议id为protocalId1,protocalId2,protocalId3这种由多个id组合而成时，即为多个富文本合并的情况，调用另外的接口进行查询
          ? TianjiFetch.post('api/mall/cq/contract/queryByProtocolIds', { protocolIds: protocolId, version: protocolVersion ?? null })
          : TianjiFetch.post('api/mall/bff/order/queryContract', { protocolId, version: protocolVersion ?? null })
      )
        .then((res) => {
          this.setState({
            content: CommonUtil.rtfTranslatePxtoRem(res.content || ''),
            configType: res.configType,
            protocolPdfUrl: res.protocolPdfUrl || '',
            isLoading: false,
            loadingText: '',
          });
        })
        .catch((/* err */) => {
          this.setState({ loadingText: '内容获取失败，请重新加载', isLoading: false });
          // ToastUtil.fail(err, 3, null, false);
        });
    });
  };

  _getPdfUrl = (protocolPdfUrl) => {
    const { cnhost } = getHost();
    return `https://${cnhost}/pdfpvw.html?url=${encodeURIComponent(protocolPdfUrl)}&scalable=no&tipType=1`;
  };

  _onClose = () => {
    const { onClose, fromManager } = this.props;

    if (!this.dom) {
      this.dom = document.getElementById('htmlC');
      if (this.dom) this.dom.scrollTop = 0;
    } else {
      this.dom.scrollTop = 0;
    }

    if (typeof onClose === 'function') onClose();
    if (fromManager) this.setState({ managerVisible: false });
  };

  // 渲染loading内容
  _renderLoadingContent = () => {
    const { isLoading, loadingText } = this.state;

    if (!loadingText) return null;

    return <ProtocolLoading reLoad={this._doInit} isLoading={isLoading} loadingText={loadingText} />;
  };

  _renderIframe = (protocalTitle, protocalModalUrlPath) => (
    <div className={isIOS() ? styles.protocalModalIOS : styles.protocalModal}>
      {isIOS() ? (
        <iframe title={protocalTitle} src={protocalModalUrlPath} frameBorder="0" scrolling="yes" height="100%" width="100%" className={styles.frameIOS} />
      ) : (
        <iframe
          title={protocalTitle}
          src={protocalModalUrlPath}
          frameBorder="0"
          scrolling="auto"
          width="100%"
          height="100%"
          onError={(e) => {
            console.log(e);
          }}
        />
      )}
    </div>
  );

  _renderHtml = () => {
    const { content, protocolPdfUrl, configType, isLoading } = this.state;
    const { protocalTitle } = this.props;
    if (!protocalTitle) return <div />;
    const pdfUrl = this._getPdfUrl(protocolPdfUrl);

    return (
      <div id="htmlC" className={styles.htmlC}>
        {+configType === 2 && !isLoading && protocolPdfUrl ? this._renderIframe(protocalTitle, pdfUrl) : <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />}
      </div>
    );
  };

  render() {
    const { visible, protocalTitle, onAfterClose, fromManager } = this.props;
    const { managerVisible } = this.state;
    return (
      <YBModal
        afterClose={onAfterClose}
        modalStyle={{ zIndex: 1005 }}
        popup
        transparent
        visible={fromManager ? managerVisible : visible}
        onMaskClick={this._onClose}
        animationType="slide-up"
        wrapClassName={styles.protocalModalWrap}
      >
        <div className={styles.contentWrap}>
          <div className={styles.header}>{protocalTitle}</div>
          {this._renderLoadingContent()}
          {this._renderHtml()}
          <span className={styles.close} onClick={this._onClose}>
            <img src={require('./img/close.png')} alt="关闭" />
          </span>
        </div>
      </YBModal>
    );
  }
}
