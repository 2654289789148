// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c_SS2wKohl {\n  color: #d0cfcf;\n  display: flex;\n  width: 100%;\n  align-items: center;\n  flex-direction: column;\n  margin: 1.01333rem 0 0.96rem 0;\n}\n.c_SS2wKohl .logo_xiFdoPf8 {\n  width: 4.72rem;\n  height: 1.09333rem;\n}\n.c_SS2wKohl .logo2_MdkDLbsi {\n  width: 2.8rem;\n  height: auto;\n}\n.c_SS2wKohl .logoCN_mrQ1C5p4 {\n  width: 2.53333rem;\n  height: auto;\n}\n.c_SS2wKohl .sxlogo_8gJ2UzRl {\n  margin: -0.32rem auto 1.33333rem;\n  width: 4.05333rem;\n  height: auto;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"c": "c_SS2wKohl",
	"logo": "logo_xiFdoPf8",
	"logo2": "logo2_MdkDLbsi",
	"logoCN": "logoCN_mrQ1C5p4",
	"sxlogo": "sxlogo_8gJ2UzRl"
};
export default ___CSS_LOADER_EXPORT___;
