/**
 * 拨打电话组件
 */
import React, { Component } from 'react';
import stores from 'store';

export default class CallPhone extends Component {
  render() {
    const { phoneNum = '02160664161', children, onBeforeCall, url } = this.props;
    const href = url || `tel:${phoneNum}`;
    return (
      <a
        onClick={() => {
          if (onBeforeCall) onBeforeCall();
          if ((+stores.userStore.isWeChatMiniApp === 2 || +stores.userStore.isWeChatMiniApp === 4)
            && href.indexOf('tel:') > -1) {
            return;
          }
          window.location.href = href;
        }}
      >
        {children}
      </a>
    );
  }
}
