/**
 * 手机震动工具
 */
// 输入框是否在抖动

let isInputVibration = false;

// 输入框抖动定时器
let inputVibrationTimer = null;

// 手机是否在震动
let isPhoneVibration = false;

// 是否是弹窗引起的震动 弹窗引起的震动由弹窗自己关闭
let isModalVibration = false;

// 手机震动定时器
let phoneVibrationTimer = null;

// 停止震动
const doStopVibrate = () => {
  navigator.vibrate(0);
};

// 震动
const doStartVibrate = (vibrateTime) => {
  const duration = 100;
  const times = vibrateTime / duration;
  const arr = [];
  for (let i = 0; i < times; i++) {
    arr.push(duration);
    arr.push(0);
  }
  navigator.vibrate(arr);
};
/**
 * 手机开始震动
 */
export const startVibration = (time = 0, isModal = false): void => {
  if (typeof time === 'number') {
    doStartVibrate(time * 1000);
    isModalVibration = isModal;
    isPhoneVibration = true;
    clearTimeout(phoneVibrationTimer);
    phoneVibrationTimer = setTimeout(() => {
      isModalVibration = false;
      isPhoneVibration = false;
    }, time * 1000);
  }
};

/**
 * 手机停止震动
 */
export const stopVibration = (isModal = false): void => {
  if (isModalVibration) {
    if (isModal) {
      clearTimeout(phoneVibrationTimer);
      isModalVibration = false;
      isPhoneVibration = false;
      doStopVibrate();
    }
  } else if (isPhoneVibration && !isModal) {
    clearTimeout(phoneVibrationTimer);
    isPhoneVibration = false;
    doStopVibrate();
  }
};

export const addVibrationClass = (element): void => {
  if (element?.style) {
    isInputVibration = true;
    element.style.animation = 'inputVibration 1s 2 linear';
    inputVibrationTimer = setTimeout(() => {
      element.style.animation = '';
      isInputVibration = false;
    }, 2000);
  }
};

/**
 * 元素移除震动样式
 */
export const removeVibrationClass = (element): void => {
  if (element && isInputVibration) {
    element.style.animation = '';
    isInputVibration = false;
    clearTimeout(inputVibrationTimer);
  }
};
