/*
 * @Author: shixiaoxia
 * @Date: 2024-05-18 10:22:10
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-11-06 20:02:55
 * @Description: 赠险链路服务
 */
import { AxiosResponse } from 'axios';
import { OrderPersonCheckResult } from '../../../../../fetch/bff/types';
import { CommonHeader, DataResponse } from '../../../../../fetch/base';
import { ifHasGiftOrder, queryOrderHmaAcceptstatisticPersonCheck } from '../../../../../fetch/bff';
import { mallQueryDetail } from '../../../../../fetch/mallGateway';
import { getShuntAbtestAll } from '../../../../../fetch/shunt';
import { GiftDetailPfreeConfig2Result, GiftPfreeConfig2AbtestTemplates, PfreeGiftDetailParam } from '../types';
import { CommonParams } from '../../../../../types';

export const giftDetailPfreeConfig2Service = async (
  giftParam: PfreeGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailPfreeConfig2Result | null> => {
  const {
    queryOrderParams,
    queryDetailParams,
    ifHasOrderParams,
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = giftParam;
  const { isWifi, productAbb, ybPosition, previewCode, lastVisitTime } = abtestParams || {};
  const abTestCommonParams = {
    productAbb,
    giftProductAbb: productAbb,
  };
  const jiYanLoginAndGiftReloginAndGiftFormParams = {
    ...abTestCommonParams,
    // 重新登录需要参数
    lastVisitTime,
    // 极验需要参数
    isWifi,
  };
  const requests = [];
  // shunt请求，分流
  /** 请求的所有AB测编码列表，请求时赋值，用于给分流结果异常的AB测兜底 */
  const loginAbTestParams = [
    { configCode: 'giftLinkAbtest', extraLimit: { ...abTestCommonParams, previewCode: previewCode ? +previewCode : null } },
    { configCode: 'giftFormAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    { configCode: 'giftCertNoScaleAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    { configCode: 'giftAudioAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftFormTipsAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftFormHeaderImgAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftLoginAndLandingLongImageAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage1Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage2Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftDetailBackAbtest', extraLimit: abTestCommonParams },
    { configCode: 'idCardAbtest', extraLimit: abTestCommonParams },
    { configCode: 'greetingVariantAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProConfirmAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftHandAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProtocolAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftLandingProtocalCheckboxAbtest', extraLimit: abTestCommonParams },
    { configCode: 'formPermanentResidentIDCardAbtest', extraLimit: abTestCommonParams },
    { configCode: 'linkConfidentialTextAbtest', extraLimit: abTestCommonParams },
  ];
  const abDataArr = loginAbTestParams.map((item) => item.configCode);
  requests.push(getShuntAbtestAll<GiftPfreeConfig2AbtestTemplates>(ybPosition, loginAbTestParams, commonAbTestParams, commonApiParams, commonHeader));
  // 请求落地页详情
  requests.push(mallQueryDetail(queryDetailParams, commonApiParams, commonHeader));
  // 登陆态或者有加密userid，获取是否领取+家庭成员信息和领取状态（不进行合并是为了避免中间有请求失败导致接口异常）
  const safeGetOrderData = async () => {
    try {
      const res = await ifHasGiftOrder(ifHasOrderParams, commonApiParams, commonHeader);
      return res;
    } catch (err) {
      return err;
    }
  };
  const safeGetRelationMemeberData = async ():Promise<AxiosResponse<DataResponse<OrderPersonCheckResult>>> => {
    try {
      return await queryOrderHmaAcceptstatisticPersonCheck(queryOrderParams, commonApiParams, commonHeader);
    } catch (err) {
      return err;
    }
  };
  requests.push(safeGetOrderData());
  requests.push(safeGetRelationMemeberData());
  const [giftAbTestDataResponse, productDetailResponse, ifHasOrderResponse, homeMemberResponse] = await Promise.all(requests);
  const giftAbTestData = giftAbTestDataResponse?.data;
  // ab测默认值赋值
  if (giftAbTestData && giftAbTestData.data) {
    for (const key of abDataArr) {
      const keyData = giftAbTestData.data[key];
      if (!keyData) {
        giftAbTestData.data[key] = { version: 1 };
      } else if (!keyData?.version) {
        keyData.version = 1;
      }
    }
    if (giftAbTestData.data.giftAudioAbtest) {
      giftAbTestData.data.giftAudioAbtest.version = 7; // 默认兜底版本7  产品要求
    }
    /** idCardAbtest 下线 3、6 https://yuanbaoshuke.feishu.cn/docx/HXCnd4PWkoCDvOxfzEtcDcemn7c */
    if ([3, 6].includes(giftAbTestData.data?.idCardAbtest?.version)) giftAbTestData.data.idCardAbtest.version = 2;
    if (giftAbTestData.data?.giftFormAbtest && [2, 3].includes(giftAbTestData.data.giftFormAbtest?.version)) {
      giftAbTestData.data.giftFormAbtest.version = 1;
    }
  }
  return {
    abtestData: giftAbTestData,
    productData: productDetailResponse?.data,
    orderData: ifHasOrderResponse?.data,
    homeMemberData: homeMemberResponse?.data,
  };
};
