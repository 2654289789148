// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageReloadContainer_cXFQMD2H {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: #FFFFFF;\n}\n.PageReloadContainer_cXFQMD2H .errorImg_HfxJQ444 {\n  width: 4.42667rem;\n  height: 2.58667rem;\n  margin-bottom: 0.53333rem;\n}\n.PageReloadContainer_cXFQMD2H .desc_\\+lkLXzAN {\n  width: 3.36rem;\n  height: 0.37333rem;\n  font-family: PingFangSC-Regular;\n  font-weight: 400;\n  font-size: 0.37333rem;\n  color: #333333;\n  letter-spacing: 0;\n  text-align: center;\n  line-height: 0.37333rem;\n}\n.PageReloadContainer_cXFQMD2H .reload_RiLMp08v {\n  width: 4rem;\n  height: 1.06667rem;\n  background-image: linear-gradient(92deg, #00A6FA 0%, #0F5BF9 80%);\n  border-radius: 0.53333rem;\n  line-height: 1.06667rem;\n  margin-top: 0.4rem;\n  text-align: center;\n  font-family: PingFangSC-Medium;\n  font-size: 0.45333rem;\n  color: #FFFFFF;\n  letter-spacing: 0.02px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageReloadContainer": "PageReloadContainer_cXFQMD2H",
	"errorImg": "errorImg_HfxJQ444",
	"desc": "desc_+lkLXzAN",
	"reload": "reload_RiLMp08v"
};
export default ___CSS_LOADER_EXPORT___;
