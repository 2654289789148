import React, { Component } from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ActivityIndicator } from 'antd-mobile-v2';
import classnames from 'classnames';
import { PayLoading } from 'ybcomponent';
import { Login, HoverButton, CommonModal } from 'component';
import Router from 'router';
import { UCFetch } from 'fetch';
import { isRequestError } from 'fe-web-common';
import ProtocalOptimizeModal from 'src/component/Modals/ProtocalOptimizeModal';
import routes from 'sys/route';
import { openHrefUrl } from 'store/UrlTools';
import BBFStore from 'store/BBFStore';
import { Toast } from '@yb/fe-component';
import { CommonUtil } from 'fe-yb-tools';
import { UserUtil } from 'utils';
import { goToKF } from 'utils/common/common';
import { transferSearchObj2Str, getLocationSearch, pxTOrem, judgeIsShuntPreview } from 'ybcommon/ybutils/common/common';
import { isShouxin, isBaiGeHost, isKc, isCN, getBusinessOrigin } from 'ybcommon/ybutils/common/company';
import { trackEvent, getBuryProductName, getProductName } from 'ybcommon/ybutils/statistic';
import { doWxAndZhiFuBaoAuth } from 'ybcommon/ybutils/wxUtil';
import url from 'utils/url';
import { isWechat, isAndroid, isMiuiBrowser, isAlipay, isAlipayClient, isWeMini } from 'common/utils/device';
import { getTgReferer, setMediaUuid } from 'ybcommon/ybmodel/tgUtil';
import { getSessionID, isNodeBff } from 'ybcommon/ybutils/common/sessionIdUtil';
import { getGiftMiddleData } from '../services/giftMiddleService';
import 'antd-mobile/es/global';
// import { showJiyanLoginModal } from 'src/store/jiYanLoginAbtestModel/components/JiyanLoginComponent';
import { initEventBus } from '../utils/eventBusCenter';
import styles from './app.less';

window.originHeight = 0;

const RECORDPATHS = [
  // 可回溯页面路径
  '/cargo/login',
  '/cargo/detail',
  '/cargo/combine',
  '/cargo/couple',
  '/cargo/cxDetail',
  '/order/unpaid',
  '/order/cxunpaid',
  '/cargo/protectdetail',
  '/renewal/detail',
  '/cargo/infodetail',
  '/cargo/calcFee',
  '/imagecollect',
  '/gift/detail',
  '/pay/cash',
  '/resultNew/detail',
  '/resultNew/cxdetail',
  '/cargo/nonStandard',
  '/resultNew/batch',
  // 橡树保路由
  '/sxcx/detail',
  '/sxcx/calculate',
  '/sxcx/info',
  '/sxcx/imagecollect',
  '/sxcx/result',
  '/resultNew/renewal', // 续保升级页
  '/resultNew/petReplenish', // 宠物险补充信息页
  '/familygetgift', // 为家人领赠险
];

const kfImg = require('../kf.png');

window.ybHasResize = false; // 判断是否接受到了安卓设备resize事件

if (window?.history?.scrollRestoration) {
  window.history.scrollRestoration = 'manual';
}

@inject('userStore', 'toolStore')
@observer
class App extends Component {
  state = {
    hasInit: false,
    kfOpacity: false, // 滑动时客服按钮是否半隐藏显示
    loginVisible: false, // 是否显示登陆弹窗
  };

  params = url(window.location.href).params;

  static getDerivedStateFromProps(nextProps) {
    const { toolStore } = nextProps;
    // 打开状态需要同步（注：非点击关闭按钮需要同步 loginVisible 为 false 状态，否则会错误的显示登陆框）
    return {
      loginVisible: toolStore.needShowLogin,
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentWillUnmount() {
    if (isAndroid()) {
      window.removeEventListener('resize', this._androidResize, false);
    }
  }

  initData = async () => {
    // showJiyanLoginModal({
    //   handleLogin: () => {
    //     console.log('登录了');
    //   },
    //   maskPhoneNum: '180****0000',
    // });
    // TODO: 测试代码，待删除
    console.log('测试下推送构建');
    if (!__DEV__) {
      // 非本地联调时，根据协议去决定是否重定向到https链接
      const protocal = window.location.protocol;
      let jumpUrl = '';
      if (protocal === 'http:') {
        jumpUrl = window.location.href.replace('http:', 'https:');
      }
      if (isBaiGeHost()) {
        // 如果是白鸽域名下（只有dev和正式），除了以下列举的页面，都重定向到元保域名首页
        if (!['/cargo/detail', '/resultNew/detail', '/cargo/calcFee', '/cargo/infodetail', '/cargo/prodetail'].includes(window.location.pathname)) {
          if (window.location.host.indexOf('dev') > -1) {
            jumpUrl = 'https://devexchange.yuanbaobaoxian.cn';
          } else {
            jumpUrl = 'https://exchange.yuanbaobaoxian.cn';
          }
        }
      }
      if (jumpUrl) {
        openHrefUrl(jumpUrl, true);
        return;
      }
    }
    const { toolStore, userStore } = this.props;
    if (!isKc()) toolStore.showYBLoading = true;
    const { encryData, mediaUuid, channel, retcode } = this.params;
    setMediaUuid(mediaUuid);
    this.initLengJing();
    this._handleEvent();
    if (channel) userStore.channel = channel;
    // 是否需要静默授权
    const isNeedWxAuth = !(channel?.indexOf('wxmp') > -1)
      && isWechat() && !isWeMini()
      && (isCN() || isShouxin()) && window.location.pathname !== '/pay/middle';
    if (encryData) { // url参数中有encryData，同步用户信息
      const callback = isNeedWxAuth ? (newUrl) => {
        // 从微信静默授权成功跳转过来
        trackEvent('h5_login_silentauth', { loginCheck: 0, loginType: 1 });
        window.history.replaceState(window.history.state, null, newUrl);
        this._doInit();
      } : null;
      this._syncCrossInfo(encryData, callback);
    } else if (retcode) { // 授权失败 toast提示
      const searchObj = getLocationSearch(window.location.search);
      delete searchObj.retcode; // 从url中删除retcode
      const searchStr = transferSearchObj2Str(searchObj);
      const newUrl = `${window.location.pathname}${searchStr}`;
      if (isNeedWxAuth) {
        // 从静默授权失败跳转过来 不刷新链接 防止进入死循环
        trackEvent('h5_login_silentauth', { loginCheck: 0, loginType: 0 });
        window.history.replaceState(window.history.state, null, newUrl);
        this._doInit();
        return;
      }
      Toast.fail('个人信息授权失败，请稍后重试~');
      window.location.replace(newUrl);
    } else if (isNeedWxAuth) {
      // 排除推广渠道 首信、经纪 微信内 静默授权
      this._doWxAuth();
    } else {
      console.log('***执行了doinit***');
      this._doInit();
    }
  };

  // 处理监听事件
  _handleEvent = () => {
    const { toolStore } = this.props;
    window.addEventListener('popstate', () => {
      // 监听到返回事件
      const { needShowLogin } = toolStore;
      if (needShowLogin) {
        toolStore.hiddenLoginModal();
        // 返回时同步隐藏状态
        this.setState({ loginVisible: false });
      }
    });
    if (isAndroid()) {
      window.addEventListener('resize', this._androidResize, false);
    }
    document.addEventListener('scroll', this._onPageScroll); // 监听 scroll 事件
    // url中有在channel后拼接特殊长度字符串，证明是预览的链接，监听一下分流平台通过 postMessage 发送的消息，清除用户的登录信息
    if (judgeIsShuntPreview()) {
      window.addEventListener('message', (e) => { // 监听 message 事件
        if (e.data && e.data === 'clearPreviewAbtestIframeLocalStorage') {
          window.localStorage.clear();
        }
      }, { once: false });
    }
  };

  _doInit = () => {
    let type = null;
    if (isAlipayClient()) {
      // 不处理
    } else if (isAlipay()) {
      type = 2;
    } else if (isWechat()) {
      type = 1;
    }
    const { channel = '', tuiguangId = '', userIdencrypt = '', ybPosition = '' } = this.params;
    const { userStore } = this.props;

    const originalUrl = tuiguangId ? getTgReferer() : '';
    const initParams = { type, originalUrl };
    if (window.location.pathname === '/cargo/middle') {
      userStore.middleTsp = new Date().getTime();
    }
    if (window.location.pathname === '/gift/middle') {
      const { userId, userToken, uuid, isWeChatMiniApp, unionId, appId } = userStore;
      getGiftMiddleData({ userId, uuid, userToken, isWeChatMiniApp, unionId, appId, channel, originalUrl, userIdencrypt, ybPosition })
        .then((res) => {
          const { startData } = res;
          // 处理 start接口返回
          if (!isRequestError(startData)) {
            const startDataCopy = JSON.parse(JSON.stringify(startData));
            const { initInfo = {}, userInfo } = startDataCopy?.data || {};
            const coreData = Object.assign(initInfo, { user: { ...userInfo } });
            userStore.updateCoreData(coreData, channel);
            // Android 如果键盘抬起的时候刷新页面，刚进入页面的时候，页面高度是键盘抬起的高度，所以将获取高度的逻辑之后到此处
            window.originHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.setState({ hasInit: true }, () => {
              initEventBus();
            });
          } else if (startData.code === 10002) {
            /* 推广链接重复访问，重定向404 */
            /** start接口 code 返回10002，重定向到404页面 */
            window.location.replace(`${window.location.origin}/error/404`);
          } else {
            Toast.fail(startData);
          }
        }).catch((err) => {
          console.log(err);
          Toast.fail(err);
        });
    } else {
      BBFStore.getInitData(initParams, this.params)
        .then((coreData) => {
          userStore.updateCoreData(coreData, channel);
          // Android 如果键盘抬起的时候刷新页面，刚进入页面的时候，页面高度是键盘抬起的高度，所以将获取高度的逻辑之后到此处
          window.originHeight = document.documentElement.clientHeight || document.body.clientHeight;
          this.setState({ hasInit: true }, () => {
            initEventBus();
          });
        })
        .catch((err) => {
          console.log(err);
          Toast.fail(err);
        });
    }
  };

  // 延迟还原状态
  setDelayRestore = ({ time, keyName }) => {
    const { [keyName]: value } = this.state;
    this.setState({
      [keyName]: !value,
    });
    const timer = setTimeout(() => {
      const { [keyName]: NewValue } = this.state;
      this.setState({
        [keyName]: !NewValue,
      });
    }, time);
    return timer;
  };

  _onPageScroll = () => {
    // 监听滑动开始以及滑动结束
    const { toolStore } = this.props;
    const { kfOpacity } = this.state;
    if (toolStore.showKF && !kfOpacity) {
      // 阻止不必要更新
      this.setDelayRestore({ time: 100, keyName: 'kfOpacity' }); // 客服透明
    }
  };

  _androidResize = () => {
    const { userStore } = this.props;
    window.ybHasResize = true;

    const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
    if (resizeHeight < window.originHeight && window.originHeight - resizeHeight > 100) {
      console.log('Android 键盘弹起啦！', resizeHeight, window.originHeight);
      userStore.keyboardShowAndroid = true;
      if (isMiuiBrowser()) {
        document.body.style.marginTop = '-40px';
      }
      CommonUtil._textScrollToView();
    } else {
      console.log('Android 键盘收起啦！', resizeHeight, window.originHeight);
      userStore.keyboardShowAndroid = false;
      if (resizeHeight - window.originHeight > 100) {
        window.originHeight = resizeHeight;
        console.log('页面原始高度记录异常重置为', window.originHeight);
      } else if (!window.__isAutoKeyBoard && (isMiuiBrowser()
        || /qqbrowser/i.test(navigator.userAgent.toLowerCase()))
      ) {
        /**
         * 安卓qq浏览器
         * 小米原生浏览器 有 XimaoMi/MiuiBrowser
         */
        document.activeElement?.blur?.();
      }
      // 修复小米浏览器下，输入框依旧被输入法遮挡问题
      if (isMiuiBrowser()) {
        document.body.style.marginTop = '0px';
      }
    }
  };

  initLengJing = () => {
    const { userStore } = this.props;
    const ybTrack = window.yb;
    if (ybTrack) {
      ybTrack.init({
        token: __PUB__ ? '8d2a4b20-5bcb-11eb-970f-4fe34d4adb68' : 'bcd8c520-497b-11eb-898b-5f315acb23ae',
        autoTrack: true,
        userData: {
          user_id: userStore.userId,
          request_id: userStore.uuid,
          isLogin: userStore.hasLogin ? 'login' : 'NotLogin',
          // anonymousID: this.anonymousID,
        },
        production: __PUB__,
        visualization: !__PUB__,
        // stopRecord: userStore.stopRecorder,
        stopRecord: userStore.stopRecorder,
        version: __VERSION__,
        recordPaths: RECORDPATHS,
        needBd: true,
        event_platform: getBuryProductName(),
        product_name: getProductName(),
        sessionId: getSessionID(),
        isNodeBff: isNodeBff(),
      });
    }
  };

  // 检查token是否有效
  _doWxAuth = () => {
    doWxAndZhiFuBaoAuth(
      isShouxin() ? 'wx_gzh_sx' : 'wx_gzh_jingji',
      2,
      getBusinessOrigin(),
      'SOURCE_APP_WEB_APP_SILENCE',
      (isLogin) => {
        // isLogin true-登录态 false-微信授权接口报错/openid开关关闭
        trackEvent('h5_login_silentauth', { loginCheck: isLogin ? 1 : 0, loginType: isLogin ? 1 : 0 });
        this._doInit();
      },
      () => {
        trackEvent('h5_login_openIdReq');
      }
    );
  };

  // 跨域同步用户信息
  _syncCrossInfo = (encryData, callback) => {
    UCFetch.post('/api/cross/decrypt', { encryData })
      .then((res) => {
        if (res) {
          const { userStore } = this.props;
          const { token, userId } = res;
          userStore.userToken = token;
          userStore.userId = userId;
          UserUtil.setItem('userId', userId, true);
          UserUtil.setItem('token', token, true);
          const searchObj = getLocationSearch(window.location.search);
          delete searchObj.encryData; // 从url中删除encryData
          delete searchObj.retcode; // 从url中删除retcode
          const searchStr = transferSearchObj2Str(searchObj);
          const newUrl = `${window.location.pathname}${searchStr}`;
          if (callback) {
            callback(newUrl);
            return;
          }
          window.location.replace(newUrl);
        }
      })
      .catch((err) => {
        this._doInit();
        console.log(err);
      });
  };

  _onLoginSuccess = (phone, isRegiste) => {
    const { toolStore, userStore } = this.props;
    userStore.phone = phone;
    toolStore.loginSuccess(isRegiste);
  };

  _onLoginFail = (err) => {
    const { toolStore } = this.props;
    if (toolStore.failCallback) {
      toolStore.failCallback(err);
    }
  };

  _onCloseAction = () => {
    const { toolStore } = this.props;
    // 延迟到动画结束后关闭弹窗
    setTimeout(() => {
      this.setState({
        loginVisible: false,
      });
    }, 400);
    toolStore.hiddenLoginModal();
  };

  _onKFClick = () => {
    const { userStore } = this.props;
    const { isWeChatMiniApp } = userStore;
    const path = window.__pathname || '';
    let page = '其他';
    let channelid = 14;
    let robotid = 3;
    if (isShouxin()) {
      channelid = 51;
      robotid = 1;
    }
    if (path.indexOf('/order/detail') !== -1) {
      page = '保单详情页';
      channelid = isShouxin() ? 84 : 83;
      if (isWeMini()) channelid = +isWeChatMiniApp === 5 ? 53 : 85;
    } else if (path.indexOf('/order/list') !== -1) {
      channelid = isShouxin() ? 51 : 82;
      page = '保单列表页';
    }
    page = `${+isWeChatMiniApp > 0 ? '小程序' : 'H5'}${page}`;
    trackEvent('online_consultation_click', { online_consultation_page: page });
    goToKF(channelid, robotid, null, true);
  };

  _renderLogin = () => {
    const { toolStore } = this.props;
    const { loginVisible } = this.state;
    const { needShowLogin, loginModalVersion } = toolStore;
    const { version, autoLogin, isJiyan, acceptProtocolVersion, canUseFaceID } = loginModalVersion || {};

    if (loginVisible || needShowLogin) {
      // version 1-橘色旧版 2-橘色放大版 3-吸顶版本
      let cName = '';
      if (version === 3) {
        // 吸底版本软键盘弹出有上滑动画，去掉弹框渐入动画
        cName = needShowLogin ? '' : styles.hideLoginBox;
      } else {
        cName = needShowLogin ? styles.showLoginBox : styles.hideLoginBox;
      }
      return (
        <div>
          <div className={needShowLogin ? styles.mask : styles.hidemask} />
          <Login version={version} acceptProtocolVersion={acceptProtocolVersion} isJiyan={isJiyan} autoLogin={autoLogin} canUseFaceID={canUseFaceID} onLoginSuccess={this._onLoginSuccess} onCloseAction={this._onCloseAction} onLoginFail={this._onLoginFail} cName={cName} />
        </div>
      );
    }
    return null;
  };

  _renderLoading = () => {
    const { toolStore } = this.props;
    const { isLoading, showYBLoading } = toolStore;
    const payLoadingPage = ['/pay/transition', '/resultNew/detail', '/resultNew/cxdetail', '/order/unpaid']; // 使用支付查询loading的页面

    if (showYBLoading) {
      if (payLoadingPage.includes(window.__pathname) && !this.params.isgift) { // 展示支付查询loading（赠险结果页不展示）
        return <PayLoading />;
      }
      return <div className={styles.loadingContainer} />;
    }
    return <ActivityIndicator toast text="加载中..." animating={isLoading} />;
  };

  _renderHoverBtn = () => {
    const pathName = url(window.location.href).pathname;
    const { channel = '' } = this.params;
    // const logo = 'http://dev1newexchange.hhtcex.com/product/insurance/upload/logo.png';
    let isShow = false;
    const _path = window.location.href.toLowerCase();
    if (_path.indexOf('needshow=1') > -1 || (navigator.userAgent.indexOf('AlipayClient') > -1 && window.my && channel.indexOf('rw') !== -1 && pathName.indexOf('result') === -1)) {
      isShow = true;
    }
    // 不显示时不渲染HoverButton
    if (!isShow) {
      return null;
    }
    return (
      <HoverButton
        isActive={false}
        visible={isShow}
        conClassName={styles.hoverBtn}
        onClick={() => {
          if (navigator.userAgent.indexOf('AlipayClient') > -1 && window.my && channel.indexOf('rw') !== -1) {
            window.my.postMessage({ oper: 'back' });
          } else {
            openHrefUrl('/', true);
          }
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#523B0F' }}>
          <span className={classnames('iconfont', 'iconshouye')} style={{ fontSize: `${pxTOrem(20)}rem` }} />
          <span style={{ fontSize: `${pxTOrem(12)}rem`, marginTop: `${pxTOrem(8)}rem`, fontWeight: 'bold' }}>首页</span>
        </div>
      </HoverButton>
    );
  };

  _renderKeFuBtn = () => {
    const { toolStore } = this.props;
    const { kfOpacity } = this.state;

    if (!toolStore.showKF) return null;

    return (
      <div className={classnames(styles.kf, kfOpacity ? styles.opciatyKf : null)}>
        <img
          src={kfImg}
          alt=""
          style={{
            width: '100%',
            height: 'auto',
          }}
          onClick={this._onKFClick}
        />
      </div>
    );
  };

  /**
* 如果是自定义身份证键盘抬起时，添加一个展位元素，让页面高度增加
* 为了解决身份证输入框距离底部过近被自定义键盘遮挡
* @returns
*/
  _renderIDCardKeyBoardShadow = () => {
    const { toolStore } = this.props;
    return toolStore.customKeyBoardIsShow ? <div className={styles.iDCardKeyBoardShadow} /> : null;
  };

  render() {
    const { history, toolStore } = this.props;
    const { hasInit } = this.state;

    return (
      <div id="main" style={{ height: '100%', background: '#ffffff' }}>
        {hasInit ? (
          <BrowserRouter history={history}>
            <Router routes={routes} />
          </BrowserRouter>
        ) : null}
        {this._renderLogin()}
        {this._renderLoading()}
        {this._renderHoverBtn()}
        {this._renderKeFuBtn()}
        <CommonModal key="3" />
        {this._renderIDCardKeyBoardShadow()}
        <ProtocalOptimizeModal visible={toolStore.optimizeModalVisible} {...(toolStore.optimizeModalProps || {})} />
      </div>
    );
  }
}

export default App;
