/**
 * 新配置化弹窗：宽度固定333px，高度由背景图片撑开
 */
import React from 'react';
import stores from 'store';
import { TianjiFetch } from 'fetch';
import { Toast } from '@yb/fe-component';
import { setCanUseLocalBackListener, isCanUseLocalBackListener, autoAddHistory } from 'ybcommon/ybutils/common/backutil';
import { addUrlParams } from 'ybcommon/ybutils/common/common';
import { abTestHitReport, getShuntAbtestAll } from 'ybcommon/ybutils/common/shuntutil';
import { isWeMini } from 'utils/device';
import axios from 'axios';

const { CancelToken } = axios;

export default class OperationModal extends React.Component {
  static defaultProps = {
    isWxSubscribe: false, // 是否是消息订阅弹窗
    orderId: '', // orderId 非必需
    showTrust: false, // 是否展示增信相关内容
  };

  constructor(props) {
    super(props);
    this.hasShowBackDialog = false; // 是否已经展示过返回弹窗
    this.signInConfig = null; // 签到弹窗
    this.state = {
      backConfig: {}, // 返回弹窗配置
      close: 0, // 展示弹窗规则 x秒后延迟展示
      show: 0, // 关闭弹窗规则 x秒后自动关闭（仅关闭）
    };
  }

  // 判断小程序环境
  _judgeMini = () => isWeMini();

  componentDidMount() {
    this._getPopupConfig();
  }

  componentWillUnmount() {
    this._cancel();
    window.removeEventListener('popstate', this._backWindow);
    if (this.showTimer) clearTimeout(this.showTimer);
    if (this.closeTimer) clearTimeout(this.closeTimer);
  }

  // 中断页面请求
  _cancel = () => {
    if (this.source) this.source.cancel('');
  };

  _backWindow = () => {
    if (this.showTimer) clearTimeout(this.showTimer);
    if (this.closeTimer) clearTimeout(this.closeTimer);
    const { toolStore } = stores;
    const { backConfig = {} } = this.state;
    // 没有返回弹窗数据 return
    if (!backConfig) return;
    if (!isCanUseLocalBackListener()) {
      setCanUseLocalBackListener(true);
      return;
    }
    // 展示过返回弹窗 return
    if (this.hasShowBackDialog) return;
    if (toolStore.modalVisible) {
      toolStore.hideModal();
      return;
    }
    this.hasShowBackDialog = true;
    this._showModalRule(backConfig, true);
  };

  // 统一加position
  _dealParams = (jumpUrl, position) => {
    if (position && jumpUrl) {
      return addUrlParams(jumpUrl, { position });
    }
    return jumpUrl;
  };

  _getPopupConfig = () => {
    this.source = CancelToken.source();
    const channel = stores.userStore.channel;
    const { orderId, signTop, extraData, showTrust } = this.props;
    const extraLimit = {
      path: window.__pathname,
      channel,
      orderId,
      userId: stores.userStore.userId,
    };

    const params = [
      { configCode: 'activity', extraLimit },
      { configCode: 'back', extraLimit },
      { configCode: 'signIn', extraLimit },
    ];

    getShuntAbtestAll(params)
      .then((res) => {
        const { activity = {}, back = {}, signIn = {} } = res || {};
        this.signInConfig = signIn;

        const { cityInfo, cityInfoShow, recommendAge, recommendAgeText, avatarsShow, avatars } = activity;

        if (showTrust) {
          this.otherInfo = {
            cityInfo,
            cityInfoShow,
            recommendAge,
            recommendAgeText,
            avatarsShow,
            avatars,
          };
        }

        if (activity?.jumpInfo && extraData) {
          const { position } = extraData || {};
          const { jumpUrl } = activity.jumpInfo || {};
          if (position) activity.jumpInfo.jumpUrl = this._dealParams(jumpUrl, position);
        }

        if (back?.jumpInfo && extraData) {
          const { position } = extraData || {};
          const { jumpUrl } = back.jumpInfo || {};
          if (position) back.jumpInfo.jumpUrl = this._dealParams(jumpUrl, position);
        }

        if (signIn?.jumpInfo && extraData) {
          const { position } = extraData || {};
          const { jumpUrl } = signIn.jumpInfo || {};
          if (position) signIn.jumpInfo.jumpUrl = this._dealParams(jumpUrl, position);
        }

        this.setState(
          {
            backConfig: back,
          },
          () => {
            if (back && back.id) {
              autoAddHistory();
              window.addEventListener('popstate', this._backWindow, false);
            }
            if (activity && activity.id) {
              autoAddHistory();
              this._showModalRule(activity);
            }
            if (signIn && signIn.id) {
              autoAddHistory();
              this._showModalRule({ ...this.signInConfig, signTop });
            }
          },
        );
      })
      .catch((err) => {
        Toast.fail(err);
      });
  };

  /**
   * 展示弹窗逻辑
   * @param {*} modalConfig 弹窗配置信息
   * @param {*} needAutoBack 是否需要返回一层popstate
   */
  _showModalRule = (modalConfig, needAutoBack) => {
    const { rule = {} } = modalConfig || {};
    const { show, close } = rule || {};
    if (+show && +show > 0) {
      this.setState(
        {
          show: +show,
        },
        () => {
          this._showLoop(modalConfig, needAutoBack);
        },
      );
    } else {
      this._showModal(modalConfig, needAutoBack);
      this._pupupCallBack(modalConfig);
      const { showEvent } = this.props;
      if (showEvent) {
        showEvent();
      }
    }
    if (+close && +close > 0) {
      this.setState(
        {
          close: +close,
        },
        () => {
          this._closeLoop();
        },
      );
    }
  };

  /** 展示弹窗规则 x秒后延迟展示
   * @param {*} modalConfig 弹窗配置信息
   * @param {*} needAutoBack 是否需要返回一层popstate
   */
  _showLoop = (modalConfig, needAutoBack) => {
    if (this.showTimer) clearTimeout(this.showTimer);
    const { show } = this.state;
    if (+show === 0) {
      this._showModal(modalConfig, needAutoBack);
      this._pupupCallBack(modalConfig);
      const { showEvent } = this.props;
      if (showEvent) {
        showEvent();
      }
      return;
    }
    const newTime = show - 1;
    this.setState({
      show: newTime,
    });
    this.showTimer = setTimeout(() => {
      this._showLoop(modalConfig, needAutoBack);
    }, 1000);
  };

  // 关闭弹窗规则 x秒后自动关闭（仅关闭）
  _closeLoop = () => {
    if (this.closeTimer) clearTimeout(this.closeTimer);
    const { close, show } = this.state;
    if (close === 0) {
      this._onClose();
      return;
    }
    const newTime = close + show - 1;
    this.setState({
      close: newTime,
    });
    this.closeTimer = setTimeout(() => {
      this._closeLoop();
    }, 1000);
  };

  /**
   * 弹窗关闭
   * @param {*} flag 是否是点击关闭按钮
   */
  _onClose = (flag) => {
    if (this.showTimer) clearTimeout(this.showTimer);
    if (this.closeTimer) clearTimeout(this.closeTimer);
    const { closeEvent, clickEvent } = this.props;
    if (closeEvent && flag) {
      closeEvent();
    }
    // 触发点击埋点
    if (clickEvent && !flag) {
      clickEvent();
    }
    setCanUseLocalBackListener(false);
    stores.toolStore.hideModal();
  };

  // 展示弹窗回调
  _pupupCallBack = (modalConfig) => {
    const { id, pageId } = modalConfig || {};
    const params = {
      pageId,
      popupId: id,
    };
    TianjiFetch.loadingPost(
      {
        url: 'api/tianji/popup/popupShowRecord/add',
        params,
      },
      () => { },
      (err) => {
        Toast.fail(err);
      },
    );
  };

  _showModal = (modalConfig, pushPop = false) => {
    const { showEvent = '', closeEvent = '', showTrust, isGray = false } = this.props;
    const { displayEnvironment } = modalConfig || {};
    if ((this._judgeMini() && +displayEnvironment === 1) || (!this._judgeMini() && +displayEnvironment === 2)) return;
    if (modalConfig?.type === 'activity' && modalConfig?.requestId) abTestHitReport([{ configCode: 'activity', requestId: modalConfig?.requestId }]);
    stores.toolStore.showModalByType(102, { modalConfig, otherInfo: showTrust ? this.otherInfo : null, isWxSubscribe: false, isGray, showEvent, closeEvent, onClose: this._onClose }, pushPop);
  };

  render() {
    return null;
  }
}
