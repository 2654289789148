/*
 * @Author: wangchen wangchen@yuanbaobaoxian.com
 * @Date: 2023-10-07 15:24:29
 * @LastEditors: wangchen wangchen@yuanbaobaoxian.com
 * @LastEditTime: 2023-10-10 11:27:55
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/component/PageReloadView/index.js
 * @Description: 数据重新加载组件
 */
import React from 'react';

import styles from './index.less';

const PageReloadView = (props) => {
  const { onReload } = props;
  return (
    <div className={styles.PageReloadContainer}>
      {/**
      */}
      <img alt="" className={styles.errorImg} src={require('./img/LoadDataFailed.png')} />
      <div className={styles.desc}>
        加载失败，点击刷新
      </div>
      <div
        className={styles.reload}
        onClick={() => {
          if (onReload) {
            onReload();
          }
        }}
      >重新加载
      </div>
    </div>
  );
};

export default PageReloadView;
