/*
 * @Author: shixiaoxia
 * @Date: 2023-11-01 10:48:04
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-01-24 14:09:43
 * @Description: 请填写简介
 */
import userStore from './UserStore';
import toolStore from './ToolStore';

export default {
  userStore,
  toolStore,
};
