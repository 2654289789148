/*
 * @Author: shixiaoxia
 * @Date: 2024-09-02 10:35:27
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-03 11:42:30
 * @Description: 请填写简介
 */
import { AxiosResponse } from 'axios';

import { CommonHeader, DataResponse } from '../../base';
import { CommonApiParams } from '../../../types';
import { bffFetch } from '../bffFetch';
import { OrderAcceptstatisticResult } from '../types';
import { QueryOrderAcceptstatisticReq } from './QueryOrderAcceptstatisticReq';

/**
 * 获取订单家庭成员领取情况
 * @param params {QueryOrderAcceptstatisticReq} 请求参数
 * @param commonHeader {CommonApiParams} 统一header
 * @returns 订单家庭成员领取结果
 */
export async function queryOrderAcceptstatistic(
  params: QueryOrderAcceptstatisticReq,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
):Promise<AxiosResponse<DataResponse<OrderAcceptstatisticResult>, unknown>> {
  const result = await bffFetch(commonHeader).post('api/mall/bff/order/acceptstatistic/query', { ...commonApiParams, ...params });
  return result;
}
