/**
 * 费率因子表格
*/

import React, { Component } from 'react';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import List from './List';
import styles from './index.less';

const defalutTitle = [
  { head: '投保年龄' },
  { head: '有医保' },
  { head: '无医保' },
];

const getPremiumTableEventName = (feeName, page = 'LP') => `${feeName}_${page}_click_premiumtable`;

class PremiumTable extends Component {
  constructor(props) {
    super(props);
    const { premiumHead } = props;
    this.title = premiumHead || defalutTitle;
    this.state = {
      curTab: 1,
    };
  }

  onTabChange = (tabKey) => {
    const { curTab } = this.state;
    const { feeName, page = 'LP' } = this.props;
    trackEvent(getPremiumTableEventName(feeName, page));
    if (curTab !== tabKey) {
      this.setState({
        curTab: tabKey,
      });
    }
  };

  // 渲染费率表
  _renderList = () => {
    // howPremiumRateShow = 1，默认显示文字费率
    const { dataMonth, dataYear, premiumRateImg,
      insuranceId, category, howPremiumRateShow = 1 } = this.props;
    const { curTab } = this.state;
    if (+howPremiumRateShow === 2 && premiumRateImg) {
      return <img src={premiumRateImg} alt="费率图片" onClick={() => false} />;
    }

    if (+howPremiumRateShow === 1) {
      if (dataMonth && dataYear) {
        if (curTab === 1) {
          return (
            <List
              titleData={this.title}
              data={dataMonth}
              curTab={curTab}
              insuranceId={insuranceId}
              category={category}
            />
          );
        }
        return <List titleData={this.title} data={dataYear} />;
      }
      if (dataMonth || dataYear) {
        return <List titleData={this.title} data={dataYear || dataMonth} />;
      }
    }
    return <div />;
  };

  _renderFooter = () => {
    const { dataMonth, dataYear, howPremiumRateShow = 1 } = this.props;
    const { curTab } = this.state;
    if (dataMonth && dataYear && +howPremiumRateShow === 1) {
      return (
        <div className={styles.footer}>
          <div
            className={curTab === 1 ? styles.active : styles.disactive}
            onClick={() => { this.onTabChange(1); }}
          >按月交费
          </div>
          <div
            className={curTab === 2 ? styles.active : styles.disactive}
            onClick={() => { this.onTabChange(2); }}
          >全额交费
          </div>
        </div>
      );
    }
    return <div />;
  };

  render() {
    const { dataMonth, dataYear, howPremiumRateShow = 1 } = this.props;
    return (
      <div className={styles.container}>
        <div id="premiumWrap" className={dataMonth && dataYear && +howPremiumRateShow === 1 ? styles.list : styles.listNoFooter}>
          {this._renderList()}
        </div>
        {this._renderFooter()}
      </div>
    );
  }
}

export default PremiumTable;
