import React, { Component } from 'react';
import classNames from 'classnames';
import style from './index.less';

/**
 * currentTime 倒计时
 * tipsText 显示文字
 */
export default class CountdownTips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bg: 'default',
      currentTime: 15,
    };
  }

  componentDidMount() {
    const { currentTime = 15 } = this.props;
    this.setState({
      currentTime,
    }, () => { this._loop(); });
    window.addEventListener('scroll', this._changeBg);
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
    window.removeEventListener('scroll', this._changeBg);
  }

  _changeBg = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 0) {
      this.setState({
        bg: 'opacityBg',
      });
    } else {
      this.setState({
        bg: 'default',
      });
    }
  }

  _loop = () => {
    if (this.timer) clearTimeout(this.timer);
    let { currentTime } = this.state;
    const { countdownFinish } = this.props;
    if (+currentTime > 0) {
      this.timer = setTimeout(() => {
        currentTime--;
        this.setState({ currentTime });
        this._loop();
      }, 1000);
    } else if (+currentTime === 0) {
      if (countdownFinish) {
        countdownFinish();
      }
    }
  }

  render() {
    const { currentTime, bg } = this.state;
    const { tipsText } = this.props;
    return (
      <div className={classNames(style.tipsBg, bg === 'opacityBg' ? style.opacityBg : null)}>
        <img className={style.bgImg} src={bg === 'default' ? require('./img/default_bg.png') : require('./img/opacity_bg.png')} alt="" />
        <p className={style.text}>{tipsText(currentTime)}</p>
      </div>
    );
  }
}
