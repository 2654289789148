export default {
  path: '/youjia',
  routes: [
    {
      path: '/youjia/detail',
      component: () => import('./Detail'),
    },
    {
      path: '/youjia/succ',
      component: () => import('./Succ'),
    },
    {
      path: '/youjia/address',
      component: () => import('./Address'),
    },
    {
      path: '/youjia/middle',
      component: () => import('./mallMiddle'),
      registerOwnShare: {
        yuanbao: true,
      },
      title: '问诊购药',
    },
    {
      path: '/youjia/six/middle',
      component: () => import('./SixForOne/Middle'),
    },
    {
      path: '/youjia/six/products',
      component: () => import('./SixForOne/Products'),
    },
    {
      path: '/youjia/six/productdetail',
      component: () => import('./SixForOne/ProductDetail'),
    },
    {
      path: '/youjia/six/orderlist',
      component: () => import('./SixForOne/OrderList'),
    },
    {
      path: '/youjia/six/orderDetail',
      component: () => import('./SixForOne/OrderDetail'),
    },
    {
      path: '/youjia/six/productaddress',
      component: () => import('./SixForOne/AddressSix'),
    },
    {
      path: '/youjia/six/logistics',
      component: () => import('./SixForOne/Logistics'),
    },
  ],
};
