/*
 * @Author: shixiaoxia
 * @Date: 2022-11-18 11:50:48
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-08-27 15:41:12
 * @Description: 微信api处理
 */
import Fetch from 'ybcommon/ybfetch';
import { type as Type, device } from 'fe-yb-tools';

const { isIOS } = device;

const wxApiconfig = {
  _iosInitUrl: '',
  urlMap: {},
  data: null,
  noConfigData: false,
};

const init = () => {
  // ios下首次启动进入的页面
  wxApiconfig._iosInitUrl = window.location.href.split('#')[0];
};

const hasConfigData = () => !wxApiconfig.noConfigData;

const _getConfigData = (url, success, force = false) => {
  const cacheData = isIOS() ? wxApiconfig.data : wxApiconfig.urlMap[url];
  if (cacheData && success && !force) {
    success(cacheData);
  } else {
    Fetch.post('/api/marketing/getJsConfig', { url })
      .then((data) => {
        if (isIOS()) {
          wxApiconfig.data = data;
        } else {
          wxApiconfig.urlMap[url] = data;
        }
        if (success) success(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const _wxconfig = (data, title, desc, imgUrl, shareUrl, url, hideShare) => {
  if (!data) {
    wxApiconfig.noConfigData = true;
    return;
  }
  if (window.wx) {
    let jsApiList = ['updateAppMessageShareData', 'updateTimelineShareData'];
    if (hideShare) jsApiList = [...jsApiList, 'hideMenuItems', 'hideOptionMenu'];
    window.wx.config({
      debug: __DEV__,
      appId: data.appId,
      timestamp: data.timestamp,
      nonceStr: data.nonStr,
      signature: data.signature,
      jsApiList,
      openTagList: ['wx-open-launch-weapp', 'wx-open-subscribe'],
    });
    window.wx.ready(() => {
      if (hideShare) {
        // 隐藏分享按钮
        if (isIOS() && Type.isFunction(window.wx?.hideOptionMenu)) {
          // ios 隐藏功能菜单（ios中hideMenuItems失效）
          window.wx.hideOptionMenu();
        } else if (Type.isFunction(window.wx?.hideMenuItems)) {
          // 安卓 仅隐藏分享按钮
          window.wx.hideMenuItems({
            menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:QZone'],
            success: (hideMenuItemsRes) => {
              console.log('hideMenuItemsOK', hideMenuItemsRes);
            },
            fail: (err) => {
              console.log('hideMenuItemsFail', err);
            },
          });
        }
      } else {
        if (Type.isFunction(window.wx?.updateAppMessageShareData)) {
          window.wx.updateAppMessageShareData({
            title,
            desc,
            link: shareUrl,
            imgUrl,
            success: (appShareData) => {
              console.log('ShareAppOK', appShareData);
            },
            fail: (err) => {
              console.log('ShareAppFail', err);
            },
          });
        }
        if (Type.isFunction(window.wx?.updateTimelineShareData)) {
          // 分享到朋友圈
          window.wx.updateTimelineShareData({
            title,
            desc,
            link: shareUrl,
            imgUrl,
            success: (menuShareData) => {
              console.log('ShareTimelineOK', menuShareData);
            },
            fail: (err) => {
              console.log('ShareTimelineFail', err);
            },
          });
        }
      }
    });
    window.wx.error(() => {
      _getConfigData(url);
    });
  }
};

const doShare = (pageUrl, title, desc, imgUrl, link, hideShare, needShowHome = true, force = false) => {
  if (__DEV__) return;
  const isIos = isIOS();
  let url = isIos ? wxApiconfig._iosInitUrl : pageUrl.split('#')[0];
  if (force) {
    url = window.location.href.split('#')[0];
  }
  let shareUrl = link || pageUrl;
  if (needShowHome) {
    if (shareUrl.indexOf('?') > -1) {
      shareUrl += '&needshow=1';
    } else {
      shareUrl += '?needshow=1';
    }
  }

  _getConfigData(
    url,
    (data) => {
      _wxconfig(data, title, desc, imgUrl, shareUrl, url, hideShare);
    },
    force,
  );
};

export { init, hasConfigData, doShare };
