/*
 * @Author: 朱晓真
 * @Date: 2023-03-09 10:08:09
 * @LastEditors: liulongjie liulongjie@yuanbaobaoxian.com
 * @LastEditTime: 2023-12-26 21:25:50
 * @Description: 请填写简介
 */
import { makeAutoObservable } from 'mobx';
import { CommonUtil } from 'fe-yb-tools';
import { getAgeFromBirth } from 'utils/common/common';

/* 年龄性别文案匹配map */
const AgeTxtMap = {
  0: '0-10',
  1: '10-20',
  2: '20-30',
  3: '30-40',
  4: '40-50',
  5: '50-60',
  6: '60-70',
  7: '70-80',
  8: '80-90',
  9: '90-100',
  10: '100-110',
  11: '110-120',
};

/**
 * UIStore定位是用来处理页面中组件间需要共享以及进行渲染的state
 * 尽量不要直接修改成员变量的值，通过action方法进行修改，这样方便管理
 */
class UIStore {
  /** 表单步骤 0-初始化/登录页 1-次页1 2-次页2 */
  formStep: number;

  /** 小手图片地址 */
  handImg: string;

  /** 协议文字颜色 */
  protocalColor: string;

  /** 协议前文本颜色 */
  preProtocalColor: string;

  /** 出单动作 */
  createOrderAction: {
    /** 路径 */
    href: string;
    /** 开始时间戳 */
    startTime: number;
  };

  /** 投保保人关系 */
  relation = 1;

  /** 获取被保人信息 */
  getInsurantInfo: () => { certNo: string, sex: number };

  /**
   * 获取被保人年龄
   * @param {*} insurant 被保人 （传递了就按照传递的获取年龄，没有传递就获取state里面的）
   * @returns number -1 信息异常
   */
  _getAge = (insurant) => {
    if (!insurant) return -1;
    if (insurant.age || +insurant.age === 0) return insurant.age;
    const { certNo } = insurant;
    const { isValid, isEmpty } = CommonUtil._checkValue(certNo, 'certNo');
    const birthday = !isEmpty && isValid ? certNo.substr(6, 14) : insurant.birthday;
    let age = -1;
    if (birthday) {
      age = getAgeFromBirth(birthday, 2);
    }
    return age;
  };

  /** 被保人信息 */
  get insurantInfo() {
    const insurant = this.getInsurantInfo?.();
    if (!insurant) {
      return {};
    }
    const ageTxt = AgeTxtMap[parseInt(`${this._getAge(insurant) / 10}`, 10)];
    let isBoy = false;
    if (insurant.sex) {
      isBoy = +insurant.sex === 1;
    } else {
      isBoy = CommonUtil.isBoyByUserCard(insurant?.certNo || '');
    }
    return { insurant, ageTxt, isBoy };
  }

  constructor() {
    makeAutoObservable(this);
    this.resetFieldsOfIntoPage();
  }

  /** 进入页面需要重置的字段值 */
  resetFieldsOfIntoPage = () => {
    this.formStep = 0;
    this.handImg = '';
    this.createOrderAction = undefined;
  };

  /** 设置标单步骤 */
  setFormStep = (formStep: number) => {
    this.formStep = formStep;
  };

  /** 设置小手图片 */
  setHandImg = (handImg: string) => {
    if (typeof handImg !== 'string') return;
    this.handImg = handImg;
  };

  /** 设置协议文字颜色 */
  setProtocalColor = (protocalColor: string) => {
    if (typeof protocalColor !== 'string') return;
    this.protocalColor = protocalColor;
  };

  /** 设置协议前文字颜色 */
  setPreProtocalColor = (color: string) => {
    if (typeof color !== 'string') return;
    this.preProtocalColor = color;
  };

  /** 设置出单动作开始时间 */
  setCreateOrderActionStartTime = () => {
    this.createOrderAction = {
      href: window.location.href,
      startTime: Date.now(),
    };
  };

  /**
   * 设置被保人信息
   * @param info 被保人信息
   */
  setInsurantFn = (fn: () => {
    sex: number,
    certNo: string,
  }) => {
    if (fn) {
      this.getInsurantInfo = fn;
    }
  };
}
const uiStore = new UIStore();
export default uiStore;
