/*
 * @Author: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @Date: 2023-05-30 13:54:20
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2023-06-06 21:17:49
 * @Description: 云服产品
 */
export default [
  {
    path: '/yunfu',
    routes: [
      {
        path: '/yunfu/introduce',
        component: () => import('./introduce'),
        registerOwnShare: {
          shouxin: true,
          yuanbao: true,
        },
      },
      {
        path: '/yunfu/middle',
        component: () => import('./middle'),
      },
    ],
  },
];
