/*
 * @Author: surun
 * @Date: 2023-04-11 16:10:30
 * @LastEditors: surun
 * @LastEditTime: 2023-04-19 14:03:40
 * @Description: 兼容表头分组的费率表格
 */
import React, { useMemo } from 'react';

import styles from './index.less';

/**
 * 将一行的带有children的表头数据处理成多行的表头数组 并统计每个分组下的实际子元素个数
 * @param {*} trList 表头行数据 一开始只一行 需要将每个表头分组的children扁平处理
 * @returns 返回处理后实际的表头每行数据
 */
function flatTableHead(trList) {
  const lastTr = trList[trList.length - 1]; // 最后一行数据
  const nextTr = []; // 下一行数据
  let hasChild = false; // 记录最后一行数据是否还存在分组
  lastTr.forEach((item) => {
    item.childNum = 0; // 初始化每一列分组的子元素个数
    if (item.child && item.child.length) {
      hasChild = true; // 若存在分组 hasChild值为true
      item.child.forEach((chidItem) => {
        chidItem.parent = item; // 记录child上一层元素 方便之后更新所有上层元素的子元素个数
        nextTr.push(chidItem); // 将最后一行的child添加到下一行
        let parentNode = chidItem.parent;
        if (chidItem.child && chidItem.child.length) return; // 如果还存在child不更新父元素的子元素个数
        while (parentNode) { // 如果已经没有子元素 说明是最后一层 其上所有父元素的childNum加1
          parentNode.childNum++;
          parentNode = parentNode.parent;
        }
      });
    }
  });
  return hasChild ? flatTableHead([...trList, nextTr]) : trList; // 如果最后一行还存在分组的列 则继续处理trList 否则直接返回trList
}

/**
 * 兼容表头分组的费率表格
 * @param {*} premiumHead 表头数据
 * @param {*} premiumData 表格数据
 * @returns
 */
function PremiumTable({ premiumHead, premiumData }) {
  const headList = useMemo(() => flatTableHead([premiumHead]), [premiumHead]);
  return (
    <table className={styles.table}>
      <thead>
        {headList.map((row, index) => (
          <tr key={`${index + 1}`} className={styles.headTr}>
            {row.map((item, thIndex) => (
              <th
                rowSpan={item.childNum ? null : headList.length - index}
                colSpan={item.childNum === 0 ? null : item.childNum}
                className={styles.th}
                key={`${thIndex + 1}`}
              >
                {item.head}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {premiumData?.map?.((row, index) => (
          <tr key={`${index + 1}`} className={styles.bodyTr}>
            {row?.map?.((item, tdIndex) => <td key={`${tdIndex + 1}`} className={styles.td}>{item}</td>)}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default React.memo(PremiumTable);
