/*
 * @Author: shixiaoxia
 * @Date: 2024-08-29 13:44:10
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-06 21:11:08
 * @Description: 请填写简介
 */
import { DataResponse } from '../fetch/base';

export function isRequestError<T>(detailResponseData: DataResponse<T> | undefined) {
  const { code } = detailResponseData || {};
  if (code !== 200) {
    return true;
  }
  return false;
}
