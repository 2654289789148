/*
 * @Author: pyj
 * @Date: 2022-10-26 09:56:45
 * @LastEditors: cuilinchao
 * @LastEditTime: 2024-04-08 11:55:54
 * @Description: 可视化相关路由
 */

export default [
  {
    path: '/visual/normal',
    component: () => import('./Normal'),
  },
  {
    path: '/showside/detail',
    component: () => import('./CommonPage'),
  },
  {
    path: '/visual/minipreview',
    component: () => import('./MiniPreviewPage'),
  },
  {
    path: '/visual/secret',
    component: () => import('./TianjiPage'),
  },
  {
    path: '/visual/public',
    component: () => import('./Public'),
  },
];
