import React, { Component } from 'react';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import styles from './index.less';

/**
 * 气泡，目前只支持向下
 */
export default class Pophover extends Component {
  state = {
    top: 10,
    left: 10,
    triLeft: 0,
  };

  /**
   * 根据点击元素更改显示位置
   * ele：被点击元素
   * scrollEle：外层滚动元素
   * callback：回调
   */
  changePos = (ele, scrollEle, callback) => {
    const screenWidth = document.documentElement.clientWidth;
    const {
      gap = (pxTOrem(10) * screenWidth) / 100,
      popwidth = (pxTOrem(190) * screenWidth) / 100,
      lineheight = (pxTOrem(24) * screenWidth) / 100,
    } = this.props;
    let left;
    let triLeft;
    const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = ele;
    const { scrollTop } = scrollEle;
    if (offsetLeft + offsetWidth / 2 - popwidth / 2 < gap) {
      left = gap;
      if (offsetHeight > lineheight + 5) {
        triLeft = 0;
      } else {
        triLeft = offsetLeft + offsetWidth / 2 - popwidth / 2 - gap;
      }
    } else if (screenWidth - offsetLeft - popwidth < gap) {
      left = screenWidth - popwidth - gap;
      triLeft = offsetLeft + offsetWidth / 2 - (screenWidth - popwidth / 2 - gap);
    } else {
      left = offsetLeft + offsetWidth / 2 - popwidth / 2;
      triLeft = 0;
    }
    const top = offsetTop + offsetHeight + gap - scrollTop;
    if (callback) {
      callback();
    }
    this.setState({
      top,
      left,
      triLeft,
    });
  };

  render() {
    const { content, visible = false } = this.props;
    const { left = 0, top = 0, triLeft = 0 } = this.state;
    return (
      <div
        className={styles.pophoverCon}
        style={{ display: visible ? '' : 'none', left, top }}
      >
        <i className={styles.pophoverTri} style={{ marginLeft: triLeft }} />
        <div>{content}</div>
      </div>
    );
  }
}
