/*
 * @Author: shixiaoxia
 * @Date: 2024-08-29 13:44:10
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-08-29 13:44:52
 * @Description: 请填写简介
 */
export interface CommonLogger {
  /**
   * log parameters
   * @param mergingObject An object can optionally be supplied as the first parameter. Each enumerable key and value of the mergingObject is copied into the JSON log line.
   * @param message A message string can optionally be supplied as the first parameter, or as the second parameter after supplying a mergingObject.
   * @param interpolationValues
   */
  trace(message:string, meta?:any):void;
  debug(message:string, meta?:any):void;
  info(message:string, meta?:any):void;
  warn(message:string, meta?:any): void;
  error(message:string, meta?:any):void;
  fatal(message:string, meta?:any): void
}

let globalLogger:CommonLogger;

export const initWebLogger = (logger:CommonLogger):void => {
  globalLogger = logger;
};

export const getLogger = ():CommonLogger => globalLogger;
