import Page404 from './404';
import Page50x from './50x';

export default {
  path: '/error',
  routes: [{
    path: '/error/404',
    component: Page404,
  }, {
    path: '/error/50x',
    component: Page50x,
  }],
};
