/*
 * @Author: shixiaoxia
 * @Date: 2024-05-21 15:36:21
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-13 10:58:15
 * @Description: gift/middle数据获取
 */
import { getShuntAbtestAll } from '../../../../fetch/shunt';
import { CommonHeader } from '../../../../fetch/base';
import { giftDetailPfreeConfig1Service } from './config1';
import { giftDetailPfreeConfig2Service } from './config2';
import { GiftNewConfigtestTemplates } from '../types';
import { PfreeGiftDetailParam, PfreeGiftDetailResult } from './types';
import { isRequestError } from '../../../../utils/httperr';
import { CommonParams } from '../../../../types';

export const giftDetailPfreeService = async (
  giftParam: PfreeGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<PfreeGiftDetailResult> => {
  const {
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = giftParam;

  const { productAbb, ybPosition } = abtestParams;

  // 赠险新配置版ab测请求
  const giftNewConfigAbtestParams = [
    { configCode: 'giftNewConfigAbtest', extraLimit: { productAbb, giftProductAbb: productAbb } },
  ];
  const giftNewConfigAbtestDataResponse = await getShuntAbtestAll<GiftNewConfigtestTemplates>(ybPosition, giftNewConfigAbtestParams, commonAbTestParams, commonApiParams, commonHeader);

  // 如果 giftNewConfigAbtest 返回结果报错，需要直接返回,不需要继续请求
  if (isRequestError(giftNewConfigAbtestDataResponse.data)) {
    return {
      giftNewConfigAbtestData: giftNewConfigAbtestDataResponse?.data,
    };
  }

  const { giftNewConfigAbtest } = giftNewConfigAbtestDataResponse?.data?.data || {};
  const { version: giftNewConfigAbtestVersion } = giftNewConfigAbtest || {};
  // 如果是走新配置
  if (giftNewConfigAbtestVersion === 2) {
    // 获取新配置赠险链路数据
    const giftDataResult = await giftDetailPfreeConfig2Service(giftParam, commonHeader);
    return {
      giftNewConfigAbtestData: giftNewConfigAbtestDataResponse?.data,
      ...giftDataResult,
    };
  }
  // 获取老配置赠险链路数据
  const giftDataResult = await giftDetailPfreeConfig1Service(giftParam, commonHeader);
  return {
    giftNewConfigAbtestData: giftNewConfigAbtestDataResponse?.data,
    ...giftDataResult,
  };
};
