/*
 * @Author: surun
 * @Date: 2022-09-13 10:53:29
 * @LastEditors: surun
 * @LastEditTime: 2022-09-13 14:33:47
 * @Description: 合规相关页面
 */
export default [
  {
    path: '/compliance/prededuct',
    component: () => import('./Prededuct/index'),
  },
];
