/*
 * @Author: shixiaoxia
 * @Date: 2022-07-27 13:36:49
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-08-29 18:11:49
 * @Description: 图片上传走网关接口
 */
import { getEnv, getHostSuffix } from 'ybcommon/envUtil';
import Fetch from './base';

const hostsuffix = getHostSuffix();

let _gwUploadFetch;
if (__PUB__) {
  _gwUploadFetch = new Fetch({ baseURL: `//api.${hostsuffix}`, timeout: 100 * 1000 });
} else if (__TEST__) {
  _gwUploadFetch = new Fetch({ baseURL: `//api-${getEnv()}.${hostsuffix}`, timeout: 100 * 1000 });
} else {
  _gwUploadFetch = new Fetch({ baseURL: '/gateway', timeout: 100 * 1000 });
}
const GWUploadFetch = _gwUploadFetch.getInstance();
export default GWUploadFetch;
