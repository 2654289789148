/*
 * @Author: surun
 * @Date: 2022-07-21 18:09:07
 * @LastEditors: surun
 * @LastEditTime: 2022-07-21 19:13:05
 * @Description: 请填写简介
 */
export default [{
  path: '/qiwei/complaints',
  component: () => import('./index'),
  routes: [{
    path: '/qiwei/complaints/complete',
    component: () => import('./Complete/index'),
  }],
}];
