// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabBox_MRNY4zKt {\n  height: 100%;\n  overflow: hidden;\n}\n.tabBox_MRNY4zKt .tabBars_WXL21n4O {\n  border-bottom: 0.5px solid #EDEDED;\n  display: flex;\n  justify-content: center;\n}\n.tabBox_MRNY4zKt .tabBars_WXL21n4O .tabBar_xxgIeapM {\n  line-height: 0.56rem;\n  height: 0.86667rem;\n  padding-bottom: 0.24rem;\n  font-family: PingFangSC;\n  font-weight: 400;\n  font-size: 0.4rem;\n  color: #454545;\n  box-sizing: border-box;\n}\n.tabBox_MRNY4zKt .tabBars_WXL21n4O .tabBar_xxgIeapM:nth-child(1n+2) {\n  margin-left: 2.08rem;\n}\n.tabBox_MRNY4zKt .tabBars_WXL21n4O .activeTabBar_Wy28sdGJ {\n  font-weight: 600;\n  color: #FF6E11;\n  border-bottom: 2.5px solid #FF6E11;\n}\n.tabBox_MRNY4zKt .tabBody_fJhcdrCG {\n  padding-top: 0.21333rem;\n  height: calc(100% - 0.90667rem);\n}\n.tabBox_MRNY4zKt .tabBody_fJhcdrCG .tabBar_xxgIeapM {\n  display: none;\n  height: 100%;\n}\n.tabBox_MRNY4zKt .tabBody_fJhcdrCG .activeTab_yDWRCxbO {\n  display: block;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabBox": "tabBox_MRNY4zKt",
	"tabBars": "tabBars_WXL21n4O",
	"tabBar": "tabBar_xxgIeapM",
	"activeTabBar": "activeTabBar_Wy28sdGJ",
	"tabBody": "tabBody_fJhcdrCG",
	"activeTab": "activeTab_yDWRCxbO"
};
export default ___CSS_LOADER_EXPORT___;
