export default {
  path: '/wallet',
  routes: [{
    path: '/wallet/list',
    component: () => import('./list'),
  }, {
    path: '/wallet/recharge',
    component: () => import('./recharge'),
  }],
};
