/*
 * @Author: shixiaoxia
 * @Date: 2024-05-21 15:36:21
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-13 11:01:19
 * @Description: gift/middle数据获取
 */
import { getShuntAbtestAll } from '../../../../fetch/shunt';
import { CommonHeader } from '../../../../fetch/base';
import { MallQueryDetailReq } from '../../../../fetch/mallGateway';
import { giftDetailUnLoginConfig1Service } from './config1';
import { giftDetailUnLoginConfig2Service } from './config2';
import { GiftNewConfigtestTemplates } from '../types';
import { GiftDetailUnLoginServiceResult } from './types';
import { isRequestError } from '../../../../utils/httperr';
import { CommonParams } from '../../../../types';

interface AbtestParams {
  productAbb: string, // 产品简称
  isWifi: number; // 1 是: 2 否
  previewCode?: string; // 预览code
  ybPosition?: string; // position
  landingPageVersion?: number; // 落地页版本
  lastVisitTime: string; // 上次访问时间
}

// 赠险请求参数
export interface UnLoginGiftDetailParam {
  queryDetailParams: MallQueryDetailReq; // 请求产品详情
  abtestParams: AbtestParams;
}

// gift/middle页整体返回结果
export const giftDetailUnLoginService = async (
  giftParam: UnLoginGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailUnLoginServiceResult> => {
  const {
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = giftParam;

  const { productAbb, ybPosition } = abtestParams;

  // 赠险新配置版ab测请求
  const giftNewConfigAbtestParams = [
    { configCode: 'giftNewConfigAbtest', extraLimit: { productAbb, giftProductAbb: productAbb } },
  ];
  const giftNewConfigAbtestDataResponse = await getShuntAbtestAll<GiftNewConfigtestTemplates>(ybPosition, giftNewConfigAbtestParams, commonAbTestParams, commonApiParams, commonHeader);

  // 如果 giftNewConfigAbtest 返回结果报错，需要直接返回,不需要继续请求
  if (isRequestError(giftNewConfigAbtestDataResponse.data)) {
    return {
      giftNewConfigAbtestData: giftNewConfigAbtestDataResponse?.data,
    };
  }

  const { giftNewConfigAbtest } = giftNewConfigAbtestDataResponse?.data?.data || {};
  const { version: giftNewConfigAbtestVersion } = giftNewConfigAbtest || {};
  // 如果是走新配置
  if (giftNewConfigAbtestVersion === 2) {
    // 获取新配置赠险链路数据
    const giftDataResult = await giftDetailUnLoginConfig2Service(giftParam, commonHeader);
    return {
      giftNewConfigAbtestData: giftNewConfigAbtestDataResponse?.data,
      ...giftDataResult,
    };
  }
  // 获取老配置赠险链路数据
  const giftDataResult = await giftDetailUnLoginConfig1Service(giftParam, commonHeader);
  return {
    giftNewConfigAbtestData: giftNewConfigAbtestDataResponse?.data,
    ...(giftDataResult || {}),
  };
};
