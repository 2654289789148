import storage from './ybutils/storage';

// webp图片格式兼容性判断
export function webpSupport() {
  const webpIsSupported = storage.get('webpIsSupported');
  if (webpIsSupported) {
    window.__YB_MALL_WEBP_IS_SUPPORTED__ = webpIsSupported === '1';
  } else {
    new Promise((resolve) => {
      const image = new Image();
      image.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
      image.onload = () => resolve(image.width === 1);
      image.onerror = () => resolve(false);
    })
      .then((res) => {
        storage.set('webpIsSupported', res ? '1' : '0');
        window.__YB_MALL_WEBP_IS_SUPPORTED__ = res;
      })
      .catch(() => {
        storage.set('webpIsSupported', '0');
        window.__YB_MALL_WEBP_IS_SUPPORTED__ = false;
      });
  }
}
