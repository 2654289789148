// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c_PtToRe0b {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  box-sizing: border-box;\n}\n.line_1sCleUy8 {\n  height: 1px;\n  background-color: #EDEDED;\n  width: 100%;\n}\n.container_qHH9pqF2 {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  box-sizing: border-box;\n}\n.container_qHH9pqF2 .list_vvO9elj5 {\n  height: calc(100% - 1.41333rem);\n  overflow: scroll;\n  position: relative;\n}\n.container_qHH9pqF2 .list_vvO9elj5 > img {\n  width: 92%;\n  margin: 0 auto;\n}\n.container_qHH9pqF2 .listNoFooter_6zyrG6X- {\n  height: calc(100% - 1.41333rem);\n  overflow: scroll;\n  position: relative;\n  height: 100%;\n}\n.container_qHH9pqF2 .listNoFooter_6zyrG6X- > img {\n  width: 92%;\n  margin: 0 auto;\n}\n.container_qHH9pqF2 .footer_aiYdEC7l {\n  width: 10rem;\n  height: 1.41333rem;\n  display: flex;\n  flex-direction: row;\n}\n.container_qHH9pqF2 .active_7Gz9irvK {\n  flex: 1;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 0.42667rem;\n  font-weight: 600;\n  color: #ffffff;\n  line-height: 0.58667rem;\n  background: #FFAA27;\n}\n.container_qHH9pqF2 .disactive_KKdbYM3n {\n  flex: 1;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #ffffff;\n  background: #FFAA27;\n  background: #ffffff;\n  font-size: 0.42667rem;\n  font-weight: 600;\n  color: #545454;\n  line-height: 0.58667rem;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"c": "c_PtToRe0b",
	"line": "line_1sCleUy8",
	"container": "container_qHH9pqF2",
	"list": "list_vvO9elj5",
	"listNoFooter": "listNoFooter_6zyrG6X-",
	"footer": "footer_aiYdEC7l",
	"active": "active_7Gz9irvK",
	"disactive": "disactive_KKdbYM3n"
};
export default ___CSS_LOADER_EXPORT___;
