// tacker report
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { getEnvConfigrationByDomain } from 'fe-web-common';

const TRACKER_EVENT_NAME = 'fe_serverdiff_request';

// jsondiff
// eslint-disable-next-line no-shadow
const Operation = {
  REMOVE: 'REMOVE',
  ADD: 'ADD',
  UPDATE: 'UPDATE',
};

// export interface IChange {
//   type: Operation;
//   key: string;
//   // embeddedKey?: string;
//   source?: any;
//   destination?: any;
//   // changes?: IChange[];
// }

/**
 * 取两个字符串数组的并集
 * @param a sting[]
 * @param b string[]
 * @returns string[]
 */
function intersection(a, b) {
  const keys = [];
  a.forEach((k) => {
    keys.push(k);
  });

  b.forEach((k) => {
    if (keys.indexOf(k) === -1) {
      keys.push(k);
    }
  });

  return keys;
}

/**
 * 获取对象的类型
 * @param obj any
 * @returns string
 */
function getTypeOfObject(obj) {
  if (obj === undefined) {
    return 'undefined';
  }

  if (obj === null) {
    return 'null';
  }

  const type = typeof obj;
  // const matches = Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/);
  // Extracts the "Type" from "[object Type]" string.   Number String Boolean 为基础类型
  // const matches = Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/);
  // const type = matches ? matches[1] : '';

  if (type === 'string') {
    return 'string';
  }

  if (type === 'number') {
    return 'number';
  }

  if (type === 'boolean') {
    return 'boolean';
  }

  // Extracts the "Type" from "[object Type]" string.   Number String Boolean 为基础类型
  // const matches = Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/);
  // return matches ? matches[1] : '';

  if (Array.isArray(obj)) {
    return 'array';
  }

  return 'object';
}

/**
 * 判断两个对象是否类型相同
 * @param aType string
 * @param bType string
 * @returns boolkean
 */
function isSameType(aType, bType) { return aType === bType; }

/**
 *
 * @param {*} type string
 * @returns boolean
 */
function isUndefined(type) { return type === 'undefined'; }

/**
 *
 * @param {*} type string
 * @returns boolean
 */
function isNull(type) { return type === 'null'; }

/**
 * 判断某个值是否是基础数据类型
 * @param {*} type stirng
 * @returns boolean
 */
function isPrimitive(type) { return type === 'number' || type === 'string' || type === 'boolean'; }

// const isDate = (type:string) => type === 'Date';

// const isRegExp = (type:string) => type === 'RegExp';

/**
 *
 * @param {*} type string
 * @returns boolean
 */
function isArray(type) { return type === 'array'; }

/**
 *
 * @param {*} type string
 * @returns boolean
 */
function isObject(type) { return type === 'object'; }

/**
 * 比较简单类型的值
 * @param source string | number | boolean | null | undefined
 * @param destination string | number | boolean | null | undefined
 * @param path string
 * @returns IChange[]
 */
function comparePrimitives(source, destination, path) {
  const changes = [];
  if (source !== destination) {
    changes.push({
      type: Operation.UPDATE,
      key: path,
      source,
      destination,
    });
  }
  return changes;
}

/**
 * 比较日期值
 * @param source
 * @param destination
 * @param path
 * @returns
 */
// const compareDate = (source: Date, destination:Date, path:string):IChange[] => {
//   const changes = [];
//   const sourceValue = source.getTime();
//   const destinationValue = destination.getTime();
//   if (sourceValue !== destinationValue) {
//     changes.push({
//       type: Operation.UPDATE,
//       key: getKey(path),
//       value: sourceValue,
//       oldValue: destinationValue,
//     });
//   }
//   return changes;
// };

/**
 * 比较正则值
 * @param source
 * @param destination
 * @param path
 * @returns IChange[]
 */
// const compareRegExp = (source: Date, destination:Date, path:string):IChange[] => {
//   const changes = [];
//   const sourceValue = source.toString();
//   const destinationValue = destination.toString();
//   if (sourceValue !== destinationValue) {
//     changes.push({
//       type: Operation.UPDATE,
//       key: getKey(path),
//       value: sourceValue,
//       oldValue: destinationValue,
//     });
//   }
//   return changes;
// };

/**
 * 比较数组类型的值
 * @param source any[]
 * @param destination any[]
 * @param path string
 * @returns IChange[]
 */
function compareArray(source, destination, path) {
  let changes = [];
  const sLength = source.length;
  const dLength = destination.length;
  if (sLength !== dLength) {
    changes.push({
      type: Operation.UPDATE,
      key: path,
      source,
      destination,
    });
  } else {
    for (let i = 0; i < sLength; i++) {
      // eslint-disable-next-line no-use-before-define
      const diffs = jsonDiff(source[i], destination[i], `${path}[${i}]`);
      if (diffs.length) {
        changes = changes.concat(diffs);
      }
    }
  }
  return changes;
}

/**
 * 比较对象
 * @param source any
 * @param destination any
 * @param path string
 * @returns IChange[]
 */
function compareObject(source, destination, path) {
  let changes = [];

  const sourceKeys = Object.keys(source);
  const destinationKeys = Object.keys(destination);

  const intersectionKeys = intersection(sourceKeys, destinationKeys);
  intersectionKeys.forEach((k) => {
    // eslint-disable-next-line no-use-before-define
    const diffs = jsonDiff(source[k], destination[k], `${path}["${k}"]`);
    if (diffs.length) {
      changes = changes.concat(diffs);
    }
  });
  return changes;
}
/**
 * 比较两个json是否相同
 * @param source any
 * @param destination any
 * @param path string
 * @returns IChange[]
 */
function jsonDiff(source, destination, path) {
  const propertyKey = path;
  const aType = getTypeOfObject(source);
  const bType = getTypeOfObject(destination);
  let changes = [];

  if (!isSameType(aType, bType)) {
    // 如果其有一个是undefined 或者 null
    if (isUndefined(aType) || isNull(aType) || isUndefined(bType) || isNull(bType)) {
      if ((isUndefined(aType) || isNull(aType)) && (!isUndefined(bType) && !isNull(bType))) { // source是undefined或null, destination不是
        changes.push({ type: Operation.REMOVE, key: propertyKey, source });
      } else if ((!isUndefined(aType) && !isNull(aType)) && (isUndefined(bType) || isNull(bType))) { // source不是undefined或null, destination是undefined或null
        changes.push({ type: Operation.ADD, key: propertyKey, source });
      }
    } else { // source destination都不是undefined 或者null
      changes.push({
        type: Operation.UPDATE,
        key: propertyKey,
        source,
        destination,
      });
    }
  } else if (isPrimitive(aType)) {
    const diffs = comparePrimitives(source, destination, propertyKey);
    if (diffs.length) {
      changes = changes.concat(diffs);
    }
  } else if (isArray(aType)) {
    const diffs = compareArray(source, destination, propertyKey);
    if (diffs.length) {
      changes = changes.concat(diffs);
    }
  } else if (isObject(aType)) {
    const diffs = compareObject(source, destination, propertyKey);
    if (diffs.length) {
      changes = changes.concat(diffs);
    }
  }

  /* else if (isDate(aType)) {
    compareDate(source, destination, path);
  } else if (isRegExp(aType)) {
    compareRegExp(source, destination, path);
  } */
  return changes;
}

// import { initComparation, CompareType, setSourceData } from './compare';
/**
 * dataReporter:(envConfigData: Record<string, string>, path:string, changes:IChange[])=>void = null;
* 上报比对结果到棱镜
* @param envConfigData Record<string, string>
* @param path string
* @param changes IChange  import { IChange } from 'fe-web-common';
* @param uuid
*/
function dataReporter(apiPath, changes) {
  if (changes && changes.length) {
    const size = new TextEncoder().encode(JSON.stringify(changes)).length;
    const diffs = changes.filter((item) => {
      const key = item.key;
      if (
        !key.endsWith('["requestId"]')
        && !key.endsWith('["serverTime"]')
        && !key.endsWith('["lastVisitedTime"]')
      ) {
        return true;
      }
      return false;
    });

    if (diffs) {
      // 上报棱镜，飞书告警

      fetch(`${getEnvConfigrationByDomain().PRISM_BASEURL }/dataDiff`, {
        method: 'POST', // 指定请求方法
        headers: {
          'Content-Type': 'application/json', // 设置请求头，表明发送的数据类型
        },
        body: JSON.stringify({ path: apiPath, diffs }), // 将JavaScript对象转换为JSON字符串
      }).then((response) => {
        // 4. 处理响应
        if (!response.ok) { // 检查响应是否成功
          console.log(new Error(`Network response was not ok ${ response.statusText}`));
        }
      }).catch((error) => {
        // 6. 处理错误
        console.error('There has been a problem with your fetch operation:', error);
      });
    }
    // 判断超过上报的最大字符数时，清空实际的值
    if (size > 65535) {
      const newChanges = changes.map((item) => {
        if (item.type === Operation.ADD) {
          item.source = '$';
        } else if (item.type === Operation.UPDATE) {
          item.source = '$';
          item.destination = '$';
        }
        return item;
      });
      if (!__DEV__) { trackEvent(TRACKER_EVENT_NAME, { apiPath, changes: newChanges }); }
    } else if (!__DEV__) {
      trackEvent(TRACKER_EVENT_NAME, { apiPath, changes });
    }
  }
}

/**
 * 比较前后端数据
 * @param apiPath {string} node服务聚合数据接口地址
 * @param clientData {json} web端请求Java各服务的聚合数据
 * @param nodeData {json} 请求Node聚合接口获取的数据
 * @return void
 */
export function compareClienAndNodeData(apiPath, clientData, nodeData) {
  const diff = jsonDiff(clientData, nodeData, '$root');
  dataReporter(apiPath, diff);
}
