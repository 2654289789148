/*
 * @Author: jiangwei
 * @Date: 2023-05-12 11:27:52
 * @LastEditors: jiangwei
 * @LastEditTime: 2023-05-12 11:30:27
 * @Description: 登录判断页路由
 */
export default {
  path: '/loginPage',
  component: () => import('./index'),
};
