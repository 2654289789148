/* eslint-disable no-nested-ternary */
/**
 * 通用保险弹窗旧版
 *  -- type: 99
 */

import React, { Component } from 'react';
import { Modal } from 'antd-mobile-v2';
import Fetch from 'fetch';
import { Toast } from '@yb/fe-component';
import { storage } from 'utils';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import { getGZHName } from 'ybcommon/ybutils/common/company';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { inject, observer } from 'mobx-react';
import styles from './index.less';

@inject('userStore')
@observer
class CommonModal extends Component {
  constructor(props) {
    super(props);
    this.cnName = getGZHName();
    this.state = {
      data: null,
      totalTime: 0,
      endTime: 0,
      t0: '00',
      t1: '00',
      t2: '00',
    };
  }

  componentDidMount() {
    this.getCommonModalDetail();
  }

  componentWillUnmount() {
    if (this._timer) clearTimeout(this._timer);
  }

  getCommonModalDetail = () => {
    const { id, storageKey = '_KEY_', eventName = null, pathName = '' } = this.props;
    Fetch.post('api/popup/queryDetail', { popupId: id })
      .then((res) => {
        if (eventName) {
          trackEvent(eventName, { pathName, id });
        }
        let endTime = 0;
        const totaltime = +res.hasCountDown === 1 ? +res.countDownConfig.countDownNum * 1000 : 24 * 60 * 60 * 1000;
        if (!(res.countDownConfig && res.countDownConfig.resetCountDown)) {
          endTime = storage.get(storageKey);
          if (!endTime) {
            endTime = new Date().getTime() + totaltime;
            storage.set(storageKey, endTime);
          }
        } else {
          endTime = totaltime + new Date().getTime();
        }
        this.setState(
          {
            data: res,
            totalTime: totaltime,
            endTime,
          },
          () => {
            this._timer = setTimeout(() => {
              this._loop6((res.countDownConfig && res.countDownConfig.resetCountDown) || true);
            }, 1000);
          },
        );
      })
      .catch((err) => {
        Toast.fail(err);
      });
  };

  /**
   * 倒计时
   */
  _loop6 = (reset) => {
    const { storageKey } = this.props;
    if (this._timer) clearTimeout(this._timer);

    const { endTime, totalTime } = this.state;
    const nowTime = new Date().getTime();
    const diff = endTime - nowTime;
    if (diff <= 100) {
      const newEndTime = nowTime + totalTime;
      this.setState(
        {
          endTime: newEndTime,
        },
        () => {
          if (!reset) {
            storage.set(storageKey, newEndTime);
          }
        },
      );
    }

    const tmp = Math.round(diff / 1000);
    let hour = Math.floor(tmp / (60 * 60));
    let remain = tmp % (60 * 60);
    let minute = Math.floor(remain / 60);
    remain = Math.floor(tmp % 60);
    hour = hour < 10 ? `0${hour}` : hour;
    minute = minute < 10 ? `0${minute}` : minute;
    const second = remain < 10 ? `0${remain}` : remain;
    this.setState({
      t0: hour,
      t1: minute,
      t2: second,
    });
    this._timer = setTimeout(this._loop6, 1000);
  };

  _addZero(num, len = 2) {
    const _tmp = `$000${num}`;
    if (len === 1) {
      return _tmp.substr(_tmp.length - 3, 1);
    }
    return _tmp.substr(_tmp.length - len, len);
  }

  _getAction = (linkUrl) => {
    const { onClick } = this.props;
    if (onClick) onClick(linkUrl);
  };

  _onClose = (e) => {
    e.stopPropagation();
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  _renderNewContent = () => {
    const { userStore } = this.props;
    const { data, t0, t1, t2 } = this.state;
    const { popupImg, hasBtn, hasAvatar, hasCountDown, nickNameConfig = {}, btnConfig = {}, countDownConfig = {}, linkUrl } = data || {};
    const { btnImg, btnType, isBreath, btnBottom } = btnConfig || {};
    const { countDownBgColor, countDownFontColor, countDownBottom, countDownContent, contentColor = '#fff', contentFontsize = 16 } = countDownConfig || {};
    const { nickNameBreakline, avatarRadoius, fontSize, fontWeight, fontColor, backNicknameContent, preNicknameContent, top, preNicknameColor, nickNameColor } = nickNameConfig || {};
    const countDownContentStyle = {
      color: contentColor,
      fontSize: contentFontsize,
    };
    const countDownStyle = {
      backgroundColor: countDownBgColor,
      color: countDownFontColor,
    };
    const dotStyle = { color: countDownBgColor };
    let btnStyle = null;
    if (+btnType === 1 && +isBreath === 1) {
      btnStyle = styles.btnCB;
    } else if (+btnType === 1 && +isBreath === 0) {
      btnStyle = styles.btnC;
    } else if (+btnType === 2 && +isBreath === 0) {
      btnStyle = styles.btn;
    } else {
      btnStyle = styles.btnB;
    }
    let btnDom;
    if (+hasBtn) {
      btnDom = (
        <div
          className={btnStyle}
          style={{
            backgroundImage: `url(${btnImg})`,
            bottom: `${pxTOrem(+btnBottom || 45)}rem`,
          }}
          onClick={() => {
            this._getAction(linkUrl);
          }}
        />
      );
    } else {
      btnDom = <div />;
    }
    return (
      <div
        className={styles.mbg13}
        onClick={(e) => {
          e.stopPropagation();
          if (+hasBtn === 0) {
            this._getAction(linkUrl);
          }
        }}
      >
        <img src={popupImg} alt="" className={styles.mbgImg} onClick={() => false} />
        {+hasAvatar === 1 ? (
          <div
            className={+nickNameBreakline === 1 ? styles.info : styles.infoC}
            style={{
              marginTop: `${pxTOrem(+top || 30)}rem`,
            }}
          >
            <img
              className={styles.imgAvatar}
              src={userStore.headImg}
              alt=""
              style={{
                borderRadius: `${pxTOrem(+avatarRadoius)}rem`,
                marginRight: +nickNameBreakline === 0 ? 0 : `${pxTOrem(5)}rem`,
              }}
            />
            <div
              className={styles.nickname}
              style={{
                fontWeight,
                fontSize: `${pxTOrem(+fontSize)}rem`,
                color: fontColor,
              }}
            >
              {+nickNameBreakline === 0 ? (
                <div className={styles.nicknameBreak}>
                  <span style={{ color: preNicknameColor }}>{preNicknameContent}</span>
                  <span>{userStore.nickname || '元小保'}</span>
                  <span>{+nickNameBreakline === 0 ? `，${backNicknameContent}` : ''}</span>
                </div>
              ) : (
                <span
                  style={{
                    color: nickNameColor,
                  }}
                >
                  {userStore.nickname || '元小保'}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
        {+hasCountDown ? (
          <div className={styles.countDown} style={{ bottom: `${pxTOrem(+countDownBottom || 180)}rem` }}>
            <span style={countDownContentStyle} className={styles.t0}>
              {countDownContent}
            </span>
            <span className={styles.t1} style={countDownStyle}>
              {t0}
            </span>
            <span className={styles.t2} style={dotStyle}>
              :
            </span>
            <span className={styles.t1} style={countDownStyle}>
              {t1}
            </span>
            <span className={styles.t2} style={dotStyle}>
              :
            </span>
            <span className={styles.t1} style={countDownStyle}>
              {t2}
            </span>
          </div>
        ) : (
          <div />
        )}
        {btnDom}
      </div>
    );
  };

  _renderContent = () => (
    <div className={styles.modals}>
      <div className={styles.close} style={{ position: 'relative' }} onClick={this._onClose} />
      {this._renderNewContent()}
    </div>
  );

  render() {
    const { visible, data, animated = false } = this.props;
    if (!data) {
      return null;
    }
    return (
      <Modal animated={animated} transparent visible={visible} onClose={this._onClose} wrapClassName={styles.newModal}>
        {this._renderContent()}
      </Modal>
    );
  }
}

export default CommonModal;
