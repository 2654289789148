/*
 * @Author: shixiaoxia
 * @Date: 2022-07-27 14:48:08
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-08-29 18:12:32
 * @Description: 分流专用fetch
 */
import { getEnv, getHostSuffix } from 'ybcommon/envUtil';
import Fetch from './base';

const hostsuffix = getHostSuffix();

let _shuntFetch;
if (__PUB__) {
  _shuntFetch = new Fetch({ baseURL: `//api.${hostsuffix}`, timeout: 10 * 1000 });
} else if (__TEST__) {
  _shuntFetch = new Fetch({ baseURL: `//api-${getEnv()}.${hostsuffix}`, timeout: 10 * 1000 });
} else {
  _shuntFetch = new Fetch({ baseURL: '/gateway' });
}
const ShuntFetch = _shuntFetch.getInstance();
export default /** @type {any} */ (ShuntFetch);
