export default {
  path: '/jkjgift',
  component: () => import('./index'),
  routes: [
    {
      path: '/jkjgift/login',
      component: () => import('./Login'),
    },
    {
      path: '/jkjgift/confirm',
      component: () => import('./Confirm'),
    },
    {
      path: '/jkjgift/sms',
      component: () => import('./Sms'),
    },
  ],
};
