/*
 * @Author: wangchen wangchen@yuanbaobaoxian.com
 * @Date: 2023-10-27 14:17:32
 * @LastEditors: wangchen wangchen@yuanbaobaoxian.com
 * @LastEditTime: 2023-12-15 14:26:32
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/activity/cashCow/route.js
 * @Description: 摇钱树活动路由配置
 */
export default {
  path: '/activity/cashCow',
  component: () => import('./index'),
  title: '',
  registerOwnShare: {
    shouxin: true,
    yuanbao: true,
  },
  exact: true,
};
