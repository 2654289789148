/*
 * @Author: shixiaoxia
 * @Date: 2024-09-04 17:43:07
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-13 11:06:22
 * @Description: 获取赠险页面数据
 */
import { giftMiddleService, GiftMiddleRequestBody, StartReq, GiftMiddleResult, PrefixAbResultTemplates, DataResponse } from 'fe-web-common';
import { getIntegratedService } from './index';

interface MiddleParam {
  /** uuid */
  uuid: string;
  /** 是否小程序内 */
  isWeChatMiniApp: number;
  /** unionId */
  unionId: string;
  /** appId */
  appId: string;
  /** 渠道号 */
  channel: string;
  /** position */
  ybPosition: string;
  /** 加密userId */
  userIdencrypt: string;
  /** 初始访问url */
  originalUrl: string;
}

type AllMiddleParam = {
  /** 用户id */
  userId: string;
  /** 用户token */
  userToken: string;
} & MiddleParam;

/**
 * /gift/middle页面初始化时的数据
 */
let giftMiddlePageInitData: DataResponse<PrefixAbResultTemplates> | null = null;

/**
 * 获取 /gift/middle页面初始化时的数据
 * !!! 请注意，这个方法不应该被/gift/middle 页之外的其他组件使用。 这只是一个临时的方案。
 * !!! 正确的应该是在路由变化时，将页面初始化数据通过context来传递。
 * @returns
 */
export function getGiftMiddlePageInitData() {
  return giftMiddlePageInitData;
}

/**
 * 封装获取/gifit/middle页面初始化数据时的参数
 * @param param
 * @returns
 */
const getGiftMiddleParams = (middleParam: MiddleParam) => {
  const { ybPosition, originalUrl } = middleParam;
  const startParams: StartReq = { };
  if (originalUrl) {
    startParams.originalUrl = originalUrl;
  }
  const abtestParams = {
    ybPosition,
  };
  const giftMiddleParams = {
    startParams,
    abtestParams,
  };
  return giftMiddleParams;
};

export const getGiftMiddleData = async (allMiddleParam: AllMiddleParam) => {
  const { userId, uuid, userToken, isWeChatMiniApp, unionId, appId, channel, originalUrl, userIdencrypt, ybPosition } = allMiddleParam;
  // 将原有数据为空
  giftMiddlePageInitData = null;
  const giftMiddleParams: GiftMiddleRequestBody = getGiftMiddleParams({ uuid, isWeChatMiniApp, unionId, appId, channel, originalUrl, userIdencrypt, ybPosition });
  const result = await getIntegratedService<GiftMiddleRequestBody, GiftMiddleResult>({ userId, uuid, userToken, isWeChatMiniApp, unionId, appId, channel, userIdencrypt }, giftMiddleParams, '/node/api/gift/middle', giftMiddleService);
  const { prefixABTestData } = result;
  giftMiddlePageInitData = prefixABTestData;
  return result;
};
