export const FACEID_INFO = 'faceID_info'; // 人脸识别缓存key

// 邮政绑卡失败视频&封面
export const POSTVIDEOMATERIAL = {
  postVideo: 'https://cdn.ybaobx.com/media/20231222/zgyzcxyh.mp4',
  postVideoPoster: 'https://cdn.ybaobx.com/shunt/config/8428677034379128832.png?size=580*1080',
};

// 组合产品类型枚举
export const LINKTYPEMAP = {
  other: 0, // 非组合
  combine: 1, // 0+长组合产品
  couple: 2, // 均分组合产品
  compose: 3, // 0+12组合产品
};
