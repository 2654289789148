/*
 * @Author: shixiaoxia
 * @Date: 2022-07-27 14:44:32
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2022-07-27 17:25:31
 * @Description: 刷单fetch（元保刷单页面 需访问收银台test2环境域名）
 */
import Fetch from './base';

const _ybShuadanFetch = new Fetch({ baseURL: '//bp-test2.ybbxkj.com/' });
const YbShuadanFetch = _ybShuadanFetch.getInstance();
export default YbShuadanFetch;
