/*
 * @Author: shixiaoxia
 * @Date: 2024-09-02 19:05:46
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-05 11:13:58
 * @Description: 请填写简介
 */
import { AxiosResponse } from 'axios';

import { CommonHeader, DataResponse } from '../../base';
import { CommonApiParams } from '../../../types';
import { bffFetch } from '../bffFetch';
import { OrderPersonCheckResult } from '../types';
import { QueryOrderHmaAcceptstatisticReq } from '../queryOrderHmaAcceptstatistic';

/**
 * 获取订单家庭成员领取情况聚合二要素
 * @param params 请求参数
 * @param commonHeader 统一header
 * @returns 订单家庭成员领取结果
 */
export async function queryOrderHmaAcceptstatisticPersonCheck(
  params: QueryOrderHmaAcceptstatisticReq,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
):Promise<AxiosResponse<DataResponse<OrderPersonCheckResult>, unknown>> {
  const result = await bffFetch(commonHeader).post('api/mall/bff/hma/acceptstatistic/personCheck/query', { ...commonApiParams, ...params });
  return result;
}
