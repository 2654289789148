/*
 * @Author: pyj
 * @Date: 2022-05-19 13:36:33
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-01-09 15:10:16
 * @Description: 可视化sdk预览-operView路由、弹窗preview路由
 */
export default [
  {
    path: '/new/modal/preview', // 新配置弹窗预览页面
    component: () => import('./NewCMPreview'),
  },
  {
    path: '/yb/preview',
    component: () => import('./YBPreview'),
  },
  {
    path: '/secret/preview',
    component: () => import('./TJPreview'),
  },
  {
    path: '/tjmodal/preview',
    component: () => import('./TjModalPreview'),
  },
  {
    path: '/questionnaire/preview',
    component: () => import('./QuestionnairePreview'),
  },
  {
    path: '/lottie/preview',
    component: () => import('./LottiePreview'),
  },
];
