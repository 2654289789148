/**
 * 中奖列表滑动组件
 * 属性：
 *  awards：奖品信息，至少应有award以及desc描述
 *  outerClassName：最外层样式
 *  data：列表数据
 */
import React, { Component } from 'react';
import { render } from 'react-dom';

const ROLL_SPEED = 25; // 滑动速度
export default class WinningList extends Component {
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  /**
   * 初始化滑动组件
   */
  initLottery = () => {
    const noticeList1 = document.getElementById('notice-list');
    const noticeList2 = document.getElementById('notice-list-2');
    noticeList2.innerHTML = noticeList1.innerHTML;
  }

  // 添加item
  addItem = (data) => {
    const { item } = this.props;
    const listWrapper = document.getElementById('list-wrapper');
    const noticeList1 = document.getElementById('notice-list');
    const noticeList2 = document.getElementById('notice-list-2');
    const newItem = document.createElement('li');
    const child = item(data);
    render(child, newItem);
    const existingItem = noticeList1.childNodes[Math.floor(listWrapper.scrollTop / 35) + 1];
    noticeList1.insertBefore(newItem, existingItem);
    noticeList2.innerHTML = noticeList1.innerHTML;
  }

  // 开始循环滚动
  rollStart = () => {
    this.timer = setInterval(() => {
      const listWrapper = document.getElementById('list-wrapper');
      const noticeList1 = document.getElementById('notice-list');
      if (listWrapper.scrollTop >= noticeList1.offsetHeight) {
        listWrapper.scrollTop = 0;
      } else {
        listWrapper.scrollTop++;
      }
    }, ROLL_SPEED);
  }

  render() {
    const { outerClassName, data, item } = this.props;
    return (
      <div className={outerClassName} id="list-wrapper" style={{ position: 'relative' }}>
        <ul id="notice-list">
          {
            data && data.map((val, index) => (
              <li key={`${index + 1}`}>
                {item(val)}
              </li>
            ))
          }
        </ul>
        <ul id="notice-list-2" />
      </div>
    );
  }
}
