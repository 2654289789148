/*
 * @Author: shixiaoxia
 * @Date: 2023-03-20 14:22:48
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-03-20 14:24:45
 * @Description: 图片上传接口
 */
import Fetch from './base';

const UploadFetch = new Fetch({ timeout: 100 * 1000 }).getInstance();

export default UploadFetch;
