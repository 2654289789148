import React, { Component } from 'react';
import { NumberUtil } from 'fe-yb-tools';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import styles from './index.less';

const { formatWanNumber } = NumberUtil;

export default class Danmu extends Component {
  hasStop = true;

  _getAllData = (arr, isEven) => {
    const data = isEven
      ? arr.filter((subArr, idx) => idx % 2 === 0)
      : arr.filter((subArr, idx) => idx % 2 === 1);
    const flatData = data.reduce((scc, cur) => scc.concat(cur), []);
    return flatData;
  };

  _startScroll = () => {
    if (!this.hasStop) {
      return;
    }
    this.hasStop = false;
    setTimeout(() => {
      const { id, data } = this.props;
      const ele = document.getElementById(id);
      if (ele) {
        let damWidth = 343;
        if (this.danmuRect) {
          damWidth = this.danmuRect.width;
        }
        ele.style.cssText = `transform: translateX(-100%); transition:transform ${data.length * 6}s linear 0s`;
        ele.addEventListener('transitionend', () => {
          if (ele) {
            ele.style.cssText = `transform: translateX(${pxTOrem(damWidth / 2)}rem)`;
            this.hasStop = true;
            this._startScroll();
          }
        });
      }
    }, 10);
  };

  _renderItem = (item, index) => {
    const subTextInfo = formatWanNumber(item.mentions);
    const { showAll } = this.props;
    return (
      <div
        className={index === 0 ? styles.danmuItemFirst : styles.danmuItem}
        key={item.fqaId}
        onClick={() => {
          const { onItemClick } = this.props;
          if (onItemClick) {
            onItemClick(item);
          }
        }}
      >
        <img className={styles.head} src={item.headImage} alt="" />
        <div className={styles.title}>{item.title}</div>
        {
          !showAll && (
            <div className={styles.showAll}>
              <div className={styles.metion}>{subTextInfo.num}{subTextInfo.showWan ? 'w' : ''}人问</div>
              <div className={`iconfont iconarrowRight ${styles.arrow}`} />
            </div>
          )
        }

      </div>
    );
  };

  render() {
    const { data, id } = this.props;
    if (data && data.length > 0) {
      const data1 = this._getAllData(data, true);
      const data2 = this._getAllData(data, false);
      return (
        <div
          className={styles.danmuCon}
          ref={(ref) => {
            if (ref) {
              this.danmuRect = ref.getBoundingClientRect();
            }
          }}
        >
          <div id={id} className={styles.scrollPart}>
            <div className={styles.line1}>
              {
                data1.map((item, index) => this._renderItem(item, index))
              }
            </div>
            <div className={styles.line2}>
              {
                data2.map((item, index) => this._renderItem(item, index))
              }
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}
