/*
 * @Author: shixiaoxia
 * @Date: 2022-07-27 13:36:49
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-08-29 18:09:04
 * @Description: 网关fetch
 */
import { getEnv, getHostSuffix } from 'ybcommon/envUtil';
import Fetch from './base';

const hostsuffix = getHostSuffix();

let _tianjiFetch;
if (__PUB__) {
  _tianjiFetch = new Fetch({ baseURL: `//api.${hostsuffix}` });
} else if (__TEST__) {
  _tianjiFetch = new Fetch({ baseURL: `//api-${getEnv()}.${hostsuffix}` });
} else {
  _tianjiFetch = new Fetch({ baseURL: '/gateway' });
}
const TianjiFetch = _tianjiFetch.getInstance();
export default /** @type {any} */ (TianjiFetch);
