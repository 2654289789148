/*
 * @Author: shixiaoxia
 * @Date: 2024-05-21 15:36:21
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-11-04 17:30:36
 * @Description: gift/detail数据获取
 */
import { CommonHeader } from '../../../fetch/base';
import { IfHasGiftOrderReq } from '../../../fetch/bff';
import { MallQueryDetailReq } from '../../../fetch/mallGateway';
import { giftDetailLoginService } from './login';
import { giftDetailUnLoginService } from './unlogin';
import { giftDetailPfreeService } from './pfree';
import { GiftDetailLoginResult } from './login/types';
import { PfreeGiftDetailResult } from './pfree/types';
import { GiftDetailUnLoginServiceResult } from './unlogin/types';
import { CommonParams } from '../../../types';

export interface GiftDetailAbtestParams {
  productAbb: string, // 产品简称
  isWifi: number; // 1 是: 2 否
  previewCode?: string; // 预览code
  ybPosition?: string; // position
  landingPageVersion?: number; // 落地页版本
  lastVisitTime: string; // 上次访问时间
}

export interface QueryOrderCommonAcceptstatisticReq {
  insuranceId: string; // 产品id
  userIdencrypt: string; // 加密userId
}

// 赠险请求参数
export interface GiftDetailParam {
  hasLogin: boolean, // 是否登录态
  userIdencrypt: string; // 加密userId
  // insuranceId: string; // 产品id
  // landingPageVersion?: number; // 落地页版本
  // queryType?: number; // 不传默认 0；0-只查询上架数据，1-CMS 落地页预览使用
  queryOrderParams: QueryOrderCommonAcceptstatisticReq, // 请求家庭信息
  queryDetailParams: MallQueryDetailReq; // 请求产品详情
  ifHasOrderParams: IfHasGiftOrderReq, // 请求是否有订单
  abtestParams: GiftDetailAbtestParams; // ab测数据
}

export const giftDetailService = async (
  giftParam: GiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailLoginResult | PfreeGiftDetailResult | GiftDetailUnLoginServiceResult> => {
  const { hasLogin, userIdencrypt } = giftParam;

  let result;
  if (hasLogin) {
    result = await giftDetailLoginService(giftParam, commonHeader);
  } else if (userIdencrypt) {
    result = await giftDetailPfreeService(giftParam, commonHeader);
  } else {
    result = await giftDetailUnLoginService(giftParam, commonHeader);
  }
  return result;
};
