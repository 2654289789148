/*
 * @Author: surun
 * @Date: 2023-03-23 13:58:45
 * @LastEditors: surun
 * @LastEditTime: 2023-03-23 16:41:46
 * @Description: 顶部提示组件
 */
import React from 'react';
import { getImgPath } from 'ybcommon/ybutils/common/common';
import ReactDom from 'react-dom';

import styles from './index.less';

const icon = getImgPath(require('./icon.png'));

function TopTipC(props) {
  const { text } = props;
  return (
    <div className={styles.wrap}>
      <img src={icon} alt="" className={styles.icon} />
      {text}
    </div>
  );
}

class TopTip {
  div = null;

  show(text, duration = 3000) {
    if (this.div) {
      this.destory();
    }
    this.div = document.createElement('div');
    document.body.appendChild(this.div);
    ReactDom.render(<TopTipC text={text} />, this.div);
    setTimeout(() => {
      this.destory();
    }, duration);
  }

  destory() {
    if (!this.div) return;
    ReactDom.unmountComponentAtNode(this.div);
    document.body.removeChild(this.div);
    this.div = null;
  }
}

export default new TopTip();
