/*
 * @Author: pyj
 * @Date: 2023-08-17 18:56:23
 * @LastEditors: 李琛
 * @LastEditTime: 2024-07-16 21:00:00
 * @Description: 请填写简介
 */
/**
 * 保障计划
 */

import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './index.less';

/**
 * @param {boolean} needOddBottom 最后一条数据为灰色是否需要底部bottom
 * @param {boolean} markValue 价格字体颜色是否用橙色 默认#878787
 * @param {number} protectInfoType 保障计划类型  1-原版  2-文字颜色有所改变 3-新版 每条条目下的免赔额、赔付比例不再展示，最大宽度变宽，折叠文字灰色 每条保障计划value居上展示，不再居中 4-小标题、折叠按钮在右上角版本
 * @param {number} foldAction 折叠功能 0-没有折叠能力 1-只有“查看更多”  2-“查看更多” + “收起内容”
 * @param {number} rowNumOnfold 折叠的时候展示的条目数
 * @param {any[]} extendEles 扩展展示内容，展示到保障计划列表下面
 * @param {Function} onFoldClick 当折叠功能点击的时候
 * @param {boolean} isShowProtectionTag 落地页保障计划标签展示ab测 版本2 为TRUE prd：https://yuanbaoshuke.feishu.cn/docx/Wq6AdIEyro2X5cxsIjHckXn5nkh
 * @param {any[]} versions 责任列表
 * @param {string} deductibles 免赔额
 * @param {string} compensationRatio 赔付比例
 * @param {boolean} isShowCardStyle 是否展示卡片化
 * @param {string} title 小标题，“1个月先体验”
 * @param {boolean} weakTitle 弱化样式小标题，字重细
 * @param {string} premium 费率价格
 * @param {string} premiumLabel 费率前文案：“封顶600万 基础版保障”
 * @param {string} tips 最后的提示文案
 */
class Protect extends Component {
  static defaultProps = {
    protectInfoType: 1, // 保障计划类型  1-原版  2-文字颜色有所改变 3-新版 每条条目下的免赔额、赔付比例不再展示，最大宽度变宽，折叠文字灰色 每条保障计划value居上展示，不再居中
    foldAction: 0, // 折叠功能 0-没有折叠能力 1-只有“查看更多”  2-“查看更多” + “收起内容”
    rowNumOnfold: 3, // 折叠的时候展示的条目数
    extendEles: [], // 扩展展示内容，展示到保障计划列表下面
    onFoldClick: () => { }, // 当折叠功能点击的时候
    isShowProtectionTag: false, // 落地页保障计划标签展示ab测 版本2 为TRUE prd：https://yuanbaoshuke.feishu.cn/docx/Wq6AdIEyro2X5cxsIjHckXn5nkh

    // 保障计划条目列表
    // eg:versions = [{
    //   duty: '重大疾病医疗保险金', // 条目title,
    //   dutyName:'重大疾病医疗保险金', // 条目title,  同duty，兼容字段
    //   amount:  '60万',// 金额
    //   insuredAmount: '60万', // 金额, 同amount 兼容字段
    //   dutyDesc:'免赔额1万，赔付比例30%', // 条目详情描述
    //   insuranceTypeDutyDesc:'免赔额1万，赔付比例30%', // 条目详情描述 同insuranceTypeDutyDesc，兼容字段
    //   keyTag: '升级后', // 条目title后面跟着的tag
    //   baseTag: '基础版', // 条目title后面跟着的tag
    //   upgradeTag: '加强版', // 条目title后面跟着的tag
    // }]
    versions: [],
    deductibles: '', // 免赔额
    compensationRatio: '', // 赔付比例
    isShowCardStyle: false, // 是否展示卡片化
    keyValueContainerClassName: '', // 为keyValueContainer div模块添加样式
  };

  constructor(props) {
    super(props);
    const { showMore, foldAction } = this.props;
    this.foldAction = showMore ? 1 : foldAction; // 转换下，兼容之前的逻辑  0-没有折叠能力 1-只有“查看更多”  2-“查看更多” + “收起内容”
    this.state = {
      isFold: this.foldAction > 0,
    };
  }

  /**
   * 渲染保障计划详情
   * @param {*} isPreUpdate 是否是前置升级
   * @param {*} keyDesc 文案
   * @returns
   */
  _renderKeyDesc = ({ isPreUpdate, keyDesc }) => {
    const { protectInfoType } = this.props;
    if ([3, 4].includes(protectInfoType)) return null;
    const isHideKeyDesc = protectInfoType === 2;
    if (!isPreUpdate) {
      return <div className={styles.keyDesc}>{keyDesc}</div>;
    }

    return !isHideKeyDesc && keyDesc && <div className={styles.keyDesc}> {keyDesc}</div>;
  };

  _renderItem = (index, item) => {
    // renderType 1-渲染的是DOM元素 不传递-渲染的保障计划条目
    const { key, value, keyDesc, keyTag, baseTag, upgradeTag, renderType, itemEle } = item;
    const { markValue, isPreUpdate, keyValueContainerClassName } = this.props;
    const markValueStyle = markValue ? styles.markValue : null;
    return (
      renderType === 1
        ? (
          <div key={`${key + value + keyTag || baseTag || upgradeTag}${index}`}>
            {itemEle}
          </div>
        )
        : (
          <div className={classNames(styles.keyValueContainer, keyValueContainerClassName)} key={`${key + value + keyTag || baseTag || upgradeTag}${index}`}>
            <div className={classNames(styles.keyBox)}>
              <div className={styles.key}>
                <span>{key}</span>
                {keyTag ? <span className={styles.keyTag}>{keyTag}</span> : null}
                {baseTag ? <span className={styles.keyTag}>{baseTag}</span> : null}
                {upgradeTag ? <span className={styles.keyTag}>{upgradeTag}</span> : null}
              </div>
              {this._renderKeyDesc({ isPreUpdate, keyDesc })}
            </div>
            <div className={classNames(styles.value, markValueStyle, isPreUpdate ? '' : styles.value2)}>{value}</div>
          </div>
        )

    );
  };

  _changeFold = () => {
    const { onFoldClick } = this.props;
    const { isFold } = this.state;
    onFoldClick(isFold ? 1 : 2); // 1--当前点击的是‘查看更多’  2--当前点击的是‘收起内容’
    this.setState({
      isFold: !isFold,
    });
  };

  /**
   * 渲染折叠功能
   */
  _renderFold = (itemList) => {
    const { rowNumOnfold, protectInfoType } = this.props;
    const expandText = protectInfoType === 4 ? '展开' : '查看更多';
    const foldText = protectInfoType === 4 ? '收起' : '收起内容';
    if (itemList.length > rowNumOnfold) {
      // 大于折叠行数才折叠
      const { isFold } = this.state;
      if (isFold || (!isFold && this.foldAction === 2)) {
        // 折叠状态或者 非折叠状态要展示收起的时候展示底部操作功能
        return (
          <div className={classNames(styles.moreContainer, isFold ? styles.moreContainerFold : '')}>
            <div
              className={styles.moreBtn}
              onClick={this._changeFold}
            >
              <div> {isFold ? expandText : foldText} </div>
              {
                [3, 4].includes(protectInfoType)
                  ? <i className={classNames('iconfont icongengduo', styles.moreArrow, { [styles.arrowDown]: !isFold })} />
                  : <img className={classNames(styles.moreArrow, { [styles.arrowDown]: !isFold })} src={require('./moreArrow.png')} alt="" />
              }

            </div>
          </div>
        );
      }
    }

    return null;
  };

  /**
   * @description: 渲染免赔额、赔付比例
   * @param {*} title 标题
   * @param {*} value 值
   * @return {*}
   */
  _renderExtraLine = (title, value) => (
    value ? (
      <div className={styles.lineWrap} key={title}>
        <div className={styles.lineTitle}>{title}</div>
        <div className={styles.lineContent}>{value}</div>
      </div>
    ) : null
  );

  /** protectInfoType为4时，展示的小标题和价格文案 */
  _renderTitle = (itemList) => {
    const { title, weakTitle, premium, premiumLabel } = this.props;
    return (
      <>
        <div className={classNames(styles.title, {
          [styles.weakTitle]: weakTitle,
        })}
        >
          <span>{title}</span>
          {this._renderFold(itemList)}
        </div>
        <div
          id={weakTitle && 'link3-protectPlan-upgradePrice-anchor'}
          className={styles.premium}
          style={{ marginBottom: weakTitle && '17px' }}
        >
          <span className={styles.label}>{premiumLabel}</span>
          <span className={styles.value}>{premium}</span>
        </div>
      </>
    );
  };

  _renderTips = () => {
    const { protectInfoType, tips } = this.props;
    if (protectInfoType !== 4 || !tips) return null;
    return (
      <div className={styles.tips}>{tips}</div>
    );
  };

  render() {
    const { className, needOddBottom, versions, extendEles, isShowProtectionTag, rowNumOnfold, protectInfoType, isShowCardStyle, deductibles, compensationRatio } = this.props;
    const { isFold } = this.state;
    const specailStyle = `${needOddBottom && versions.length % 2 === 0 && versions.length !== 0 ? styles.needOddBottom : null}`;
    const itemList = [...versions, ...extendEles.map((item) => ({ renderType: 1, itemEle: item }))];
    return (
      <div
        id="link3-protectPlan-moreBtn-anchor"
        className={classNames(className, specailStyle, {
          [styles.tagVersion]: isShowProtectionTag,
          [styles.protectStyle3]: [3, 4].includes(protectInfoType),
          [styles.protectStyle4]: protectInfoType === 4,
          [styles.inCard]: isShowCardStyle,
        })}
      >
        {protectInfoType === 4 && this._renderTitle(itemList)}
        {itemList.map((val, index) => {
          if (index > rowNumOnfold - 1 && isFold) return null;

          // 兼容保险2.0
          if (val.renderType === 1) {
            return this._renderItem(index, val);
          }
          const duty = val.duty || val.dutyName || '';
          const amount = val.amount || val.insuredAmount || '';
          const desc = val.dutyDesc || val.insuranceTypeDutyDesc || '';
          return this._renderItem(index, { key: duty, value: amount, keyDesc: desc, keyTag: val.keyTag, baseTag: val.baseTag, upgradeTag: val.upgradeTag });
        })}
        {this._renderExtraLine('免赔额', deductibles)}
        {this._renderExtraLine('赔付比例', compensationRatio)}
        {this._renderTips()}
        {protectInfoType !== 4 && this._renderFold(itemList)}
      </div>
    );
  }
}

export default Protect;
