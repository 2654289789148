/*
 * @Author: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @Date: 2023-09-05 15:48:28
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2023-09-11 15:58:44
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/pay/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  path: '/pay',
  routes: [
    {
      path: '/pay/detail', // 主动支付页
      component: () => import('./Detail'),
    },
    {
      path: '/pay/center', // 支付中心页，目前主要用于泰康
      component: () => import('./Center'),
    },
    {
      path: '/pay/cash',
      component: () => import('./Cash'),
    },
    {
      path: '/pay/cashier', // 自主支付收银台
      component: () => import('./Cashier'),
    },
    {
      path: '/pay/middle', // form表单形式支付
      component: () => import('./Middle'),
    },
    {
      path: '/pay/transition', // 聚合收银台过渡页
      component: () => import('./Transition'),
    },
    {
      path: '/pay/sign', // 双签约中间页
      component: () => import('./SignMiddle'),
    },
    {
      path: '/pay/customization', // 微信商户代扣
      component: () => import('./CustomizedService'),
      title: '服务',
    },
  ],
};
