/**
 * @弹窗组件描述 显示时不会使页面滚动到顶部的弹窗
 * @visible 控制弹窗隐藏显示
 * @transparent 让modal背景为透明，默认为白色
 * @popup 属性为false时，animationType默认为'fade'。 属性为true时，animationType默认为'slide-up'可指定为'slide-down'
 * @wrapClassName modalWrap层的样式。主要用于排列布局
 * @className modal的样式。用于覆盖modal的样式
 * @onMaskClick 点击蒙层回调
 * @onClose 弹窗即将关闭的回调
 * @afterClose Modal 完全关闭后的回调
 * @modalPassiveUnmountCallback 弹窗被动卸载后调用，主要指用户使用浏览器返回按钮关闭弹窗的情况
*/
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { type as UtilType } from 'fe-yb-tools';
import store from 'store';
import { PageInteractionLogManager, InteractionTopic } from 'ybcommon/ybutils/PageInteractionLogManager';
import './index.less';
import MaskDomUtil from 'src/common/utils/maskDomUtil';

const { toolStore } = store;

export default class YBModal extends Component {
  static defaultProps = {
    onOpened: () => { }, // 弹窗打开后调用
    modalPassiveUnmountCallback: () => { }, // 弹窗被动卸载后调用，主要指用户使用浏览器返回按钮关闭弹窗的情况
    onShowRendered: () => { }, // 当展示弹窗的时候渲染元素后（动效之前）执行，此时可以获取dom等操作
    forbidOpenAnimation: false, // 打开弹窗时不执行动画
  };

  /** 用户手指是否移动过 */
  hasUserTouchMoved = false;

  constructor(props) {
    super(props);
    this.id = `ybm${Math.random().toString(36).slice(-6)}`;
    this.state = {
      renderVisible: false,
      modalVisible: !!props.forbidOpenAnimation,
    };
  }

  componentDidMount() {
    /** 正在展示弹窗，记录交互 */
    PageInteractionLogManager.startInteraction(InteractionTopic.ShowModal);
    this.showModal();
  }

  componentWillUnmount() {
    toolStore.removeYbModalId(this.id);
    /** 关闭弹窗，执行记录交互的回调 */
    PageInteractionLogManager.endCurInteraction();
    // 使用浏览器返回按钮，导致弹窗被动卸载时处理的逻辑
    const { modalPassiveUnmountCallback, visible } = this.props;
    if (modalPassiveUnmountCallback && visible) {
      modalPassiveUnmountCallback();
    }
  }

  componentDidUpdate(preProps) {
    const { visible } = this.props;
    if (visible !== preProps.visible) {
      if (visible) {
        this.showModal();
      } else {
        this.closeModal();
      }
    }
  }

  showModal = () => {
    const { visible, onOpened, onShowRendered, forbidOpenAnimation } = this.props;
    if (!visible) return;
    // showModal调用后如果用户机器性能较差会导致renderVisible的更新较慢，期间用户可能会点击页面上的其他按钮导致双弹窗。
    MaskDomUtil.addMaskDom(5);

    toolStore.addYbModalId(this.id);
    // 通过延时方法控制显示顺序 先显示外层结构 再显示modal
    setTimeout(() => {
      this.setState({
        renderVisible: visible,
      }, () => {
        if (onShowRendered) onShowRendered();
        setTimeout(() => {
          this.setState({
            modalVisible: visible,
          });
          if (forbidOpenAnimation) {
            // 弹窗打开，释放页面
            MaskDomUtil.removeMaskDom();
            onOpened();
          } else {
            setTimeout(() => {
              // 弹窗打开，释放页面
              MaskDomUtil.removeMaskDom();
              onOpened();
            }, 500);
          }
        }, 20);
      });
    });
  };

  closeModal = () => {
    const { onClose, afterClose } = this.props;
    if (onClose && typeof onClose === 'function') onClose();
    this.setState({
      modalVisible: false,
    }, () => {
      toolStore.removeYbModalId(this.id, this.props);
      // 为了保证关闭时依然显示动画效果，给了与过渡动画相同事件的等待时间，再这之后再关闭
      setTimeout(() => {
        this.setState({
          renderVisible: false,
        }, () => {
          // 当页面上所有通过编程式打开的弹窗关闭时再销毁弹窗
          if (!toolStore.modalVisible) {
            toolStore.modalType = -1;
            toolStore.modalProps = null;
          }
          if (afterClose && typeof afterClose === 'function') afterClose();
        });
      }, 450);
    });
  };

  _onClickMask = (e) => {
    e.stopPropagation();
    const { onMaskClick } = this.props;
    if (onMaskClick) onMaskClick();
  };

  _onMaskTouchEnd = (e) => {
    if (this.hasUserTouchMoved) {
      // 用户滑动结束
      this._onMaskSlideEnd();
      this.hasUserTouchMoved = false;
    }
    // 判断当前点击元素是否为蒙层，点击子元素时不触发蒙层的onTouchEnd事件
    const targetClass = e?.target?.className || '';
    const isTargetMask = targetClass?.includes && UtilType.isFunction(targetClass.includes) && targetClass.includes('yb-modal-mask');
    if (!isTargetMask) return;
    // 这个会阻止所有弹窗内滑动事件的传播，所以只需要在滑动的元素是蒙层时阻止传播即可
    e.stopPropagation();
    const { onMaskTouchEnd } = this.props;
    if (onMaskTouchEnd) onMaskTouchEnd();
  };

  // 用户滑动结束
  _onMaskSlideEnd = () => {
    const { onMaskSlideEnd } = this.props;
    if (onMaskSlideEnd) onMaskSlideEnd();
  };

  _onClickModal = (e) => {
    e.stopPropagation();
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  _renderChild = (child, index) => <Fragment key={`${index + 1}`}>{child}</Fragment>;

  render() {
    const {
      children,
      popup = false,
      animationType = 'fade',
      transparent = false,
      className = '',
      wrapClassName = '',
      bgOpacity = 0.6,
      modalStyle = {},
      showAnimationStyle = true,
    } = this.props;
    const {
      renderVisible,
      modalVisible,
    } = this.state;
    // modal开始状态
    let animationStyle = 'yb-modal-fade';
    // modal结束状态
    let transClassName = 'yb-modal-showfade';
    let popupStyle = null;
    if (popup) {
      popupStyle = 'yb-modal-popup';
      if (animationType === 'slide-down') {
        animationStyle = 'yb-modal-popup-slidedown';
        transClassName = 'yb-modal-slidedown';
      } else {
        animationStyle = 'yb-modal-popup-slideup';
        transClassName = 'yb-modal-slideup';
      }
    } else {
      popupStyle = null;
      animationStyle = 'yb-modal-fade';
      transClassName = 'yb-modal-showfade';
    }
    if (!showAnimationStyle) animationStyle = '';
    const contentView = (
      <div
        id={this.id}
        className="yb-modal-main"
        style={{ display: renderVisible ? 'block' : 'none', ...modalStyle }}
      >
        <div
          className={classnames('yb-modal-wrap',
            wrapClassName || '',
            'yb-modal-mask',
            modalVisible ? 'yb-modal-masktrans' : null)}
          style={{ background: `rgba(0, 0, 0, ${bgOpacity})` }}
          onClick={this._onClickMask}
          onTouchMove={() => {
            this.hasUserTouchMoved = true;
          }}
          onTouchEnd={this._onMaskTouchEnd}
        >
          <div
            className={
              classnames(
                'yb-modal',
                transparent ? 'yb-modal-transparent' : null,
                popupStyle,
                className || '',
                // renderClassName,
                animationStyle,
                modalVisible ? transClassName : null,
              )
            }
            onClick={this._onClickModal}
          >
            <div className="yb-modal-content">
              <div className="yb-modal-body">
                {
                  Array.isArray(children)
                    ? children.map((child, index) => this._renderChild(child, index))
                    : children && this._renderChild(children)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return ReactDOM.createPortal(contentView, document.body);
  }
}
