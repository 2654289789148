/**
 * 生成消息数组
 * @param {*} level string  [trace debug info warn error fatal] 选其一
 * @param {*} message string
 * @param {*} meta any
 * @returns void
 */
const getMessageArray = (level, message, meta) => {
  const array = [(new Date()).toISOString(), level, message];
  if (meta !== undefined) {
    array.push(meta);
  }
  return array;
};

/**
 * 写log
 * @param {*} message string
 * @param {*} meta any
 * @return void
 */
const logger = {
  trace(message, meta = undefined) {
    console.log(getMessageArray('[trace]', message, meta));
  },
  debug(message, meta = undefined) {
    console.log(getMessageArray('[debug]', message, meta));
  },
  info(message, meta = undefined) {
    console.log(getMessageArray('[info]', message, meta));
  },
  warn(message, meta = undefined) {
    console.log(getMessageArray('[warn]', message, meta));
  },
  error(message, meta = undefined) {
    console.log(getMessageArray('[error]', message, meta));
  },
  fatal(message, meta = undefined) {
    console.log(getMessageArray('[fatal]', message, meta));
  },
};

export default logger;
