/*
 * @Author: shixiaoxia
 * @Date: 2022-10-24 14:17:51
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-04-11 16:29:22
 * @Description: 用户信息本地存储
 */
import { storage } from 'ybcommon/ybutils';
import { cookie } from 'fe-yb-tools';

const LSUUIDKEY = 'YB_LS_UUID_V1';
const LSUSERIDKEY = 'YB_LS_USERID_V1';
const LSTOKENKEY = 'YB_LS_TOKEN_V1';
/**
 * 针对之前本地存储的进行统一key整理
 */
class User {
  _getStorageKey = (key) => {
    let tempKey = key;
    switch (key) {
      case '__UUID__':
        tempKey = LSUUIDKEY;
        break;
      case 'userId':
        tempKey = LSUSERIDKEY;
        break;
      case 'token':
        tempKey = LSTOKENKEY;
        break;
      default:
        break;
    }
    return tempKey;
  };

  _getCookieKey = (key) => {
    let tempKey = key;
    switch (key) {
      case '__UUID__':
        tempKey = 'authKeyUuid';
        break;
      case 'userId':
        tempKey = 'authKeyUser';
        break;
      case 'token':
        tempKey = 'authKeyToken';
        break;
      default:
        break;
    }
    return tempKey;
  };

  setItem = (key, value, useCookie) => {
    storage.set(this._getStorageKey(key), value, useCookie);
  };

  /**
   * @description: 获取存储值，优先从localStorage中获取，获取不到再从cookie获取
   * @param {string} key 存储key
   * @return {string} 返回结果
   */
  getItem = (key) => {
    const storageKey = this._getStorageKey(key);
    const cookieKey = this._getCookieKey(key);
    let result = storage.get(storageKey);
    if (!result) { // localStorage里没有，从cookie里获取,
      result = cookie.readCookie(cookieKey);
      if (result) { // cookie里有，则更新localStorage内容
        this.setItem(key, result);
      }
    }
    return result;
  };
}

export default new User();
