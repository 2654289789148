/*
 * @Author: shixiaoxia
 * @Date: 2022-08-04 15:50:41
 * @LastEditors: liulongjie liulongjie@yuanbaobaoxian.com
 * @LastEditTime: 2024-04-23 14:44:23
 * @Description: 分流专用util，方便处理分流相关逻辑
 */
import { md5, storage } from 'fe-yb-tools';
import stores from 'store';
import { ShuntFetch } from 'ybcommon/ybfetch';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { getEnv } from 'ybcommon/envUtil';
import { getSource, getLocationSearch, getUrlParam } from './common';
import { getBusinessOrigin, getAppCode } from './company';

const getShuntAppId = () => {
  const env = getEnv();
  let shuntAppId = __PUB__ ? '1633685515' : '1632814006';
  if (env === 'pre') {
    shuntAppId = '1633685515';
  }
  return shuntAppId;
};

/**
 * @description: obj转为a=b&c=d
 * @param {*} obj 要转化的对象
 * @param {*} filterString 过滤的字符串
 * @return {*} 转化后的字符串
 */
const sortObjToSearch = (configCode, obj, filterString) => {
  let result = `configKey=${configCode}&`;
  Object.keys(obj || {}).sort().map((item) => {
    if (item && obj[item] !== null && obj[item] !== undefined) {
      if (filterString) { // 如果有需要过滤的字段
        if (filterString.indexOf(item) === -1) { // 如果不含过滤的字段，则添加
          result += `${item}=${obj[item]}&`;
        }
      } else {
        result += `${item}=${obj[item]}&`;
      }
    }
    return item;
  });
  if (result.length > 0) {
    result = result.substr(0, result.length - 1);
  }
  return result;
};

/**
 * @description: 获取参数md5
 * @param {*} params 参数
 * @return {*} 返回md5文件名
 */
const genShuntParamsMd5 = (params) => {
  const filterString = 'userId,uuid,channel,ip,orderId,userIdencrypt,openId,unionId,mediaUuid,auth-token,aaTestTag,ip2Region,ipRegion,data,requestOS,position,lastVisitTime,exchangeFlag,orderIdEncrypt,amount,configId';
  let preString = '';
  const configArr = [];
  params.map((item) => {
    if (item) {
      configArr.push(sortObjToSearch(item.configCode, item.params, filterString));
    }
    return item;
  });
  preString = configArr.join('&');
  return { preString, md5String: md5(preString) };
};

const handleShuntAbtestData = async (resultData, resolve) => {
  // 存在没有template的情况
  let template = null;
  try {
    template = JSON.parse(resultData.template);
    template.requestId = resultData?.requestId;
  } catch (err) {
    template = null;
  }
  resolve(template);
};

const handleData = async (resultData, resolve) => {
  const templates = resultData.templates;
  for (const temKey in templates) {
    if (Object.prototype.hasOwnProperty.call(templates, temKey)) {
      // 解析templates里配置参数的template 拼回原对象
      let values = {};
      if (templates[temKey].template) {
        values = JSON.parse(templates[temKey].template);
      } else {
        values = {};
      }
      const bucketNo = templates[temKey].bucketNo;
      templates[temKey] = { bucketNo, ...values, requestId: resultData.requestId };
    }
  }
  resolve(templates);
};

/**
 * @description: 根据md5文件名获取缓存分流数据
 * @param {*} name 文件名
 * @param {*} preString md5加密前数据，主要用于事件上报
 * @param {*} sucCB 成功回调
 * @param {*} failCB 失败回调
 * @return {*} 模板json数据
 */
const getCdnData = (name, preString, sucCB, failCB) => {
  const t = new Date().getTime();
  let md5Url = `https://devcdn.ybaobx.com/shunt/resources/rule/cache/${name}.json?v=${t}`;
  if (__PUB__) {
    md5Url = `https://cdn.ybaobx.com/shunt/resources/rule/cache/${name}.json?v=${t}`;
  }
  ShuntFetch.get(md5Url).then((res) => {
    if (sucCB) sucCB(res);
  }).catch((err) => {
    if (failCB) failCB(err);
    trackEvent('h5_fe_shunt_ossFail', { message: '缓存json失败', data: preString, err, md5Url });
  });
};

/**
 * @description: 获取oss缓存2.0数据
 * @param {*} type 类型，1：单个分流请求；2：批量分流请求
 * @param {*} paramsData 请求参数
 * @param {*} sucCB 成功回调
 * @param {*} failCB 失败回调
 * @return {*}
 */
const getShuntOssData = (type, paramsData, sucCB, failCB) => {
  const queryUrl = type === 2 ? 'api/growth/shunt/default/determine/batchGetPage' : 'api/growth/shunt/default/determine/getPage';
  ShuntFetch.post(queryUrl, paramsData).then((res) => {
    if (sucCB) sucCB(res);
  }).catch((err) => {
    trackEvent('h5_fe_shunt_oss2Fail', { message: '缓存json2失败', err });
    let md5Result = '';
    if (type === 2) {
      md5Result = genShuntParamsMd5(paramsData.paramList);
    } else {
      md5Result = genShuntParamsMd5([paramsData]);
    }
    const { preString, md5String } = md5Result;
    getCdnData(md5String, preString, (md5Data) => {
      if (sucCB) sucCB(md5Data);
    }, () => { if (failCB) failCB(); });
  });
};

/**
 * 获取请求分流的时候用的channel
 * @returns
 */
export const getShuntChannel = () => {
  const { channel } = stores.userStore || {};
  return channel || getUrlParam('channel');
};

/**
 * 分流平台ab测 TODO
 * @param {*} limitParams 匹配规则
 * @param {*} abTest 分流平台ab测参数
 * @returns
 */
export const getShuntAbtest = (abTestParams, limitParams = {}, configId = '') => {
  const { uuid, userIdencrypt: userStoreUserIdencrypt, hasLogin } = stores.userStore || {};
  const searchParams = getLocationSearch(window.location.search);
  const { userIdencrypt } = searchParams || {};
  const TJParams = {
    shuntAppId: getShuntAppId(),
    configCode: abTestParams,
    configId,
    params: {
      // 匹配规则
      source: getSource(), // 当前环境
      host: window.location.host,
      url_pathname: window.__pathname || window.location.pathname,
      channel: getShuntChannel(),
      userIdencrypt: hasLogin ? '' : (userIdencrypt || userStoreUserIdencrypt),
      uuid: uuid || storage.get('YB_LS_UUID_V1'),
      ...limitParams,
    },
  };
  return new Promise((resolve, reject) => {
    ShuntFetch.post('/api/growth/shunt/v1/determine/getPage', TJParams)
      .then((res) => {
        if (res) {
          handleShuntAbtestData(res, resolve);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        trackEvent('h5_fe_shunt_request_md5', { err });
        getShuntOssData(1, TJParams, (md5Data) => {
          handleShuntAbtestData(md5Data, resolve);
        }, () => { reject(err); });
      });
  });
};

/**
 * 批量请求分流ab测
 * @param {object} abTestParams ab测参数
 * @param {string | undefined} originalUrl 页面路径
 * @returns Promise
 * originalUrl 如果有 分流生成mediaUuid; 如果没有 再判断公共参数里有没有tuiguangId 如果有作为mediaUuid
 */
export const getShuntAbtestAll = (abTestParams, originalUrl = null) => {
  const { uuid, userIdencrypt: userStoreUserIdencrypt, hasLogin, isWeChatMiniApp } = stores.userStore || {};
  const searchParams = getLocationSearch(window.location.search);
  const { userIdencrypt, position } = searchParams || {};
  const TJParams = {
    shuntAppId: getShuntAppId(),
    ybPosition: position,
    paramList: abTestParams.map((item) => ({
      configCode: item.configCode,
      params: {
        source: getSource(), // 当前环境
        host: window.location.host,
        url_pathname: window.__pathname || window.location.pathname,
        channel: getShuntChannel(),
        uuid: uuid || storage.get('YB_LS_UUID_V1'),
        companyCode: getBusinessOrigin(),
        appCode: getAppCode(isWeChatMiniApp),
        userIdencrypt: hasLogin ? '' : (userIdencrypt || userStoreUserIdencrypt),
        ...item.extraLimit,
      },
    })),
  };
  if (originalUrl) TJParams.originalUrl = originalUrl;
  return new Promise((resolve, reject) => {
    ShuntFetch.post('/api/growth/shunt/v1/determine/batchGetPage', TJParams)
      .then((res) => {
        if (res) {
          handleData(res, resolve);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        if (err?.code === 5000) { // code=5000 表示分流实验预览链接失效，此时不请求兜底
          reject(err);
          return;
        }
        trackEvent('h5_fe_shunt_request_md5', { err });
        getShuntOssData(2, TJParams, (md5Data) => {
          handleData(md5Data, resolve);
        }, () => { reject(err); });
      });
  });
};

/**
 * 命中ab测上报接口，批量
 * @param {*} params [{requestId: '', configCode: ''}] ab测编码，以及请求返回的requestId
 * @returns 上报接口的返回
 */
export const abTestHitReport = async (params) => {
  if (params && params.length) {
    /* 过滤上报信息错误的ab测 */
    const paramList = params.filter((abtest) => abtest.requestId && abtest.configCode);
    if (paramList.length) {
      /* 有需要上报的信息 */
      if (__DEV__) {
        console.log('分流ab测命中上报', paramList);
        if (paramList.length !== params.length) {
          console.error('分流ab测命中上报错误', params);
        }
      }
      const hitParams = {
        host: window.location.host,
        shuntAppId: getShuntAppId(),
        paramList,
      };
      const res = await ShuntFetch.post('/api/growth/shuntreport/v1/hit/batchReport', hitParams).catch((err) => {
        trackEvent('h5_fe_shunt_abtest_hit_request', { err });
      });
      return res;
    }
    if (__DEV__) {
      console.error('分流ab测命中上报错误', params);
    }
  }
  return null;
};
