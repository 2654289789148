/*
 * @Author: shixiaoxia
 * @Date: 2022-07-21 17:26:32
 * @LastEditors: jiangwei
 * @LastEditTime: 2023-12-28 10:08:58
 * @Description: url处理方法
 */
import { url, storage } from 'common/utils';
import { transferSearchObj2Str, getLocationSearch } from 'ybcommon/ybutils/common/common';
import { isWeMini } from 'utils/device';
import { getMediaUuid } from 'ybcommon/ybmodel/tgUtil';
import userStore from './UserStore';

export const updateChannel = (nextLocation, isRoute = true, noPush = false) => {
  const _nextLocation = isRoute ? nextLocation : url(nextLocation);
  const nextSearch = _nextLocation.search;
  // 将路径参数转成对象
  const nextSearchObj = getLocationSearch(nextSearch || '');
  // 路径参数统一添加channel，始终透传
  if (userStore.channel) nextSearchObj.channel = userStore.channel;
  const mediaUuid = getMediaUuid();
  if (mediaUuid) nextSearchObj.mediaUuid = mediaUuid;
  if (window.ybParams?.shuntId) {
    nextSearchObj.shuntId = window.ybParams?.shuntId;
  }
  const newSearchStr = transferSearchObj2Str(nextSearchObj);
  const newUrl = `${_nextLocation.origin || window.location.origin}${_nextLocation.pathname || window.__pathname}${newSearchStr}`;
  if (isRoute) {
    // 路由地址返回nexLocation,replace浏览器地址
    nextLocation.search = newSearchStr;
    // 对于会直接更新链接的情况，不更新浏览器路径，防止用户可以看到
    if (!noPush) {
      window.history.replaceState(window.history.state, window.location.title, newUrl);
    }
  } else {
    // 浏览器地址直接返回新的地址
    return newUrl;
  }
  return nextLocation;
};

/**
 * 处理banner链接里channel
 * @param {*} urlPath 要跳转的页面
 * @param {*} isReplace 是否为replace
 * @param {*} isJump 是否跳转
 * @returns 转变后的url
 */
export const openHrefUrl = (urlPath, isReplace = false, isJump = true) => {
  let resultUrl = updateChannel(urlPath, false);
  const container = document.scrollingElement;
  if (container) {
    const pre = container.scrollTop;
    storage.set('schoolY', pre);
  }
  if (isWeMini()) {
    // 小程序内跳转附带用户信息
    const { unionId, isWeChatMiniApp, appId } = userStore;
    if (resultUrl.indexOf('?') === -1) {
      // resultUrl = `${resultUrl}?userId=${userId}&unionId=${unionId}&userToken=${userToken}&isWeChatMiniApp=${isWeChatMiniApp}&appId=${appId}`;
      resultUrl = `${resultUrl}?&unionId=${unionId}&isWeChatMiniApp=${isWeChatMiniApp}&appId=${appId}`;
    } else {
      resultUrl = `${resultUrl}&unionId=${unionId}&isWeChatMiniApp=${isWeChatMiniApp}&appId=${appId}`;
    }
  }

  if (isJump) {
    // 是否跳转
    if (isReplace) {
      // 是否直接替换之前链接
      window.location.replace(resultUrl);
    } else {
      // 新打开链接
      window.location.href = resultUrl;
    }
  }
  return resultUrl;
};
