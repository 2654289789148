import youjiaRoutes from './youjia/route';

// 福利保障
import WelfareRoute from './welfare/route';

export default [
  {
    path: '/rights',
    component: () => import('./Index/index.js'),
    exact: true,
  },
  {
    path: '/rights/introdution',
    component: () => import('./Introdution/index.js'),
    exact: true,
  },
  {
    path: '/rights/detail',
    component: () => import('./Detail/index.js'),
    exact: true,
  },
  {
    path: '/rights/growth',
    component: () => import('./Growth/index.js'),
    exact: true,
  },
  {
    path: '/rights/dbMonPay',
    component: () => import('./dbMonPay/index'),
    exact: true,
  },
  {
    path: '/zszq',
    component: () => import('./zszq'),
    exact: true,
  },
  {
    path: '/zszq/records',
    component: () => import('./zszq/records'),
    exact: true,
  },
  {
    path: '/zszq/withdraw',
    component: () => import('./zszq/withdraw'),
    exact: true,
  },
  {
    path: '/zszq/succ',
    component: () => import('./zszq/succ'),
    exact: true,
  },
  {
    path: '/zszq/detail',
    component: () => import('./zszq/detail'),
    exact: true,
  },
  {
    path: '/vipDay',
    component: () => import('./vipDay'),
    exact: true,
  },
  {
    path: '/vipDay/records',
    component: () => import('./vipDay/records'),
    exact: true,
  },
  {
    path: '/vipDay/address',
    component: () => import('./vipDay/address'),
    exact: true,
  },
  youjiaRoutes,
  {
    path: '/gift/suppliersqy',
    component: () => import('./Suppliers'),
  },
  {
    path: '/rights/use',
    component: () => import('./RightsUse'),
  },
  {
    path: '/gift/rights',
    component: () => import('../home'),
  },
  WelfareRoute, // 福利保障
];
