import ReactDOM from 'react-dom';

let tempContainer = null;

export function renderToBody(element) {
  const container = document.createElement('div');

  tempContainer = container;
  document.body.appendChild(container);

  // 卸载组件
  function unmount() {
    const unmountResult = ReactDOM.unmountComponentAtNode(container);

    if (unmountResult && container.parentNode) {
      container.parentNode.removeChild(container);
    }
  }

  ReactDOM.render(element, container);
  return unmount;
}

export function updateToBody(element) {
  if (tempContainer) {
    ReactDOM.render(element, tempContainer);
  }
}
