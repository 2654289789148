let curAction = 'PUSH'; // 当history action值
let reqs = [];

export const historyInit = (history) => {
  window.__YB_MALL_HISTORY__ = history;
  history.listen((location, action0) => {
    curAction = action0;
    reqs.forEach((cancel) => {
      if (cancel) {
        cancel();
      }
    });
    reqs = [];
  });
};

export const getCurHistoryAction = () => curAction;

export const getCancelRequets = () => reqs;
