/*
 * @Author: pyj
 * @Date: 2022-08-01 17:40:53
 * @LastEditors: liulongjie liulongjie@yuanbaobaoxian.com
 * @LastEditTime: 2024-01-09 14:10:38
 * @Description: 活动相关路由
 */

import TrigerRoute from './triger/route';
import WheelDiscRoute from './wheelDisc/route';
import WenJuanRoute from './wenjuan/route';
import LuckyRoute from './lucky/route';
import ExperienceNew from './experienceClaim/route';
import CashCowRoute from './cashCow/route';

export default [
  TrigerRoute,
  WheelDiscRoute,
  WenJuanRoute,
  LuckyRoute,
  ExperienceNew,
  CashCowRoute,
];
