import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import styles from './index.less';

export default class TagItem extends Component {
  state = {
    showAll: false, // 默认收起状态
  };

  componentDidMount() {
    const { tags, value, brevLength } = this.props;
    if (brevLength && tags && tags.length > 0) {
      for (let i = 0; i < tags.length; i++) {
        const val = tags[i];
        const { id, value: _id } = val;
        const _tmp = _id || id;
        if (value === _tmp && i > 5) {
          this.setState({ showAll: true });
        }
      }
    }
  }

  _renderItem = (val, index) => {
    const { brevLength, tagActStyle } = this.props; // 表示收起的时候展示 brevLength 个
    const { showAll } = this.state;
    const { id, text, value: _id } = val;
    const _tmp = _id || id;
    const { tags, value, row = 3, gap = 11, gapCol = 12, onTagChange, width = 79, labelSrc, disable, minWidth } = this.props;
    // 收起状态的最后一行不设置下外边距
    // (brevLength < tags.length) && !showAll 满足这个才会显示收起按钮
    // (index >= (brevLength - row)) 表示最底下一行
    const shouqiStateLast = brevLength ? brevLength < tags.length && !showAll && index >= brevLength - row : false;
    const _style = {
      width: `${pxTOrem(width)}rem`,
      marginLeft: index % row === 0 ? '' : `${pxTOrem(gap)}rem`,
      marginBottom: index < tags.length - row && !shouqiStateLast ? `${pxTOrem(gapCol)}rem` : '',
    };
    if (minWidth) {
      _style.minWidth = `${pxTOrem(minWidth)}rem`;
      _style.width = 'auto';
      _style.padding = `0 ${pxTOrem(6)}rem`;
    }
    return (
      <div
        style={_style}
        key={`${_tmp}_${index}`}
        className={`${value}` === `${_tmp}` ? classnames(tagActStyle || styles.tagItemAct, styles.tag) : styles.tagItem}
        onClick={() => {
          if (onTagChange && `${_tmp}` !== `${value}` && !disable) {
            onTagChange(_tmp, val);
          }
        }}
      >
        <span>{text}</span>
        {`${value}` === `${_tmp}` && labelSrc ? <img src={labelSrc} className={styles.taglabel} alt="" /> : null}
      </div>
    );
  };

  // 额外的占位选项
  _renderItemExtra = (val, index) => {
    const { gap } = this.props;
    return (
      <div
        style={{
          marginLeft: `${pxTOrem(gap)}rem`,
        }}
        key={`extra_${index + 1}`}
        className={styles.itemContainer2}
      />
    );
  };

  // 点击收起或更多
  _toggleShowAll = () => {
    const { showAll } = this.state;
    this.setState({ showAll: !showAll });
  };

  render() {
    const { tags, row = 3, gap = 11, width = 79, brevLength, className } = this.props;
    const { showAll } = this.state;
    return (
      <div
        style={{
          width: `${pxTOrem((gap + width) * row)}rem`,
        }}
        className={className ? classnames(styles.tagContainer, className) : styles.tagContainer}
      >
        {brevLength && tags.length > brevLength ? (
          <Fragment>
            {showAll ? (
              <Fragment>{tags.map((val, index) => this._renderItem(val, index))}</Fragment>
            ) : (
              <Fragment>{tags.slice(0, brevLength).map((val, index) => this._renderItem(val, index))}</Fragment>
            )}
            <div className={showAll ? styles.transparentBox : styles.transparentBoxPart} />
            <div onClick={this._toggleShowAll} className={styles.showAllBtn}>
              {showAll ? '收起' : '更多'}
              {showAll ? <img src={require('./arrowUp.png')} alt="" /> : <img src={require('./arrowDown.png')} alt="" />}
            </div>
          </Fragment>
        ) : (
          <Fragment>{tags.map((val, index) => this._renderItem(val, index))}</Fragment>
        )}
      </div>
    );
  }
}
