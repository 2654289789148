/*
 * @Author: shixiaoxia
 * @Date: 2024-09-06 14:08:52
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-06 16:07:03
 * @Description: 请填写简介
 */
import { AxiosInstance } from 'axios';
import { baseFetch, CommonHeader } from '../base';
import { getEnvConfigrationByDomain } from '../../envConfig/index';

/**
 * 生成BFF请求
 * @param commonHeader
 * @returns
 */
// eslint-disable-next-line max-len
export function bffFetch(commonHeader: CommonHeader):AxiosInstance {
  const baseURL = getEnvConfigrationByDomain().GATEWAY_BASEURL;
  return baseFetch({ baseURL, withCredentials: true }, commonHeader);
}
