/*
 * @Author: shixiaoxia
 * @Date: 2022-10-21 10:54:45
 * @LastEditors: 朱晓真
 * @LastEditTime: 2023-08-11 17:00:28
 * @Description: 字典映射 - init里的dict字段
 */
class Dict {
  constructor(data = []) {
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
    this.data = data; // /api/mall/bff/init 接口返回值 dict
  }

  /**
   * 获取code对应的name
   * @param {*} type dict的键名
   * @param {*} code 相应的code
   * @returns
   */
  mapCodeToName = (type, code) => {
    let result;
    if (this.data && this.data[type] && this.data[type].length > 0) {
      this.data[type].map((option) => {
        if (String(option.code) === String(code)) {
          result = String(option.name);
        }
        return option;
      });
      return result;
    }
    return '';
  };

  /**
   * 根据value获取对应的text
   * @param {*} options 数组 [{value: '0', text: '文案一'}, {value: '1', text: '文案二'}]
   * @param {*} value options里的value
   * @returns
   */
  mapValueToName = (options, value) => {
    let result;
    options.map((option) => {
      if (String(option.value) === String(value)) {
        result = String(option.text);
      }
      return option;
    });
    return result;
  };

  /**
   * 获取选项
   * @param {*} type
   * @returns
   */
  options = (type) => {
    const list = this.data[type] || [];
    const options = list.map(({ code, name }) => ({ value: `${code}`, label: `${name}` }));
    return options;
  };
}

export default new Dict();
