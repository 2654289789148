/*
 * @Author: shixiaoxia
 * @Date: 2022-10-24 17:26:43
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2023-01-31 11:25:56
 * @Description: 操作 localstorage 的公共类
 */

import { storage } from 'fe-yb-tools';
import { isShouxin } from './common/company';

if (isShouxin()) {
  storage.setCachePrefix('SERVICE_SX_');
}

export default storage;
