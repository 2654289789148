/*
 * @Author: 朱晓真
 * @Date: 2023-04-18 20:14:33
 * @LastEditors: liulongjie liulongjie@yuanbaobaoxian.com
 * @LastEditTime: 2024-04-24 19:59:05
 * @Description: 背景音乐
 */
import { PureComponent } from 'react';
import { trackEvent } from 'ybcommon/ybutils/statistic';

interface IState {
  audioSrc: string;
}

interface IProps {
  onPlay?: (e: React.SyntheticEvent<HTMLAudioElement, Event>) => void,
  onPlayEnd?: () => void,
  onStatic?: (eventName: string, data) => void,
}

export default class BGAudioCom extends PureComponent<IProps, IState> {
  /**
   * eslint 被必填属性，需要有默认值，当前默认值为undefined
   */
  static defaultProps = {
    onPlay: undefined,
    onPlayEnd: undefined,
    onStatic: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      audioSrc: '',
    };
  }

  audioRef: HTMLAudioElement;

  /**
   * 埋点上报的额外参数
   */
  trackParams: any = {};

  /**
   * 播放的埋点上报
   */
  _playEvent = (targetEle: HTMLAudioElement) => {
    const { onStatic } = this.props;
    const { audioSrc } = this.state;
    const params = { position: targetEle?.src || audioSrc, ...this.trackParams };
    if (typeof onStatic === 'function') {
      onStatic('h5_yb_shenyinyindao_play', params);
    } else {
      trackEvent('h5_yb_shenyinyindao_play', params);
    }
  };

  /**
   * 播放方法
   * @param src 音频链接
   * @param isReplay 是否为重新播放
   * @param trackParams 播放的埋点参数
   * @param otherParams 其他参数
   */
  play = (src?: string, isReplay = false, trackParams = {}) => {
    const { audioSrc } = this.state;
    this.trackParams = trackParams || {};
    if (src !== audioSrc) {
      this.pause();
      this.trackParams = trackParams || {};
      this.setState({
        audioSrc: src,
      }, () => {
        this.audioRef?.play()
          ?.catch((err) => {
            console.log(err);
          });
      });
    } else if (isReplay) {
      this.replay();
    } else {
      this.audioRef?.play()
        ?.catch((err) => {
          console.log(err);
        });
    }
  };

  /**
   * 重新播放
   */
  replay = () => {
    this.setCurrentTime();
    setTimeout(() => {
      this.audioRef?.play()
        ?.catch((err) => {
          console.log(err);
        });
    }, 100);
  };

  /**
   * 暂停播放
   */
  pause = () => {
    this.trackParams = {};
    if (!this.audioRef) return;
    this.audioRef.pause();
  };

  /**
   * 设置播放进度，父级调用，勿删
   * @param seconds 播放进度，默认0，从头开始
   */
  setCurrentTime = (seconds = 0) => {
    if (this.audioRef) {
      this.audioRef.currentTime = seconds;
    }
  };

  onPlay = (e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    const { onPlay } = this.props;
    const targetEle = e?.target as HTMLAudioElement;
    this._playEvent(targetEle);
    onPlay?.(e);
  };

  render() {
    const { onPlayEnd } = this.props;
    const { audioSrc } = this.state;
    return (
      audioSrc
        ? (
          <audio
            ref={(ref) => { if (ref) this.audioRef = ref; }}
            onPlay={this.onPlay}
            onEnded={onPlayEnd}
            src={audioSrc}
            preload="auto"
            autoPlay
          />
        )
        : null
    );
  }
}
