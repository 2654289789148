/*
 * @Author: shixiaoxia
 * @Date: 2024-06-06 20:58:02
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-05 21:39:31
 * @Description: 请填写简介
 */
import { AxiosInstance } from 'axios';
import { baseFetch, CommonHeader } from '../base';
import { getEnvConfigrationByDomain } from '../../envConfig/index';

/**
 * 生成网关请求
 * @param commonHeader
 * @returns
 */
// eslint-disable-next-line max-len
export function mallGatewayFetch(commonHeader: CommonHeader):AxiosInstance {
  return baseFetch({ baseURL: getEnvConfigrationByDomain().GATEWAY_BASEURL }, commonHeader);
}
