// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table_ykCTQnyD {\n  width: 10rem;\n  font-size: 0.37333rem;\n}\n.table_ykCTQnyD .headTr_y1fndxD6 {\n  height: 1.06667rem;\n  background-color: #ffffff;\n  color: #3F3F3F;\n}\n.table_ykCTQnyD .headTr_y1fndxD6 .th_0B1S0yXC {\n  font-weight: normal;\n  text-align: center;\n  border-bottom: 1px solid #EDEDED;\n}\n.table_ykCTQnyD .headTr_y1fndxD6 .th_0B1S0yXC:not(:last-child) {\n  border-right: 1px solid #EDEDED;\n}\n.table_ykCTQnyD tbody > :nth-child(odd) {\n  background-color: #FFF9EF;\n}\n.table_ykCTQnyD tbody .bodyTr_UrLc\\+Py4 {\n  height: 1.06667rem;\n  color: #626262;\n}\n.table_ykCTQnyD tbody .bodyTr_UrLc\\+Py4 .td_GQ1YlG2- {\n  padding: 0 !important;\n  border: none;\n  text-align: center;\n}\n.table_ykCTQnyD tbody .bodyTr_UrLc\\+Py4 .td_GQ1YlG2-:not(:last-child) {\n  border-right: 1px solid #EDEDED;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table_ykCTQnyD",
	"headTr": "headTr_y1fndxD6",
	"th": "th_0B1S0yXC",
	"bodyTr": "bodyTr_UrLc+Py4",
	"td": "td_GQ1YlG2-"
};
export default ___CSS_LOADER_EXPORT___;
